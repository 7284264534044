import styled from '@emotion/styled';
import {mqMax} from '@facepaint';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export const Section = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 6px;
    border: 2px solid #E00000;


`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;

    ${mqMax.medium}{
      align-self: start;
    }
`;

export const Header = styled.h3`
  display: flex;
  background-color: #ffecec;
  margin: 0;
  cursor: pointer;
  padding: 1em;
  color: black;
  font-weight: 500;
  border: none;
  font-size: 1rem;
  border-bottom: 1px solid #e1e4e8;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  padding-top: 0.85em;
  padding-bottom: 0.85em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  align-items: center;

    ${mqMax.medium}{
      flex-direction: column;
      padding-left: 1.5em;
    }
`;


export const CaretIcon = styled(FontAwesomeIcon)`
  color: #616161;
  font-size: 1.15em;
  display: flex;
`;

export const ClickToExpand = styled.p`
  margin: 0;
  margin-left: auto;
  color: #586069;
  font-size: 0.83em;
  font-weight: 400;
  margin-right: 0.5em;

  ${mqMax.medium}{
    margin: 0;
    align-self: flex-start;
    margin-top: 0.25rem;
  }
`;

export const Content = styled.div`
    width: 100%;
    padding: 1em;
    overflow: hidden;
    font-size: 0.9rem;

    .no-expired{
      font-weight: 600;
      display: inline-block;
    }
`;
