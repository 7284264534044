import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';
import {employees as routes, employees as employeesRoutes} from '@routes/routes.manifest';
import StatisticCard, {StatisticCardLoading} from '@routes/employees/components/statistic-card';
import {i18n} from '@src/i18n';
import {faEllipsisH, faRoute, faUserTag} from '@fortawesome/pro-light-svg-icons';
import * as T from '@types/load.types';
import {HasMoreCard, MobileCard} from '@routes/employees/components/statistics/statistics-style';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ActionButton} from '@components/buttons/actionButton';
import {useHistory} from 'react-router';
import {DropdownWithActionButton} from '@components/dropdown-with-action-button/dropdown-with-action-button';
import {useSelector} from 'react-redux';
import reports from '@routes/reports.manifest';
import {getStatisticsKindsForView} from '@selectors/employees.selectors';
import {css} from '@emotion/react';


const StatisticCards = ({
  statCards,
  statistics,
  orgId,
  isMobile,
}) => {
  const history = useHistory();
  const reportsCards = useSelector(getStatisticsKindsForView);

  const [open, setOpen] = useState(false);
  const totalCard = reportsCards.find(rc => rc.id === 'totalt');

  if (statistics.status !== T.LoadStatuses.LOADED) {
    return (
      <div className="employees-page__statistics">
        <StatisticCardLoading  forceDesktopView/>
        {!isMobile && (
          <>
            <StatisticCardLoading />
            <StatisticCardLoading />
          </>
        )}
      </div>
    );
  }

  return(
    isMobile && (
      <>
        <h2
          css={css`
            margin: 15px;
            font-size: 1.65rem !important;
        `}
        >
          {i18n('reports.statistics')}

        </h2>

        <DropdownWithActionButton
          toggleButton={{
            text: i18n('reports.total'),
            variant:'large',
            showSelectedAsTitle: true,
            style: css`
            width: 100%;
            margin-left: 15px;
            margin-right: 15px;
            .dropdown-icon{
              margin-left: auto;
            }
            `
          }}
            variant="select"
          itemsWrapperStyle={css`
            min-width: calc(100% - 30px);
            margin-left: 15px;
          `}

          onChange={reportId => history.push(getRouteWithParams(employeesRoutes.reportPreview, {
            reportId,
            orgId,
          }))}
          items={reportsCards.map(rc => ({
            label: rc.gotoName,
            value: rc.id,
          }))}
        />

        <Link
          to={getRouteWithParams(routes.reportPreview, {
            orgId,
            reportId: totalCard.id,
          })}
          className="employees-page__statistics-card-wrapper items1 }"
        >
          <StatisticCard
            name={totalCard.name}
            progress={totalCard.data}
            firstItem={true}
            clickable
            icon={totalCard.icon}
            forceDesktopView
            css={css`
              border-bottom: 1px solid #e0e0e0;
          `}
          />

        </Link>
        <div
          css={css`
            border-bottom: 1px solid #e0e0e0;
            width: 96%;
            margin-bottom: 4rem;
            margin-top: 4.2em;
        `}
        />

      </>

    ) || (
      <div className="employees-page__statistics">
        {statCards.map((card, i) =>
          i >= 5 && !open && (
            <div
              className={`employees-page__statistics-card-wrapper items${statCards.length }`}
            >
              <HasMoreCard
                onClick={() => setOpen(true)}
              >
                <div>
                  <FontAwesomeIcon
                    icon={faEllipsisH}
                    style={{
                      fontSize: 64,
                      color: '#0B5CD7',
                    }}
                  />
                </div>
                <div>
                  {i18n('employees.report-view-rest-of-x', {functionArgs:{x: statCards.length - 4}})}
                </div>
              </HasMoreCard>
            </div>
          ) || (
            <Link
              to={getRouteWithParams(routes.reportPreview, {
                orgId,
                reportId: card.id,
              })}
              className={`employees-page__statistics-card-wrapper items${statCards.length }`}
            >
              <StatisticCard
                name={card.name}
                progress={card.data}
                firstItem={i === 0}
                clickable
                icon={card.icon}
              />
            </Link>
          ))}
      </div>
    )
  );
};

export default StatisticCards;
