import styled from '@emotion/styled';
import {css, jsx} from '@emotion/react';
import { mqMax } from '@facepaint';

export const PersonInformationLoadingCont = styled.div`
  font-size: 1.5em;
  position: relative;
  top: 34%;
  transform: translate(0, -50%);
  text-align: center;
`;

export const NoDataSection = styled.div`
  display: flex;
  height: 500px;
`;

export const ErrorIcon = styled.i`
  font-size: 2em;
  margin-right: 5px;
  position: relative;
  top: 9px;
`;

export const LoadingWrapper = styled.div`
  font-size: 1.5em;
  text-align: center;
`;

export const MainHeader = styled.h1`
  font-weight: 600;
  font-size: 2rem;
  margin-top: 0;
  margin-bottom: 1.2rem;
`;


export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex: 1 1 auto;
  top: 0;
  bottom: 0;
  width: 100%;
  flex-direction: column;
  .close-btn{
    top: 1.75em;
    right: 1.75em;
  }
  ${mqMax.medium}{
    .close-btn{
        top: 1em;
        right: 1em;
    }
  }
`;


export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  min-height: 700px;
  width: 70%;
  background: white;
  margin: 0 auto;
  flex-direction: column;
  border-radius: 16px 16px 0 0;
  padding: 2.8rem;
  padding-bottom: 15em;
  overflow-y: auto;
  flex: 1;

  ${mqMax.medium}{
   width: 94%;

   ${({mode})=> mode === 'edit' && css`
    padding-left: 1em;
    padding-right: 1em;
   `}
  }
`;

export const ExperienceDescription = styled.p`
  max-width: 1024px;
  margin: 0 auto;
  font-size: 0.9rem;
  word-break: break-word;
  hyphens: auto;
`;

export const TopBarWrapper = styled.div`
  display: flex;
  width: 70%;
  margin: auto;
  margin-top: 6em;
  align-items: center;
  margin-bottom: 1.7rem;

  ${mqMax.medium} {
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    margin-top: 2.5em;
  }
`;

export const Seperator = styled.div`
  display: flex;
  flex: 0 0 auto;
  background: #eaebed;
  width: 100%;
  height: 1px;
  margin-top: ${props => props.marginTop}px;
  margin-bottom: ${props => props.marginBottom}px;
`;

export const TopDescription = styled.p`
  ${({theme}) => theme === 'light'
  ? css`
    color: black;
    `
  : css`
      color: white;
    `
}
  margin: 0;
  font-weight: 500;
  font-size: 0.8rem;
  margin-left: 1.1rem;

  ${mqMax.medium}{
    margin-bottom: 1em;
    margin-left: 0;
  }
`;

export const TopBarActionButtonsWrapper = styled.div`
    display: flex;
    margin: 0 1.1em 0 auto;
    ${mqMax.medium}{
      width: 100%;
      justify-content: center;
    }
`;
