import React, {useEffect, useState, useCallback} from 'react';
import axios from 'axios';
import {backendUrl} from '@config';
import {i18n} from '@src/i18n';
import FormInput from '@components/form-input';
import * as styles from './edit-competence-styles';
import {ActionButton} from '@components/buttons/actionButton';
import moment from 'moment';
import {AnimatePresence, motion} from 'framer-motion';
import { useDropzone } from 'react-dropzone';
import { css } from '@emotion/react';
import PersonAvatar from '@components/person-avatar/person-avatar';

const EditCompetence = ({
  handleSubmit,
  onCancel,
  onDelete,
  personId,
  orgId,
  userName,
  phceId,
  cid,
}) => {
  const [selectFiles, setSelectFiles] = useState(true);

  const [formData, setFormData] = useState({
    orgId,
    userName,
    uploadFiles: [],
    phceId: phceId ? phceId : 0,
    fetching: true,
    saving: false,
  });

  const handleInput = ({target: {id, value}}) => {
    setFormData({
      ...formData,
      [id]: value,
    });
    console.log(formData);
  };

  const {getRootProps, getInputProps, acceptedFiles} = useDropzone();

  useEffect(() => {
    if (acceptedFiles.length !== 0 ) {
      setSelectFiles(false);
    }
  }, [acceptedFiles]);

  useEffect(async () => {
    const fetchData = async () => {
      if (phceId) {
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/persons/editcompetence_json/${phceId}/${orgId}`,
            withCredentials: true,
          })
          .then(({data}) => {
            if (data.verifieddate) {
              data.verifieddate = moment(data.verifieddate, 'DD.MM.YYYY').format('YYYY-MM-DD');
            }
            if (data.valid_until) {
              data.valid_until = moment(data.valid_until, 'DD.MM.YYYY').format('YYYY-MM-DD');
            }
            setFormData({
              ...formData,
              ...data,
              fetching: false,
            });
          });
      }else {
        axios
          .request({
            method: 'GET',
            url: `${backendUrl}/persons/editcompetence_json/0/${orgId}?pid=${personId}&cid=${cid}`,
            withCredentials: true,
          })
          .then(({data}) => {
            if (data.verifieddate) {
              data.verifieddate = moment(data.verifieddate, 'DD.MM.YYYY').format('YYYY-MM-DD');
            }
            setFormData({
              ...formData,
              ...data,
              fetching: false,
            });
          });
      }
    };
    fetchData();
  }, []);


  return (
    <AnimatePresence>
      <motion.div
        animate={{
          height: !formData.fetching ? 'auto' : 200,
          opacity: formData.saving ? 0 : 1,
        }}
        transition="tween"
        initial={{height: 200}}
        css={{
          overflow: 'hidden',
          zIndex: 10000,
          position: 'relative',
        }}
      >
      {!formData.fetching && (
        <>
          <styles.FormWrapper>
            {selectFiles && (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <styles.DropZone>
                  {i18n('person.add-competences.certificate')}
                </styles.DropZone>
                <styles.HelpTxt>{i18n('person.add-competences.certificate-txt')}</styles.HelpTxt>
              </div>
            ) || (
              <styles.Files>
                <h4>{i18n('person.add-competences.files')}</h4>
                {acceptedFiles.map(addedFile => (
                  <>
                    {addedFile.path}<br/>
                  </>
                ))}
                <ActionButton
                  onClick={() => {setSelectFiles(true)}}
                >
                  {i18n('globals.change')}
                </ActionButton>
              </styles.Files>
            )}

            <div className="two">
              <FormInput
                className="person-form__input"
                name="verifieddate"
                label={i18n('person.add-competences.completed-date')}
                input={{}}
                placeholder={i18n('date-and-time.browser-date-format-local')}
                meta={{}}
                value={formData.verifieddate}
                type="date"
                id="verifieddate"
                onChange={handleInput}
              />
              <FormInput
                className="person-form__input"
                name="valid_until"
                label={i18n('person.add-competences.valid_until')}
                placeholder={i18n('date-and-time.browser-date-format-local')}
                input={{}}
                value={formData.valid_until}
                meta={{}}
                type="date"
                id="valid_until"
                onChange={handleInput}
              />
            </div>
            <FormInput
              className="person-form__input"
              name="comments"
              label={i18n('person.add-competences.comments')}
              input={{}}
              meta={{}}
              type="text"
              value={formData.comment}
              id="comment"
              onChange={handleInput}
            />
          </styles.FormWrapper>
          <styles.ButtonWrapper>
            <div className='left'>
              {phceId && (
                <ActionButton
                  variant="cancel"
                  type="button"
                  onClick={() => onDelete(formData)}
                  style={{color: '#E00000'}}
                >
                  {i18n('globals.delete')}
                </ActionButton>
              )}
            </div>
            <div className='right'>
              <>
                <ActionButton
                  variant="cancel"
                  type="button"
                  onClick={onCancel}
                >
                  {i18n('globals.cancel')}
                </ActionButton>
                <ActionButton
                  type="submit"
                  variant="primary"
                  onClick={() => {
                    setFormData({
                      ...formData,
                      saving: true,
                    });
                    handleSubmit(formData, (selectFiles ? null : acceptedFiles));
                  }}
                >
                  {i18n('globals.save')}
                </ActionButton>
              </>
            </div>
          </styles.ButtonWrapper>
        </>
      ) || (
        <div>{i18n('globals.loading')}</div>
      )}
      </motion.div>
    </AnimatePresence>
  )
}

export default EditCompetence;
