import {backendUrl} from '@config';
import moment from 'moment';

/* eslint-disable one-var */
/* eslint-disable no-param-reassign */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-bitwise */
// based on https://awik.io/determine-color-bright-dark-using-javascript/
export const lightOrDark = color => {
  // Variables for red, green, blue values
  let r, g, b;

  // Check the format of the color, HEX or RGB?
  if (color.startsWith('rgb')) {
    // If RGB --> store the red, green, blue values in separate variables
    color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

    r = color[1];
    g = color[2];
    b = color[3];
  } else {
    // If hex --> Convert it to RGB: http://gist.github.com/983661
    color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
  }

  // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
  const hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

  // Using the HSP value, determine whether the color is light or dark
  if (hsp > 200) {
    return 'light';
  }

  return 'dark';
};

/* eslint-enable one-var */
/* eslint-enable no-param-reassign */
/* eslint-enable prefer-destructuring */
/* eslint-enable no-bitwise */

// gets the full url for an image, possibly prepending the backend-url if the url is absolute
export const getImageUrl = src => {
  if (!src) {
    return '';
  }
  if (src.startsWith(backendUrl)) {
    return src;
  }
  if (src.charAt(0) === '/') {
    return backendUrl + src;
  }

  return src;
};


// given a html-node, return all the parents of this node as a list, excuding the document-element
export const  parentsForHtmlNode = node => {
  if(!node) {
    return [];
  }
  let current = node;
  const list = [];

  while(current.parentNode != null && current.parentNode !== document.documentElement) {
    list.push(current.parentNode);
    current = current.parentNode;
  }

  return list;
};

export const getTwoletterLangCodeFromNavigatorLanguage = () => {
  const navLang = navigator.language && navigator.language.toLocaleLowerCase();

  if(!navLang) {
    return null;
  }

  if(navLang.startsWith('en-')) {
    return 'en';
  }

  return {
    'nb-no':'no',
    'nn-no':'no',
  }[navLang] || 'en';
};

export const emptyDate = () => {
  const d = new Date();

  d.setMilliseconds(0);
  d.setMinutes(0);
  d.setSeconds(0);
  d.setHours(0);
  d.setFullYear(0);
  d.setMonth(0);
  d.setDate(1);

  return d;
};

// https://stackoverflow.com/a/44198641
export const isValidDate = date =>
  date
  && Object.prototype.toString.call(date) === '[object Date]'
  && !Number.isNaN(date);

export const lastDateThisYear = () => {
  const d = new Date();

  d.setMonth(11);
  d.setDate(31);
  d.setHours(23, 59, 59);

  return d;
};

export const verifyPassed = (competence) => {
  if (competence.passed === 100 && competence.valid_until && moment(competence.valid_until).isBefore(moment())) {
    return false;
  }

  return competence.passed === 100;
}

export const lastDateNextYear = () => {
  const d = lastDateThisYear();

  d.setFullYear(d.getFullYear() + 1);

  return d;
};
