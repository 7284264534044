import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import {NavLink} from 'react-router-dom';

import './desktop-menu.scss';
import {Menu as SnapperMenu} from '@snapper/menu/dist/lib';
import { css, useTheme } from '@emotion/react';

const DesktopMenu = ({
  menuLinks,
  onLogoutClick,
  data,
  isMobile,
  history
}) => {
  const theme = useTheme();

  return (
    <div className="desktop-menu">
    <SnapperMenu
      history={history}
      data={
        {
          ...data,
          isMobile: isMobile,
        }
      }
      className={"desktop-menu-wrapper"}
      css={css`justify-content: center!important;;flex-basis: auto !important;`}
    />
    {!isMobile && menuLinks && (
      <div className="desktop-menu__bottom-section">
        {menuLinks.map(({name, path, active, exact = false}, index) => (
          <NavLink
            className={classNames('desktop-menu__link', {'desktop-menu__link--active': active})}
            activeClassName="desktop-menu__link--active"
            exact={exact}
            style={{
              borderBottom: (active ? `2px solid ${theme.colors.menu.header}` : '0'),
            }}
            to={path}
            strict={false}
            key={index}
          >
            {name}
          </NavLink>
        ))}
      </div>
    )}
  </div>
)};

DesktopMenu.propTypes = {
  menuLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onLogoutClick: PropTypes.func.isRequired,
};

export default DesktopMenu;
