import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {compose} from 'redux';
import {withRouter} from 'react-router';
import {Field, reduxForm} from 'redux-form';

import {RoleShape, OrganisationShape} from '@types/role';

import FormInput from '@components/form-input';
import FormSelect from '@components/form-select';

import '../styles/employee-add.scss';
import {i18n} from '@src/i18n';
import {ActionButton} from '@components/buttons/actionButton';

const validate = values => {
  const errors = {};

  const requiredFields = [
    'firstname',
    'lastname',
    'email',
    'id',
    'employee_code',
    'user_name',
    'role_id',
  ];

  requiredFields.forEach(field => {
    if (!values[field]) {
      errors[field] = i18n('globals.mandatory-field');
    }
  });

  return errors;
};

const FormCheckbox = ({className, input, label, meta, ...props}) => (
  <label
    htmlFor={input.name}
    className="employee-add__bottom-checkbox-wrapper"
  >
    <input
      type="checkbox"
      id={input.name}
      className={className}
      autoComplete="off"
      checked={input.value}
      {...props}
      {...input}
    />
    {label && <span className="employee-add__checkbox-label">{label}</span>}
  </label>
);

FormCheckbox.propTypes = {
  className: PropTypes.string,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    touched: PropTypes.bool,
  }).isRequired,
};

FormCheckbox.defaultProps = {
  className: '',
  label: '',
};

const validateUsername = input => {
  if (!/^[\w.@-]{3,}$/gi.test(input)) {
    return i18n('person.username-illegal-chars');
  }

  return undefined;
};

const validateMobile = input => {
  if (!input || !input.trim()) {
    return undefined;
  }
  const number = input
    .replace(/^\s*(?:\+|00)(?:45|46|47|358|354)\s*/g, '')
    .trim();
  const digits = number.match(/\d/g);

  if (
    !digits
    || !/^[\d\s]+$/g.test(number)
    || digits.length < 5
    || digits.length > 12
  ) {
    return  i18n('person.invalid-mobile');
  }

  return undefined;
};

const validateRole = value => {
  if (!value || value === i18n('person.role-select-default')) {
    return i18n('person.position-required');
  }

  return false;
};

class EmployeeAdd extends Component {
  constructor(props) {
    super(props);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const {onInit} = this.props;

    if (onInit) {
      onInit();
    }
  }

  onSubmit(e) {
    const {onAddEmployeeFormSubmit} = this.props;

    onAddEmployeeFormSubmit(e);
  }

  render() {
    const {
      functions: {position: positions},
      handleSubmit,
      onAddEmployeeFormCancel,
    } = this.props;


    return (
      <div className="employee-add">
        <div className="employee-add__card">
          <form
            onSubmit={handleSubmit(this.onSubmit)}
            className="employee-add__form"
          >
            <div className="employee-add__form-inner-wrapper">
              <div className="employee-add__input-wrapper">
                <Field
                  autoComplete="firstname"
                  className="employee-add__input"
                  component={FormInput}
                  label={i18n('person.firstname') + ' *'}
                  name="firstname"
                  type="text"
                />
              </div>
              <div className="employee-add__input-wrapper">
                <Field
                  autoComplete="lastname"
                  className="employee-add__input"
                  component={FormInput}
                  label={i18n('person.lastname') + ' *'}
                  name="lastname"
                  type="text"
                />
              </div>
              <div className="employee-add__input-wrapper">
                <Field
                  autoComplete="email"
                  className="employee-add__input"
                  component={FormInput}
                  label={i18n('person.email') + ' *'}
                  name="email"
                  type="email"
                />
              </div>
              <div className="employee-add__input-wrapper">
                <Field
                  autoComplete="mobile"
                  className="employee-add__input"
                  component={FormInput}
                  validate={[validateMobile]}
                  label={i18n('person.mobile') + ' *'}
                  name="mobile"
                  type="text"
                />
              </div>
              <div className="employee-add__divider" />
              <div className="employee-add__input-full-wrapper">
                <div className="hlp">
                  <strong>{i18n('globals.username')}</strong>
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                  i18n('person.username-desc'),
                    }}
                  />
                </div>
                <Field
                  autoComplete="user_name"
                  className="employee-add__input"
                  component={FormInput}
                  label=""
                  name="user_name"
                  type="text"
                  validate={[validateUsername]}
                />
              </div>
              <div className="employee-add__divider" />
              <div className="employee-add__input-full-wrapper">
                <div
                  className="hlp"
                  dangerouslySetInnerHTML={{
                    __html:
                i18n('person.employee-number-desc'),
                  }}
                />
                <Field
                  component={FormInput}
                  className="employee-add__input"
                  label=""
                  name="employee_code"
                  type="text"
                />
              </div>
              <div className="employee-add__divider" />
              <div className="employee-add__input-wrapper">
                {positions.length !== 0 && (
                  <Field
                    component={FormSelect}
                    options={positions}
                    defaultValue={null}
                    className="employee-add__input"
                    name="role_id"
                    label={i18n('person.position') + ' *'}
                    validate={[validateRole]}
                  />
                )}
              </div>
              <div className="employee-add__input-wrapper" />
              <Field
                className="employee-add__checkbox"
                component={FormCheckbox}
                label={i18n('person.send-username-and-password')}
                name="send_login"
              />
            </div>
            <div className="employee-add__buttons-wrapper">
              <ActionButton
                variant="cancel"
                onClick={onAddEmployeeFormCancel}
              >
                {i18n('globals.cancel')}
              </ActionButton>
              <ActionButton
                variant="primary"
                type="submit"
              >
                {i18n('globals.add')}
              </ActionButton>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

EmployeeAdd.propTypes = {
  onAddEmployeeFormSubmit: PropTypes.func,
  onInit: PropTypes.func,
  handleSubmit: PropTypes.func.isRequired,
  roles: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  organisations: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(OrganisationShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  functions: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape({})),
    positions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  history: PropTypes.shape({}).isRequired,
};

EmployeeAdd.defaultProps = {
  onInit: null,
  onAddEmployeeFormSubmit: null,
};

export default compose(
  withRouter,
  reduxForm({
    form: 'addemployee',
    validate,
    enableReinitialize: true,
    updateUnregisteredFields: true,
    keepDirtyOnReinitialize: true,
  }),
)(EmployeeAdd);
