import themedLogo from '@components/themed-logo/themed-logo';
import {css, jsx} from '@emotion/react';
import styled from '@emotion/styled';
import {mqMax} from '@facepaint';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CaretDown} from 'phosphor-react';
import {darken, lighten, rgba} from 'polished';

export const Wrapper = styled.div`
    position: relative;
`;

export const CaretIcon = styled(CaretDown)`
  color: #6B7280;
  margin-left: auto;
  position: absolute;
  right: 1em;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.2em;
`;

export const DropdownItemsWrapper = styled.div`
    position: absolute;
    background: #FFFFFF;
    width: auto;

    z-index: 2;
    display: ${({isOpen}) => isOpen ? 'block' : 'none'};

    ${mqMax.medium}{
      width: 100%;
    }

    ${({variant, isOpen}) => ({
      simple: css`
        box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
        border-radius: 4px;
        padding-top: 4px;
        padding-bottom: 4px;
        margin-left: -9px;
        width: 300px;
        margin-top: 0.25em;
     `,
    dark: css`
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #E0E0E0;
      border-radius: 4px;
      `,
    black: css`
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      border: 1px solid #1C1D1F;
      border-radius: 4px;
      `,
  'default': css`
    border-radius: 4px;
    width: 100%;
    max-width: 300px;
    position: ${isOpen ? 'absolute' : 'static'};
    top: 0;
    border: 1px solid #D1D5DB;
    font-size: 0.85rem;
  `,
})[variant]}

`;

export const ToggleDropdownIcon = styled(FontAwesomeIcon)`
  ${({size}) => ({
  small: css`

  `,
  large: css`
    color: rgb(102,102,102);
    font-size: 14px;
    position: relative;
    top: 2px;
  `,
}[size])}
${({variant}) => variant === 'light' && css`
  margin-left: 1.1em;
`}
`;
export const DropdownItem  = styled.div`
    &:hover, &.focus-visible, :focus:not(:hover){
      background:  ${({theme}) => theme.colors.dropdown.itemHoverBackgroundColor};
      color:  ${({theme}) => theme.colors.dropdown.itemHoverColor};
        cursor: pointer;

        ${({disabled}) => disabled
            && css`
                background: initial;
                color: #9E9E9E;
                cursor: default;
            `
        }
    }

    ${({variant, isSelected}) => ({
        dark: css`
          color: #424242;
          font-size: 1em;
          padding: 0.5em 2em;
        `,
        black: css`
          color: #000;
          font-size: 1em;
          padding: 0.5em 2em;
        `,
          profile: css`
            color: #424242;
            font-size: 1em;
            padding: 0.5em 2em;
        `,
          simple: css`
          color: #0a0a0a;
          font-size: 0.9em;
          padding: 6px 0;
          padding-left: 12px;
          padding-right: 12px;
      `,
      'default': css`
        padding: 0.8rem;
        padding-left: 0.765rem;
        font-size: 0.85rem;
        font-weight: 400;
        line-height: 1;
        color: ${isSelected ? '#0B5CD7' : '#424242'};
        width: 100%;
        display: block;
        text-align: left;

        border-radius: 0;

        &:first-child{
          border-top-left-radius: inherit;
          border-top-right-radius: inherit;
        }

        &:last-child{
          border-bottom-left-radius: inherit;
          border-bottom-right-radius: inherit;
        }
      `,
        })[variant]}

    ${({disabled}) => disabled
        && css`
            color: #9E9E9E;
        `
    }
`;
export const DropdownItemsLabel  = styled.div`
    color: #9E9E9E;
    font-size: 0.8em;
    padding-top: 0.95em;
    padding-bottom: 0.45em;
    padding-left: 2em;
    &:hover{
        cursor: default;
    }
`;

export const ToggleDropdownButton = styled.button`
  ${({size, hasText}) =>
  ({
    small: css`
        color: #ffffff;
        background: #0a0a0a;
        padding: ${hasText ? '1em' : '0.75em'};
      `,
    large: css`
        font-size: 24px;
        font-weight: 600;
        padding: 0;
        line-height: 1.5;
      `,
  }[size])}

  ${({colorScheme}) =>
    ({
      dark: css`
        color: #ffffff;
        background: #0a0a0a;
      `,
      black: css`
        color: #ffffff;
        background: #0a0a0a;
      `,
      profile: css`
        color: #ffffff;
        background: rgba(255,255,255,0.2);
      `,
      light: css`
        color: #0a0a0a;
        background: transparent;
      `,
    }[colorScheme])}

  ${({variant}) => ({
      'default': css`
        background: #FFFFFF;
        border: 1px solid #D1D5DB;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
        font-size: 0.85rem;
        padding: 0.8rem;
        padding-left: 0.765rem;
    `,
      profile: css`
        color: #ffffff;
        background: rgba(255,255,255,0.1);
      `,
      dark: css`
        align-items: center;
        justify-content: center;
        `,
      black: css`
        background: black;
        align-items: center;
        color: white;
        justify-content: center;
        `,
      simple: css`
        align-items: center;
        justify-content: center;
    `,

    })[variant]}

  ${({withBorderDecoration}) => withBorderDecoration && css`
    background: #FFFFFF;
    border: 1px solid #D1D5DB;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
  `}

  ${({variant}) => variant === 'large'
  && css`
    font-size: 24px;
    font-weight: 600;
  `
}

  height: auto;
  display: flex;

  &:focus {
    outline: none;
  }

  &:hover {
    ${({variant, isNextToActionButton}) =>
  ({
    small: css`
          background:  ${isNextToActionButton ?  lighten(0.05, '#0A0A0A') : 'none'};
        `,
    large: css``,
  }[variant])}

    cursor: pointer;
  }

  &.focus-visible {
    box-shadow: 0 0 0 3px #537fc3;
  }

  ${({isNextToActionButton}) =>
    isNextToActionButton
    && css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    `}
`;

export const ActionButton = styled.button`
    padding: 8px 16px;
    background: #424242;
    border-radius: 4px;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    color: #FFFFFF;
    text-align: left;
    font-weight: 500;
    font-size: 1em;

    ${({variant}) => ({
      profile: css`
        color: #ffffff;
        min-width: 160px;
        background: rgba(255,255,255,0.2);
      `,
      black: css`
        background: #1C1D1F;
      `,
    })[variant]}

  flex-grow: 1;

  &:focus{
      outline: none;
  }

  &:hover{
      background: ${lighten(0.05, '#424242')};
      cursor: pointer;
      ${({variant}) => ({
        profile: css`
          background: rgba(255,255,255,0.3);
        `,
      })[variant]}
  }

  &.focus-visible{
      box-shadow: 0 0 0 3px #537fc3;
  }

`;

export const ButtonsWrapper = styled.div`
    display: flex;
`;
