import styled from '@emotion/styled';
import { mqMax } from '@facepaint';


export const Wrapper = styled.div`
  display: flex;
  background: #495866;
  color: #ffffff;
  padding: 4.5em 0em;
  position: relative;
  width: 100%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  max-width: 950px;
  margin: 0 auto;
  width: 100%;
  padding-left: 2em;

  ${mqMax.medium} {
    flex-direction: column;
    .send-msg-button{
      margin-bottom: 1.5em;
    }
  }
`;

export const LeftPart = styled.div``;
export const RightPart = styled.div`
  margin-left: auto;
  span {
    line-height: 2em;
  }
  margin-right: 0;
  ${mqMax.large} {
    margin-right: 30px;
  }

  ${mqMax.medium} {
    margin-left: 0;
    align-items: flex-start;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;
export const Heading = styled.p`
  color: white;
  font-weight: bold;
  font-size: 1.2em;
  margin-bottom: 0.4em;
  margin-top: 0;
`;
export const SubHeading = styled.p`
  color: white;
  margin: 0 !important;
  font-weight: 500;
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #d9d9d9;
  margin: 1.5em 0em;
`;


export const PersonInfoWrapper = styled.div`
  display: flex;
  margin-bottom: 2.5em;
`;
export const Personalia = styled.div`
  margin-left: 1.5em;
`;

export const Text = styled.p`
  margin: 0;
  font-size: 0.9em;
`;
