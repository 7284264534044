export const CourseTypes = {
  ecourse: ['ecourse'],
  course: ['course', 'elkemcourse'],
  digitalsignature: ['digital_signature', 'digitalsignature'],
  complex: ['complex'],
  equivalents: ['equivalents'],
  checklist: ['checklist'],
  checklist_item: ['checklist_item'],
  nano: ['nano'],
  chat: ['chat'],
  track: ['track'],
};

export const CourseType = {
  ecourse: 'ecourse',
  course: 'course',
  digitalsignature: 'digitalsignature',
  complex: 'complex',
  equivalents: 'equivalents',
  checklist: 'checklist',
  checklist_item: 'checklist_item',
  nano: 'nano',
  chat: 'chat',
  track: 'track',
};

