import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import CourseButtons from '@components/course/course-buttons';
import {faCalendarAlt, faHourglass, faUserCheck} from '@fortawesome/pro-solid-svg-icons';
import completed from '../../../assets/svg/completed.svg';
import {ActionButton} from '@components/buttons/actionButton';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import {Pencil} from 'phosphor-react';
import {bindActionCreators} from 'redux';
import {getIsMobile} from '@selectors/global.selectors';
import {connect} from 'react-redux';
import {FilesList} from '@components/files-list/files-list';
import {css} from '@emotion/react';
import {verifyPassed} from '../../utils/misc.utils';
import { getPassedIds } from '@selectors/profile.selectors';


const createSubtitleContent = content => ({__html: content});

class CompetenceEcourse extends Component {
  constructor(props) {
    super(props);
    this.removePersonalCompetence = this.removePersonalCompetence.bind(this);
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    removeRequirement(id);
  }

  render() {
    const {
      size,
      course,
      roleName,
      roleId,
      canEdit,
      passedCoursesIds,
      onEdit,
      removeRequirement,
      defaultImg,
      isMobile,
    } = this.props;
    const isDone = passedCoursesIds && passedCoursesIds.includes(Number.parseInt(course.id, 0)) || false;
    console.log(passedCoursesIds)
    return (
      <>
        {size === 'big' && (
          <>
            <div className="cell small-4 medium-4 course-image-wrapper">
              <Link
                to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                  courseId: course.id,
                  roleId,
                })}
              >
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${course.cover && course.cover.url
                      || defaultImg})`,
                  }}
                />
              </Link>
            </div>
            <div className="content cell small-8 medium-8 course-content-wrapper">
              <Link
                to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                  courseId: course.id,
                  roleId,
                })}
                className="__title"
              >
                <h2>{course.competence_title}</h2>
              </Link>
              {course.competence_type.competence_type
                && (
                  <div>
                    {i18nCourseGetCompetenceType(course)}
                  </div>
                )}
              <div
                className="desc"
                dangerouslySetInnerHTML={createSubtitleContent(course.short_description)}
              />
              {course.files && course.files.length > 0 && (
                <FilesList
                  className="files-list"
                  files={course.files.map(({title, url}) => ({
                    title,
                    url,
                  }))}
                  wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                />
              )}

              <CourseButtons course={course} />
            </div>
            {isDone && (
              <img
                className="passed-ico"
                alt="completed"
                src={completed}
              />
            )}
          </>
        )}

        {size === 'compact' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 medium-9 cell">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
              </div>
              <div className="small-12 medium-3 cell">
                <CourseButtons course={course} />
              </div>
            </div>
          </div>
        )}
        {size === 'employee' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 cell">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >
                  {canEdit && !isMobile && (
                    <div className="edit-button">
                      <ActionButton
                        variant="defaultTheme"
                        onClick={() => onEdit(course)}
                      >
                        {i18n('globals.edit')}
                      </ActionButton>
                    </div>
                  )}
                  {canEdit && isMobile && (
                    <div className="edit-button mobile">
                      <Pencil
                        color="#0b5dd7"
                        role="button"
                        tabIndex="0"
                        onKeyUp={evt => evt.key === 'Enter' && onEdit(course)}
                        onClick={() => onEdit(course)}
                      />
                    </div>
                  )}

                  <div className="roles-list__competence-title">
                    <div className="roles-list__competence-status">
                      {verifyPassed(course)
                        && (
                          <img
                            src={completed}
                            alt="all-ok"
                            style={{width: 20}}
                          />
                        )}
                    </div>
                    <div className="grid-x">
                      <div className="cell small-12 medium-8 info-wrapper">
                        <div className="small-header">
                          {i18nCourseGetCompetenceType(course)}
                        </div>
                        {course.competence_title}
                        <div className="signed-info">
                          {course.verified && course.verified.date && (
                            <div className="signed-date">
                              <FontAwesomeIcon icon={faCalendarAlt} />
                                &nbsp;&nbsp;
                              {course.verified.date}
                            </div>
                          )}
                          {course.verified && course.verified.fullname && (
                            <div className="signed-by">
                              <FontAwesomeIcon icon={faUserCheck} />
                                &nbsp;&nbsp;
                              {course.verified.fullname}
                            </div>
                          )}
                          {course.valid_until && (
                            <div className="signed-by">
                              <FontAwesomeIcon icon={faHourglass} />
                                &nbsp;&nbsp;
                              {course.valid_until}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="cell small-12 medium-4">
                        {verifyPassed(course)
                            && !!course.grade
                            && course.grade !== 0 && (
                              <div className="course-grade">
                                {i18n('globals.grade')}:
                                {' '}
                                {course.grade}
                              </div>
                        )}
                      </div>
                      {course.files && course.files.length > 0 && (
                        <FilesList
                          className="files-list"
                          files={course.files.map(({title, url}) => ({
                            title,
                            url,
                          }))}
                          wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                        />
                      )}

                    </div>
                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>
            </div>
          </div>
        )}
        {size === 'compact-sub' && (
          <div className="competence-card-sub">
            <div className="grid-x">
              <div className="small-9 cell info-wrapper">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
              </div>

              <div className="small-3 cell">
                <CourseButtons course={course} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

CompetenceEcourse.propTypes = {
  course: PropTypes.shape({}).isRequired,
  roleName: PropTypes.string.isRequired,
  removeRequirement: PropTypes.func,
  size: PropTypes.string.isRequired,
  roleId: PropTypes.number,
};

CompetenceEcourse.defaultProps = {
  roleId: -2,
  removeRequirement: undefined,
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
  passedCoursesIds: getPassedIds(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetenceEcourse);
