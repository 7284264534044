import PropTypes from 'prop-types';
import * as T from '@types/load.types';
import React, {useState, useEffect, useRef, useCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {i18n} from '@src/i18n/index';
import TreeView from '@components/tree-view/tree-view';
import {AnimatePresence} from 'framer-motion';
import {faCaretDown, faSitemap} from '@fortawesome/pro-solid-svg-icons';
import {DropdownWithActionButton} from '@components/dropdown-with-action-button/dropdown-with-action-button';
import {css} from '@emotion/react';
import * as styles from './styles';
import {useSelector} from 'react-redux';
import {getIsMobile} from '@selectors/global.selectors';

const OrgansationHeader = ({
  mainOrganisation,
  tenets,
  updateActiveOrg,
}) => {
  const node = useRef();
  const [selectSub, openSub] = useState(false);
  const [showTree, openTree] = useState(false);
  const isMobile = useSelector(getIsMobile);

  const handleClick = useCallback(e => {
    if (selectSub) {
      if (node.current && node.current.contains(e.target)) {
        // inside click
        return;
      }
      openSub(false);
    }
  }, [selectSub]);

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick);

    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [handleClick]);

  return (
    <>
      <div className="organisation-header-wrapper" >
        {tenets && tenets.length > 1 && (
          <div className="tenets">
            <DropdownWithActionButton
              variant="dark"
              css={css`margin-top: 1.15em;`}
              actionButton={{
                text: 'Change organisation',
                souldOpenDropdown: true,
              }}
              toggleButton={{title:'change tendent'}}
              onChange={val => {
                updateActiveOrg(val);
              }}
              items={tenets.map(t => { return({label: t.title, value: t.id}) })}
            />
          </div>
        )}
        {mainOrganisation.status === T.LoadStatuses.LOADED && (
          <div className="organisation-header">
            {mainOrganisation.data.children.length !== 0 && updateActiveOrg && (
              <DropdownWithActionButton
                className="select-menu"
                toggleButton={{
                  text: mainOrganisation.data.title,
                  variant: 'large',
                  style: isMobile && css`
                    text-align: left;
                  `,
                }}
                variant="simple"
                size="large"
                onChange={orgId => {
                  openSub(false);
                  updateActiveOrg(orgId);

                }}
                items={mainOrganisation.data.children.map(org => ({
                  label: org.title,
                  value: org.organisation_id,
                }))}
              />
            ) || (
              <div className="organisation-drop">
                <h1>
                  {mainOrganisation.data.title}
                </h1>
              </div>
            )}
            {mainOrganisation.data.children.length !== 0 && updateActiveOrg && (
              <styles.OpenTree
                className="open-tree-button"
                role="button"
                tabIndex="0"
                onClick={() => openTree(!showTree)}
                onKeyUp={e => e.key === 'Enter' && openTree(!showTree)}
              >
                <FontAwesomeIcon icon={faSitemap} />
                {' '}
                {i18n('employees.show-tree')}
              </styles.OpenTree>
            )}
          </div>
        )
          || <h1>...</h1>}

      </div>
      <AnimatePresence>
        {mainOrganisation.status === T.LoadStatuses.LOADED && showTree && (
          <div className="fullwidth organisation-treeview-wrapper">
            <TreeView
              openNode={id => {
                updateActiveOrg(id);
                openTree(false);
              }}
              orgId={mainOrganisation.data.organisation_id}
            />
          </div>
        )}
      </AnimatePresence>
    </>
  );
};

OrgansationHeader.propTypes = {mainOrganisation: PropTypes.shape({}).isRequired};


export default OrgansationHeader;
