import styled from '@emotion/styled';

export const CheckListWrapper = styled.div`
  padding-bottom: 12px;
`


export const InfoTxt = styled.div`
  margin-top: 2px;
  margin-left: 8px;
  font-size: 0.8em;
  color: #525252;
`;


export const Input = styled.input`
  width: 30px;
`;
