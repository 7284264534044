/* eslint-disable camelcase */
/* eslint-disable no-console */
import {takeLatest, put, take, select} from 'redux-saga/effects';
import axios from 'axios';

import {
  getIsFullPersonFetched,
  getProfileUserName,
} from '@selectors/profile.selectors';

import {
  fetchRolesRequest,
  fetchRolesRequestSuccess,
  fetchRolesRequestFailure,
  fetchRoleRequest,
  fetchRoleRequestSuccess,
  fetchRoleRequestFailure,
  PROFILE_FETCH_SUMMARY,
} from '@actions/profile.actions';

import {backendUrl} from '@config';
import * as authActions from '@actions/auth.actions';
import {
  ROUTER_MY_EDUCATION_ROLE_VIEW_DID_MOUNT,
  ROUTER_MY_EDUCATION_PROFILE_VIEW_DID_MOUNT,
} from '@actions/router.actions';

import * as employeesActions from '@actions/employees.actions';
import * as rolesActions from '@actions/roles.actions';

import retry from '@utils/sagas.utils';

const RolesAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

function* fetchRolesSummary() {
  yield put(rolesActions.fetchRolesRequest());
}

function* fetchRoles({payload}) {
  try {
    let {rid, userName} = payload;

    yield put(rolesActions.fetchRolesRequest());

    userName = yield userName || select(getProfileUserName);
    rid = rid || '';

    while (userName === null) {
      yield take();
      userName = yield select(getProfileUserName);
    }
    const roles = yield retry(() =>
      RolesAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/roles/${rid}`,
        params: {
          role_meta_types: 'position,role',
          user_name: userName,
          ...!rid && {
            summary: 1,
            fields: 'title,id',
          }
              || {},
        },
      }).then(response => response.data.roles));

    yield put(rolesActions.fetchRolesSuccess({roles}));
  } catch (error) {
    if (error.code === 401) {
      yield put(authActions.authUnauthorized());
    }
    yield put(rolesActions.fetchRolesFailure({error}));
  }
}

/*
 *   FETCHROLESREQUIRED
 *   Get the roles and requierments for this section.
 * */

export function* fetchRolesRequired() {
  yield put(fetchRolesRequest());
  try {
    let userName = yield select(getProfileUserName);

    while (userName === null) {
      yield take();
      userName = yield select(getProfileUserName);
    }
    const roles = yield retry(() =>
      RolesAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/roles`,
        params: {
          fields:
              'title,role_id,description,required_competences(title,id,weight,short_description,competence_type_id)',
          role_meta_types: 'position,role',
          summary: 1,
          user_name: userName,
        },
      }).then(response => response.data.roles));

    yield put(fetchRolesRequestSuccess({roles}));
  } catch (error) {
    if (error.code === 401) {
      yield put(authActions.authUnauthorized({error}));
    }
    yield put(fetchRolesRequestFailure({error}));
  }
}

function* fetchRole(action) {
  const rid = action.payload;

  yield put(fetchRoleRequest());
  try {
    let userName = yield select(getProfileUserName);

    while (userName === null) {
      yield take();
      userName = yield select(getProfileUserName);
    }

    const role = yield retry(() =>
      RolesAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/roles/${rid}`,
        params: {
          fields: 'title,description,files',
          user_name: userName,
          role_ids: rid,
        },

      }).then(response => response.data.roles[0]));

    const competences = yield retry(() =>
      RolesAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/personcompetences`,
          params: {
            fields:
              'title,description,valid_until,requirement_type,certificate_url,description,competence_id,competence_type,competence(files,short_description,title,competence_type,competence_type_id,competence_id),passed,manager_check_user_id',
            state: 'all',
            limit: 101,
            user_name: userName,
            role_ids: rid,
          },
          withCredentials: true,
        })
        .then(response => response.data.personcompetences));

    for (const competence of competences) {
      competence.competence.competence_title
        = competence.competence.title;
      competence.competence.children = [];
      competence.children = [];

      competence.competence.competence_type
        = competence.competence_type;

      if (competence.competence.files.length !== 0) {
        competence.competence.cover = competence.competence.files.find(f => f.title === 'cover');
      }
    }
    role.requirements = competences;
    yield put(fetchRoleRequestSuccess({role}));
  } catch (error) {
    if (error.code === 401) {
      yield put(authActions.authUnauthorized({error}));
    }
    yield put(fetchRoleRequestFailure({error}));
  }
}

export function* fetchRolesMainPage(action) {
  let pid = yield select(getIsFullPersonFetched);

  while (pid !== 100) {
    yield take();
    pid = yield select(getIsFullPersonFetched);
  }

  yield fetchRole(action);
}

export function* fetchOrganisations() {
  yield put(rolesActions.fetchOrganisationsRequest());
  try {
    let userName = yield select(getProfileUserName);

    while (userName === null) {
      yield take();
      userName = yield select(getProfileUserName);
    }
    const organisations = yield retry(() =>
      RolesAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/organisations`,
          withCredentials: true,
          params: {user_name: userName},
        })
        .then(response => response.data.organisations));

    yield put(rolesActions.fetchOrganisationsSuccess({organisations}));
  } catch (error) {
    if (error.code === 401) {
      yield put(authActions.authUnauthorized({error}));
    }
    yield put(rolesActions.fetchOrganisationsFailure({error}));
  }
}

export default [
  fetchRolesRequired,
  takeLatest(rolesActions.FETCH_ROLE, fetchRole),
  takeLatest(rolesActions.FETCH_ROLES, fetchRoles),
  takeLatest(PROFILE_FETCH_SUMMARY, fetchRolesSummary),
  takeLatest(ROUTER_MY_EDUCATION_ROLE_VIEW_DID_MOUNT, fetchRolesMainPage),
  takeLatest(rolesActions.FETCH_ROLES_REQUIRED, fetchRolesRequired),
  takeLatest(employeesActions.EMPLOYEES_EDIT_VIEW_INIT, fetchOrganisations),
  takeLatest(employeesActions.EMPLOYEES_ADD_VIEW_INIT, fetchRoles),
  takeLatest(employeesActions.EMPLOYEES_ADD_VIEW_INIT, fetchOrganisations),
  takeLatest(ROUTER_MY_EDUCATION_PROFILE_VIEW_DID_MOUNT, fetchRoles),
  takeLatest(ROUTER_MY_EDUCATION_PROFILE_VIEW_DID_MOUNT, fetchOrganisations),
];
