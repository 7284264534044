import React, { Component, useMemo } from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {getIsMobile} from '@selectors/global.selectors';
import {employees as routes} from '@routes/routes.manifest';
import {getRouteWithParams} from '@utils/routes.utils';
import Modal from '@components/modal/modal';
import {
  getIsSuperuser,
  getOrganisationId,
  getProfileId,
  getRoles,
} from '@selectors/profile.selectors';

import {
  getEmployees,
  getEmployeesStatistics,
  getFilteredEmployees,
  getFunctions,
  getIsFetchingEmployees,
  getSearchPhraze,
  getSelectedOrganisation,
  getStatisticsKindsForView,
} from '@selectors/employees.selectors';

import {routerEmployeesListDidMount} from '@actions/router.actions';
import {
  employeesSearchPhrazeChange,
  employeesSelectPerson,
  employeeMove,
  employeesGet,
  employeesAddViewInit,
  employeesFetchFunctions,
  employeesAddPerson,
} from '@actions/employees.actions';

import '@routes/employees/styles/employees-list-container.scss';

import ActionBar from '@components/actionbar/action-bar';

import PersonSearchAdd from '@components/person-search-add-form/person-search-add-form';

import SearchBar from '@routes/employees/components/search-bar';
import EmployeesList, {EmployeesListLoading} from '@routes/employees/components/employees-list';
import {i18n} from '@src/i18n/index';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import OrgansationHeader from '@routes/employees/components/organsation-header';
import EmployeeAdd from '@routes/employees/components/employee-add';
import * as T from '@types/load.types';
import reports from '@routes/reports.manifest';
import Calendar from '@components/calendar/calendar';
import EmployeesCourses from '@routes/employees/components/employee-courses/employees-courses';
import EmployeesExpired from '@routes/employees/components/employee-expired/employee-expired';
import StatisticCards from '@routes/employees/components/statistics/statistics';
import {getOrganisations} from '@selectors/roles.selectors';
import {css} from '@emotion/react';
import {getConfigObject} from '@selectors/config.selectors';
import { DropdownWithActionButton } from '@components/dropdown-with-action-button/dropdown-with-action-button';

class EmployeesListContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isSearchForEmployeeOpen: false,
      isPersonAddNewOpen:false,
    };

    this.setModalVisibility = this.setModalVisibility.bind(this);
    this.handleAddEmployeeClick = this.handleAddEmployeeClick.bind(this);
    this.handleSearchPhrazeChange = this.handleSearchPhrazeChange.bind(this);
    this.handleEmployeeSelect = this.handleEmployeeSelect.bind(this);
    this.clickShowSearchEmployee = this.clickShowSearchEmployee.bind(this);
    this.clickAddEmployee = this.clickAddEmployee.bind(this);
    this.handleEmployeeMove = this.handleEmployeeMove.bind(this);
    this.clickShowAllEmployees = this.clickShowAllEmployees.bind(this);
    this.escapeListener = this.escapeListener.bind(this);
    this.handleAddEmployeeViewInit = this.handleAddEmployeeViewInit.bind(this);
    this.handleAddEmployeeFormSubmit = this.handleAddEmployeeFormSubmit.bind(this);
    this.handleAddEmployeeFormCancel = this.handleAddEmployeeFormCancel.bind(this);
  }

  componentDidMount() {
    const {routerEmployeesListDidMount, match, searchOnly} = this.props;

    if (!searchOnly) {
      routerEmployeesListDidMount({orgId: match.params.orgId});
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const {routerEmployeesListDidMount, match} = this.props;
    const {match: prevMatch} = prevProps;

    if (prevMatch && match.params?.orgId !== prevMatch.params?.orgId) {
      localStorage.setItem('orgId', match.params.orgId);
      routerEmployeesListDidMount({orgId: match.params.orgId});
    }
  }

  setModalVisibility(type, visibility) {
    if (type === 'personsearchadd') {
      this.setState({isPersonAddNewOpen: false});
      this.setState({isSearchForEmployeeOpen: visibility});
    }
    if(type === 'personaddnew') {
      this.setState({isSearchForEmployeeOpen: false});
      this.setState({isPersonAddNewOpen: visibility});
    }

    this.toggleEscapeListener(visibility);
  }

  handleSearchNewEmployeeFormSubmit() {
    this.setModalVisibility('personsearchadd', false);
  }

  escapeListener({key}) {
    if (key === 'Escape') {
      this.setState({isSearchForEmployeeOpen: false});
    }
  }

  toggleEscapeListener(on) {
    if (on) {
      document.addEventListener('keydown', this.escapeListener);
    } else {
      document.removeEventListener('keydown', this.escapeListener);
    }
  }

  handleEmployeeMove(data) {
    const {employeeMove} = this.props;
    const {employee, operation, position} = data;

    this.setModalVisibility('personsearchadd', false);
    employeeMove({
      personId: employee,
      position,
      move_type: operation,
    });
  }

  updateOrg = org => {
    const {history: {push}} = this.props;

    push(getRouteWithParams(routes.employees, {orgId: org}));
  };

  handleAddEmployeeClick() {
    const {history: {push}} = this.props;

    push(routes.addEmployee);
  }

  handleSearchPhrazeChange(searchPhraze) {
    const {employeesSearchPhrazeChange} = this.props;

    employeesSearchPhrazeChange({searchPhraze});
  }

  clickShowAllEmployees() {
    const {employeesGet} = this.props;

    employeesGet({all: true});
  }

  clickShowSearchEmployee() {
    this.setModalVisibility('personsearchadd', true);
  }
c
  clickAddEmployee() {
    this.setModalVisibility('personaddnew', true);
  }

  handleEmployeeSelect(employee) {
    const {
      employeesSelectPerson,
      history: {push},
      orgId,
    } = this.props;
    const {user_name: userName} = employee;

    employeesSelectPerson({userName});
    const urlWithParams = getRouteWithParams(routes.employeePreview, {
      userName,
      orgId,
    });

    push(urlWithParams);
  }

  handleAddEmployeeViewInit = userName => {
    const {employeesAddViewInit, employeesFetchFunctions} = this.props;

    employeesAddViewInit({userName});
    employeesFetchFunctions();
  };

  handleAddEmployeeFormSubmit = employee => {
    const {employeesAddPerson, employeesGet} = this.props;

    employeesAddPerson({
      employee,
      callback: ()  => {
        this.setModalVisibility('personaddnew', false);
        employeesGet({all: true});
      },
    });
  };

  handleAddEmployeeFormCancel() {
    this.setModalVisibility('personaddnew', false);
  }

  render() {
    const {
      isFetchingEmployees,
      employees,
      isMobile,
      events,
      tenets,
      personId,
      isSuperUser,
      organisationId,
      expired,
      statistics,
      filteredEmployees,
      searchPhraze,
      searchOnly,
      mainOrganisation,
      roles,
      functions,
      organisations,
      orgId,
      statCards,
      configObject,
    } = this.props;


    const breadPath = [{
      breadcrumb: i18n('menu.employees'),
      match: {url: getRouteWithParams(routes.employees, {orgId})},
    }];

    if (mainOrganisation.status === T.LoadStatuses.LOADED && mainOrganisation.data.parent) {
      if(mainOrganisation.data.parent.manager === true) {
        breadPath.push({
          breadcrumb: mainOrganisation.data.parent.title,
          match: {url: '/employees/' + (mainOrganisation.data.parent.manager ? mainOrganisation.data.parent.organisation_id : orgId)},
        });
      }

      breadPath.push({
        breadcrumb:mainOrganisation.data.title,
        match: {url: '/employees/' + mainOrganisation.data.organisation_id},
      });
    }

    const {isSearchForEmployeeOpen, isPersonAddNewOpen} = this.state;
    const showAllEmployees = !searchOnly && searchPhraze.length <= 3;

    const hasMore
      = showAllEmployees && employees.data && employees.hasMore
      || searchPhraze.length > 3
        && filteredEmployees.data
        && filteredEmployees.hasMore
      || false;

    const employeesList
      = showAllEmployees && employees.data
      || searchPhraze.length > 3 && filteredEmployees.data
      || [];

    const actionBarItems = [];
    const actionBarConfig = configObject.getProperty('employees.manage');

    if(!actionBarConfig || actionBarConfig.add) {
      actionBarItems.push({
        label: i18n('employees.add-employee'),
        value:'add-employee',
      });
    }

    if(!actionBarConfig || actionBarConfig.move) {
      actionBarItems.push({
        label: i18n('employees.move-employee'),
        value:'move-employee',
      });
    }

    if(!actionBarConfig || actionBarConfig.export) {
      actionBarItems.push({
        label: i18n('employees.export-to-excel'),
        value:'export-excel',
      });
    }

    return (
      <>
        {!searchOnly && (
          <div className="employee-list-header">
            <div className="wrapper">
              <Breadcrumbs
                breadcrumbs={breadPath}
                disableMyPage
              />
              <OrgansationHeader
                mainOrganisation={mainOrganisation}
                updateActiveOrg={this.updateOrg}
                tenets={tenets?.status === T.LoadStatuses.LOADED && tenets.data}
              />
            </div>
          </div>
        )}

        <div className="employees-list-container">
          {expired.status === T.LoadStatuses.LOADED && expired.data.length !== 0 && (
            <div className="employees-list-container__expired-elements grid-x">
              <div className="small-12 cell">
                <EmployeesExpired
                  orgId={orgId}
                  expired={expired}
                />
              </div>
            </div>
          )}
          <div className="grid-x">
            <div className="cell small-12 medium-12">
              <StatisticCards
                reports={reports}
                statistics={statistics}
                statCards={statCards}
                orgId={orgId}
                isMobile={isMobile}
              />
            </div>
            <div className="employees-list-container__course-elements grid-x">
              <div className="hide-for-small-only medium-6 large-3 cell">
                <Calendar />
              </div>
              <div className="small-12 medium-6 large-9 cell">
                <EmployeesCourses
                  myProfileId={personId}
                  myOrganisationId={orgId}
                  events={events}
                />
              </div>
            </div>

            <div className={`employees-list-container__search-and-actions-wrapper ${!isMobile ? 'grid-x' : ''}`}>
              <div
                className={`${
              searchOnly ? 'small-12 medium-12' : actionBarItems.length > 0 &&  'small-8 medium-10' || 'small-12'
            } cell`}
              >
                <div className="employees-list-container__search-bar-outer-wrapper">
                  <div className="employees-list-container__search-bar-wrapper">
                    <SearchBar
                      placeholder={searchOnly && i18n('employees.search', {ellipsis: true}) || i18n('globals.search', {ellipsis: true})}
                      value={searchPhraze}
                      name="search-bar"
                      loading={isFetchingEmployees !== T.LoadStatuses.LOADED}
                      onChange={({target: {value}}) => {
                        this.handleSearchPhrazeChange(value);
                      }}
                    />
                    <div className="search-bar__dropdown__wrapper">
                      <div className="search-bar__dropdown">
                        {searchPhraze.length > 3
                      && !isFetchingEmployees
                      && !employeesList && (
                        <div className="search-bar__dropdown__item">
                          Fant ingen resultater
                        </div>
                        )
                      || !!searchPhraze.length && searchPhraze.length <= 3 && (
                        <div className="search-bar__dropdown__item">
                          {i18n('employees.min-letters', {functionArgs:{x: 4}})}
                        </div>
                      )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {!searchOnly && actionBarItems.length > 0 && (
                <div className="small-4 medium-2 cell">
                  <ActionBar
                    searchEmployee={this.clickShowSearchEmployee}
                    addEmployee={this.clickAddEmployee}
                    type="employee-bar"
                    items={actionBarItems}
                    orgId={orgId}
                  />
                </div>
              )}
            </div>
          </div>

          {isFetchingEmployees === T.LoadStatuses.LOADED && (
            <EmployeesList
              hasMore={hasMore}
              isSuperUser={isSuperUser}
              isFetching={isFetchingEmployees}
              onEmployeeMore={this.clickShowAllEmployees}
              employees={employeesList}
              isMobile={isMobile}
              onEmployeeSelect={this.handleEmployeeSelect}
            />
          )
          || <EmployeesListLoading />}

          {isSearchForEmployeeOpen && (
            <Modal
              onClose={() =>
                this.setModalVisibility('personsearchadd', false)}
              onCancel={() =>
                this.setModalVisibility('personsearchadd', false)}
              disableActionButtons
              title={() => (
                <Modal.Title>
                  {i18n('employees.search-for-employees')}
                </Modal.Title>
              )}
            >
              <PersonSearchAdd
                onSubmit={this.handleEmployeeMove}
                onCancel={
                () => this.setModalVisibility('personsearchadd', false)
              }
              />
            </Modal>
          )}

          {isPersonAddNewOpen
            && (
              <Modal
                onClose={() => {
                  this.setModalVisibility('personaddnew', false);
                }}
                onCancel={() =>
                  this.setModalVisibility('personaddnew', false)}
                disableActionButtons
                bodyWrapperStyle={css`overflow: auto;`}
                title={() => (
                  <Modal.Title>
                    {i18n('employees.add-employee')}
                  </Modal.Title>
                )}
              >
                <EmployeeAdd
                  roles={roles}
                  functions={functions}
                  organisations={organisations}
                  onInit={() => this.handleAddEmployeeViewInit()}
                  onAddEmployeeFormSubmit={this.handleAddEmployeeFormSubmit}
                  onAddEmployeeFormCancel={this.handleAddEmployeeFormCancel}
                  initialValues={{
                    position:
                      functions.position[0] && functions.position[0].title,
                    send_login: true,
                  }}
                />
              </Modal>
            )}
        </div>
      </>
    );
  }
}

EmployeesListContainer.propTypes = {
  history: PropTypes.shape({push: PropTypes.any.isRequired}).isRequired,
  isFetchingEmployees: PropTypes.shape({}).isRequired,
  employees: PropTypes.arrayOf(PropTypes.shape({})),
  filteredEmployees: PropTypes.arrayOf(PropTypes.shape({})),
  routerEmployeesListDidMount: PropTypes.func.isRequired,
  searchPhraze: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
  employeesSearchPhrazeChange: PropTypes.func.isRequired,
  employeesSelectPerson: PropTypes.func.isRequired,
  employeeMove: PropTypes.func.isRequired,
  employeesGet: PropTypes.func.isRequired,
  searchOnly: PropTypes.bool,
  mainOrganisation: PropTypes.shape({
    organisation_id: PropTypes.number.isRequired,
    extern_organisation_id: PropTypes.number,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

EmployeesListContainer.defaultProps = {
  employees: null,
  filteredEmployees: null,
  searchOnly: false,
};

const mapStateToProps = state => ({
  employees: getEmployees(state),
  events: state.employees.events,
  expired: state.employees.expiring,
  statistics: getEmployeesStatistics(state),
  statCards: getStatisticsKindsForView(state),
  personId: getProfileId(state),
  isSuperUser: getIsSuperuser(state),
  filteredEmployees: getFilteredEmployees(state),
  mainOrganisation: getSelectedOrganisation(state),
  tenets: state.profile.person.tenets,
  orgId: getOrganisationId(state),
  isMobile: getIsMobile(state),
  isFetchingEmployees: getIsFetchingEmployees(state),
  searchPhraze: getSearchPhraze(state),
  roles: getRoles(state),
  functions: getFunctions(state),
  organisations: getOrganisations(state),
  configObject: getConfigObject(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerEmployeesListDidMount,
      employeesSearchPhrazeChange,
      employeeMove,
      employeesGet,
      employeesSelectPerson,
      employeesAddViewInit,
      employeesFetchFunctions,
      employeesAddPerson,
    },
    dispatch,
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(EmployeesListContainer));
