import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import CourseButtons from '@components/course/course-buttons';

import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendarAlt, faUserCheck, faEyeSlash} from '@fortawesome/pro-solid-svg-icons';
import {ActionButton} from '@components/buttons/actionButton';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import completed from '../../../assets/svg/completed.svg';
import {EyeSlash, Pencil} from 'phosphor-react';
import {getIsMobile} from '@selectors/global.selectors';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {css} from '@emotion/react';
import {FilesList} from '@components/files-list/files-list';
import {verifyPassed} from '../../utils/misc.utils';


const createSubtitleContent = content => ({__html: content});

class CompetenceSignature extends Component {
  constructor(props) {
    super(props);
    this.state = {filesNotClicked: []};
  }

  componentDidMount() {
    const {course} = this.props;

    this.setState({filesNotClicked: course && course.files ? course.files.map(f => f.url) : []});
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    if (removeRequirement) {
      removeRequirement(id);
    }
  }

  render() {
    const {
      size,
      course,
      roleName,
      roleId,
      defaultImg,
      canEdit,
      onEdit,
      isMobile,
      isPassed,
    } = this.props;

    const {filesNotClicked} = this.state;

    return (
      <>
        {size === 'big' && (
          <>
            <Link
              to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                courseId: course.id,
                roleId,
              })}
              className="cell small-4 medium-4 course-image-wrapper"
            >
              <div
                className="img"
                style={{
                  backgroundImage: `url(${course.cover && course.cover.url
                  || defaultImg})`,
                }}
              />
            </Link>
            <div className="content cell small-8 medium-7 course-content-wrapper">
              <Link
                to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                  courseId: course.id,
                  roleId,
                })}
                className="__title"
              >
                <h2>{course.competence_title}</h2>
              </Link>
              {course.competence_type.competence_type
              && (
                <div>
                  {i18nCourseGetCompetenceType(course)}
                </div>
              )}
              <div
                className="desc"
                dangerouslySetInnerHTML={createSubtitleContent(course.short_description)}
              />

              {course.files && !!course.files.length && (
                <div className="course-preview__files">
                  <FilesList
                    files={course.files.map(({title, url}) => ({
                      title,
                      url,
                      state:  isPassed ?  'default' :  filesNotClicked.find(urlNotClicked => url === urlNotClicked) && 'unread' || 'read',
                    }))}
                    onClickFile={({url}) => {
                      this.setState({
                        filesNotClicked:
                          filesNotClicked.filter(cf => cf !== url),
                      });
                    }}
                  />

                </div>
              )}
              <CourseButtons
                disabled={filesNotClicked.length !== 0}
                course={course}
              />
            </div>
          </>
        )}
        {size === 'compact' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-9 cell">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
                {course.files && !!course.files.length && (
                  <div className="course-preview__files">
                    {course.files.map(({title, url}) =>
                      title !== 'cover' && (
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn course-preview__file-button"
                        >
                          <i className="fas fa-file-alt"/>
                          {' '}
                          {title}
                        </a>
                      ))}
                  </div>
                )}
              </div>
              <div className="small-3 cell">
                <CourseButtons course={course} />
              </div>
            </div>
          </div>
        )}
        {size === 'employee' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 cell">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >
                  {canEdit && !isMobile && (
                    <div className="edit-button">
                      <ActionButton
                        variant="defaultTheme"
                        onClick={() => onEdit(course)}
                      >
                        {i18n('globals.edit')}
                      </ActionButton>
                    </div>
                  )}
                  {canEdit && isMobile && (
                    <div className="edit-button mobile">
                      <Pencil
                        color="#0b5dd7"
                        role="button"
                        tabIndex="0"
                        onKeyUp={evt => evt.key === 'Enter' && onEdit(course)}
                        onClick={() => onEdit(course)}
                      />
                    </div>
                  )}
                  <div className="roles-list__competence-title">
                    <div
                      className="roles-list__competence-status"
                    >
                      {verifyPassed(course)
                      && (
                        <img
                          src={completed}
                          alt="all-ok"
                          style={{width: 20}}
                        />
                      )}
                    </div>
                    <div className="info-wrapper">
                      <div className="small-header">
                        {i18nCourseGetCompetenceType(course)}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            &nbsp;&nbsp;
                            {course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon={faUserCheck} />
                            &nbsp;&nbsp;
                            {course.verified.fullname}
                          </div>
                        )}
                      </div>
                    </div>
                    {course.files && course.files.length > 0 && (
                      <FilesList
                        className="files-list"
                        files={course.files.map(({title, url}) => ({
                          title,
                          url,
                        }))}
                        wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                      />
                    )}

                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>
            </div>
          </div>
        )}
        {size === 'compact-sub' && (
          <div className="competence-card-sub">
            <div className="grid-x">
              <div className="small-9 cell info-wrapper">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
              </div>
              {course.files && course.files.length > 0 && (
                <FilesList
                  className="files-list"
                  files={course.files.map(({title, url}) => ({
                    title,
                    url,
                  }))}
                  wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                />
              )}

              <div className="small-3 cell">
                <CourseButtons course={course} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

CompetenceSignature.propTypes = {
  course: PropTypes.shape({}).isRequired,
  roleName: PropTypes.string.isRequired,
  removeRequirement: PropTypes.func,
  size: PropTypes.string.isRequired,
  roleId: PropTypes.number,
};

CompetenceSignature.defaultProps = {
  roleId: -2,
  removeRequirement: undefined,
};


const mapStateToProps = state => ({isMobile: getIsMobile(state)});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetenceSignature);

