import React from 'react';
import {useSelector} from 'react-redux';
import moment from 'moment';

import PropTypes from 'prop-types';
import {css} from '@emotion/react';
import {i18n} from '@src/i18n';
import Loading from '@components/loading/loading';
import {getConfigObject} from '@selectors/config.selectors';


/* Displays a skeleton for an article */
const CourseLoader = ({
  startup,
  appload,
}) => {
  const configObject = useSelector(getConfigObject);
  const logo = configObject.getProperty('params.menu.logo');
  return (
    <div
      className="course-loader"
      css={css`
        position: fixed;
        background: rgba(0, 0, 0, 0) linear-gradient(rgb(0, 3, 54) 0%, rgb(0, 62, 126) 100%) no-repeat scroll center center / cover;
        top: 0;
        left: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        justify-content: center;
        justify-items: center;
        display: flex;
        align-items: center;
        align-content: center;
        color: white;
        z-index: 3000;
      `}
    >
      <div
        className="course-loader-content"
      >
        <div style={{
          marginTop: 30,
          minWidth: 200,
          justifyContent: 'center',
          display: 'flex'
        }}
        >
          {logo && (
            <img src={logo}
                 className="course-loader-image"
                 style={{marginBottom: 2}}
            />
          )}
        </div>
        <Loading />
        <div
          style={{
            position: 'fixed',
            bottom: 10,
            left: 10,
          }}
          className="course-loader-text"
        >
          <div
            style={{
              fontSize: 16,
              float: 'left',
              fontWeight: 900,
            }}
            className="course-loader-text"
          >
            {moment()
              .format('HH:mm')}
          </div>
          <div
            style={{
              fontSize: 14,
              float: 'left',
              marginLeft: 10,
              paddingTop: 2,
              color: 'rgba(255,255,255, .4)',
              fontWeight: 300,
            }}
            className="course-loader-text"
          >
            {appload && (
              <>
                {i18n('globals.loading')}
              </>
            ) || startup && (
              <>
                {i18n('globals.loading-nano')}
              </>
            ) || (
              <>
                {i18n('globals.registrer')}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
};

CourseLoader.propTypes = {
  isMobile: PropTypes.bool,
  startup: PropTypes.bool,
  wrapperstyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  wrapperstyleMobile: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

CourseLoader.defaultProps = {
  isMobile: false,
  startup: false,
  wrapperstyle: '',
  wrapperstyleMobile: '',
};

export default CourseLoader;
