import React, {useState, useEffect} from 'react';
import classNames from 'classnames';
import {connect} from 'react-redux';
import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';

import './modal.scss';
import {getIsMobile} from '@selectors/global.selectors';
import {i18n} from '@src/i18n';
import {StyledModalTitle, LearnMoreWrapper, BottomButtonsWrapper, ActionButtonsWrapper, Wrapper, LearnMoreLink, BodyWrapper, TitleWrapper, ContentWrapper, CloseButton} from './modal-styles';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import {ArrowCircleUpRight} from 'phosphor-react';
import {ActionButton} from '@components/buttons/actionButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/**
 * A dialog-box where the user has to make a decision(or possibly close the modal)
 * @example
 * <Modal
 *  title={<Modal.Title>Add new modal</Modal.Title>}
 *  submitButtonText="Add it"
 *  onClose={()=>setShowModal(false)}
 *  onCancel={()=>setShowModal(false)}
 *  onSubmit={addNewModal}
 * >
 * This will add a new modal
 * </Modal>
 */
const Modal = connect(state => ({isMobile: getIsMobile(state)}))(({
  isMobile,
  onClose,
  fullWidth,
  withPhosphorIcon,
  onCancel,
  onConfirm,
  classNameContent,
  classNameWrapper,
  disableActionButtons,
  variant,
  title,
  bodyWrapperStyle,
  disableCloseButton,
  withReadMoreLnk,
  children,
  isFetching,
  cancelButtonText,
  submitButtonText,
  cancelButtonStyle,
  submitButtonStyle,
  wrapperStyle,
  cancelButtonStyledAsSubmitButton,
  ...props
}) => {
  const TitleComponent = title;
  const PhosphorIconComponent = withPhosphorIcon && withPhosphorIcon.icon;

  return createPortal(
    <Wrapper
      role="modal"
      className={classNameWrapper || ""}
    >
      <div
        className="modal__backdrop"
        onClick={() => variant !== 'warning' && onClose && onClose()}
      />
      {isFetching && <LoadingIndicator />}
      <ContentWrapper
        hasIcon={withPhosphorIcon}
        fullWidth={fullWidth}
        isMobile={isMobile}
        className={classNames('modal__content', classNameContent)}
        css={
          {...{
            displau: isFetching ? 'none' : 'flex'
          },
          ...wrapperStyle}}
      >
        {variant !== 'warning' && !disableCloseButton
        && (
          <CloseButton
            className="close-btn"
            isMobile={isMobile}
            ariaLabel={i18n('globals.close')}
            title={i18n('globals.close')}
            onClick={() => onClose && onClose()}
          >
            {' '}
            <FontAwesomeIcon icon={faTimes} />
            {' '}

          </CloseButton>
        )}

        <TitleWrapper isMobile={isMobile}>
          {withPhosphorIcon
       && (
         <PhosphorIconComponent
           css={{
             marginRight: '0.7rem',
             width:'1.75rem',
             height: '1.75rem',
             alignSelf: 'center',
             ...withPhosphorIcon.css || {},
           }}

           {...withPhosphorIcon}
         />
       )}
          {TitleComponent
          && <TitleComponent />}

        </TitleWrapper>
        <BodyWrapper css={bodyWrapperStyle}>
          {children}
        </BodyWrapper>
        {withReadMoreLnk || !disableActionButtons && (
          <BottomButtonsWrapper isMobile={isMobile}>
            {withReadMoreLnk
              && (
                <LearnMoreWrapper>
                  <LearnMoreLink
                    href={withReadMoreLnk.href}
                    target="_blank"
                  >
                    {withReadMoreLnk.text || i18n('global.read-more')}
                  </LearnMoreLink>
                  <ArrowCircleUpRight
                    weight="fill"
                    style={{fontSize:'1.1em'}}
                  />
                </LearnMoreWrapper>
              )}
            {!disableActionButtons
                && (
                  <ActionButtonsWrapper>
                    <ActionButton
                      variant={cancelButtonStyledAsSubmitButton && 'primary' || 'cancel'}
                      onClick={onCancel}
                      css={cancelButtonStyle}
                    >
                      {cancelButtonText || i18n('globals.cancel')}
                    </ActionButton>
                    <ActionButton
                      variant={variant === 'warning' ? "danger" : "primary"}
                      onClick={onConfirm}
                      css={submitButtonStyle}
                    >
                      {submitButtonText || i18n('globals.confirm')}
                    </ActionButton>
                  </ActionButtonsWrapper>
                )}
          </BottomButtonsWrapper>
        )}
      </ContentWrapper>
    </Wrapper>,
    document.querySelector('#modal'),
  );
});

const LoadingIndicator = () => {
  const maxLoadingState = 3;
  const loadingChar = '.';
  const [loadingState, setLoadingState] = useState(3);

  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingState(loadingState =>
        loadingState >= maxLoadingState ? 0 : loadingState + 1);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <p className="modal__loading-text">
      {i18n('globals.loading')}
      {' '}
      {loadingChar.repeat(loadingState)}
    </p>
  );
};

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
  className: PropTypes.string,
  classNameContent: PropTypes.string,

  // if the data for the modal is loaded
  dataLoaded: PropTypes.bool,

  // if true - displays a loading animation untill dataLoaded is true and then opens the modal
  waitForLoadedDataWithAnimation: PropTypes.bool,
};

Modal.defaultProps = {
  children: null,
  className: null,
  classNameContent: null,
  dataLoaded: false,
  waitForLoadedDataWithAnimation: false,
};

export const Title = ({children, withPhosphorIcon, ...props}) => (
  <StyledModalTitle {...props}>

    {children}
  </StyledModalTitle>
)
  ;

Modal.Title = Title;

export default Modal;
