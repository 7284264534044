import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import CourseButtons from '@components/course/course-buttons';
import {faCalendarAlt, faHourglass, faUserCheck} from '@fortawesome/pro-solid-svg-icons';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import {FilesList} from '@components/files-list/files-list';
import {css} from '@emotion/react';

const createSubtitleContent = content => ({__html: content});

class CompetenceNano extends Component {
  constructor(props) {
    super(props);
    this.removePersonalCompetence = this.removePersonalCompetence.bind(this);
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    removeRequirement(id);
  }

  render() {
    const {size, course, roleName, roleId, removeRequirement, defaultImg} = this.props;

    return (
      <>
        {size === 'big' && (
          <>
            <Link
              to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                courseId: course.id,
                roleId,
              })}
              className="cell small-3 medium-3 course-image-wrapper"
            >
              <div
                className="img"
                style={{
                  backgroundImage: `url(${course.cover && course.cover.url
                  || defaultImg})`,
                }}
              />
            </Link>
            <div className="content cell small-8 medium-7 course-content-wrapper">
              <Link
                to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                  courseId: course.id,
                  roleId,
                })}
                className="__title"
              >
                <h2>{course.competence_title}</h2>
              </Link>
              {course.competence_type.competence_type
                && (
                  <div>
                    {i18nCourseGetCompetenceType(course)}
                  </div>
                )}
              <div
                className="desc"
                dangerouslySetInnerHTML={createSubtitleContent(course.short_description)}
              />
              {course.files && !!course.files.length && (
                <FilesList
                  className="files-list"
                  files={course.files}
                  wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                />
              )}
              <CourseButtons course={course} />
            </div>
          </>
        )}

        {size === 'compact' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 medium-9 cell">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
              </div>
              <div className="small-12 medium-3 cell">
                <CourseButtons course={course} />
              </div>
            </div>
          </div>
        )}
        {size === 'employee' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 cell">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >
                  <div className="roles-list__competence-title">
                    <div
                      className={classNames([
                        'roles-list__competence-status',
                        {
                          'roles-list__competence-status--not-started':
                            course.passed === 0,
                        },
                        {
                          'roles-list__competence-status--in-progress':
                            course.passed > 0 && course.passed < 100,
                        },
                        {
                          'roles-list__competence-status--completed':
                            course.passed === 100,
                        },
                      ])}
                    />
                    <div className="grid-x">
                      <div className="cell small-12 medium-8 info-wrapper">
                        <div className="small-header">
                          {i18nCourseGetCompetenceType(course)}
                          {roleId === -2
                            && course.passed !== 100
                            && removeRequirement && (
                              <div className="removeThis">
                                <button
                                  type="button"
                                  onClick={() =>
                                    this.removePersonalCompetence(course.competence_id)}
                                >
                                  <i className="far fa-trash-alt" />
                                </button>
                              </div>
                          )}
                        </div>
                        {course.competence_title}
                        <div className="signed-info">
                          {course.verified && course.verified.date && (
                            <div className="signed-date">
                              <FontAwesomeIcon icon={faCalendarAlt} />
                                &nbsp;&nbsp;
                              {course.verified.date}
                            </div>
                          )}
                          {course.verified && course.verified.fullname && (
                            <div className="signed-by">
                              <FontAwesomeIcon icon={faUserCheck} />
                                &nbsp;&nbsp;
                              {course.verified.fullname}
                            </div>
                          )}
                          {course.valid_until && (
                            <div className="signed-by">
                              <FontAwesomeIcon icon={faHourglass} />
                                &nbsp;&nbsp;
                              {course.valid_until}
                            </div>
                          )}
                        </div>
                      </div>

                      {course.files && course.files.length > 0 && (
                        <FilesList
                          className="files-list"
                          files={course.files.map(({title, url}) => ({
                            title,
                            url,
                          }))}
                          wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                        />
                      )}

                      <div className="cell small-12 medium-4">
                        {course.passed === 100
                          && !!course.grade
                          && course.grade !== 0 && (
                            <div className="course-grade">
                              Karakter:
                              {' '}
                              {course.grade}
                            </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>
            </div>
          </div>
        )}
        {size === 'compact-sub' && (
          <div className="competence-card-sub">
            <div className="grid-x">
              <div className="small-9 cell">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
              </div>
              <div className="small-3 cell">
                <CourseButtons course={course} />
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

CompetenceNano.propTypes = {
  course: PropTypes.shape({}).isRequired,
  roleName: PropTypes.string.isRequired,
  removeRequirement: PropTypes.func,
  size: PropTypes.string.isRequired,
  roleId: PropTypes.number,
};

CompetenceNano.defaultProps = {
  roleId: -2,
  removeRequirement: undefined,
};

export default CompetenceNano;
