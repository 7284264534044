import {
  EMPLOYEES_GET_REQUEST,
  EMPLOYEES_GET_SUCCESS,
  EMPLOYEES_GET_FAILURE,
  EMPLOYEES_SEARCH_REQUEST,
  EMPLOYEES_SEARCH_SUCCESS,
  EMPLOYEES_SEARCH_FAILURE,
  EMPLOYEES_SEARCH_PHRAZE_CHANGE,
  EMPLOYEES_PERSON_SELECT,
  EMPLOYEES_FETCH_FUNCTIONS_REQUEST,
  EMPLOYEES_FETCH_FUNCTIONS_SUCCESS,
  EMPLOYEES_FETCH_FUNCTIONS_FAILURE,
  EMPLOYEES_FETCH_SELECTED_PERSON_REQUEST,
  EMPLOYEES_FETCH_SELECTED_PERSON_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_FAILURE,
  EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_REQUEST,
  EMPLOYEES_FETCH_STATISTICS_GET_REQUEST,
  EMPLOYEES_FETCH_STATISTICS_GET_SUCCESS,
  EMPLOYEES_FETCH_STATISTICS_GET_FAILURE,
  EMPLOYEES_FETCH_EXTRADATA_REQUEST,
  EMPLOYEES_FETCH_EXTRADATA_SUCCESS,
  EMPLOYEES_FETCH_EXTRADATA_FAILURE,
  EMPLOYEES_FETCH_ACTIVITIES_GET_REQUEST,
  EMPLOYEES_FETCH_ACTIVITIES_GET_SUCCESS,
  EMPLOYEES_FETCH_ACTIVITIES_GET_FAILURE,
  EMPLOYEES_REPORT_VIEW_FAILURE,
  EMPLOYEES_REPORT_VIEW_REQUEST,
  EMPLOYEES_REPORT_VIEW_SUCCESS,
  EMPLOYEES_SAVE_VERIFICATION_REQUEST,
  EMPLOYEES_SAVE_VERIFICATION_SUCCESS,
  EMPLOYEES_SAVE_VERIFICATION_FAILURE,
  EMPLOYEES_SAVE_REQUIREMENTS_REQUEST,
  EMPLOYEES_SAVE_REQUIREMENTS_SUCCESS,
  EMPLOYEES_SAVE_REQUIREMENTS_FAILURE,
  EMPLOYEES_SAVE_ROLE_REQUEST,
  EMPLOYEES_SAVE_ROLE_SUCCESS,
  EMPLOYEES_SAVE_ROLE_FAILURE,
  EMPLOYEES_FETCH_EVENTS_REQUEST,
  EMPLOYEES_FETCH_EVENTS_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_REQUEST,
  EMPLOYEES_FETCH_EVENTS_FAILURE,
  EMPLOYEES_FETCH_EXPIRING_COMPETENCES_REQUEST,
  EMPLOYEES_FETCH_EXPIRING_COMPETENCES_SUCCESS,
  EMPLOYEES_FETCH_EXPIRING_COMPETENCES_FAILURE,
  EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_REQUEST,
  EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_REQUEST,
  EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_SUCCESS,
  EMPLOYEES_FETCH_CHECKLISTS_GET_FAILURE,
  EMPLOYEES_FETCH_CHECKLISTS_GET_REQUEST,
  EMPLOYEES_FETCH_CHECKLISTS_GET_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_REQUEST,
  EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_SUCCESS,
  EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_FAILURE,
  EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_REQUEST,
  EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_SUCCESS,
  SWITCH_EMPLOYEE_TAB,
  EMPLOYEES_FETCH_ORGANISATION_REQUEST,
  EMPLOYEES_FETCH_ORGANISATION_SUCCESS,
  EMPLOYEES_FETCH_ORGANISATION_FAILURE,
  EMPLOYEES_FETCH_TREE_FAILURE,
  EMPLOYEES_FETCH_TREE_REQUEST,
  EMPLOYEES_FETCH_TREE_SUCCESS, EMPLOYEES_FETCH_TREE_PROGRESS_SUCCESS,
  EMPLOYEES_FETCH_EVENTS_WAITLIST_REQUEST,
  EMPLOYEES_FETCH_EVENTS_WAITLIST_SUCCESS,
  EMPLOYEES_FETCH_EVENTS_WAITLIST_FAILURE,

} from '@actions/employees.actions';
import * as T from '../../store/types/load.types';


const initialState = {
  list: {
    isFetching: false,
    hasMore: false,
    status: T.LoadStatuses.NOT_LOADED,
    hasChildren: false,
    data: null,
  },
  tree: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  organisation: {
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
  },
  organisations: {
    status: T.LoadStatuses.NOT_LOADED,
    hasMore: false,
    data: null,
  },
  filteredList: {
    isFetching: false,
    hasMore: false,
    data: null,
  },
  selectedPerson: {
    userName: null,
    isFetching: false,
    elements: {
      checklists: {isFetching: false},
      events: {isFetching: false},
      positions: {isFetching: false},
      summary: {isFetching: false},
      activities: {isFetching: false},
      expiring: {
        status: T.LoadStatuses.NOT_LOADED,
        isFetching: false
      },
    },
    data: null,
    tab: 'role',
    error: null,
    report: {
      isFetching: false
    },
  },
  statistics: {
    isFetching: false,
    status: T.LoadStatuses.NOT_LOADED,
    data: null,
    error: null,
  },
  checklists: {
    isFetching: false,
    data: null,
    error: null,
  },
  expiring: {
    status: T.LoadStatuses.NOT_LOADED,
    isFetching: false,
    error: null,
    data: null,
  },
  extraData: {
    isFetching: false,
    error: null,
    data: null,
  },
  activities: {
    isFetching: false,
    data: null,
    error: null,
  },
  report: {
    status: T.LoadStatuses.NOT_LOADED,
    id: '',
    data: null,
    error: null,
  },
  functions: {
    isFetching: false,
    data: [],
    error: null,
  },
  saving: {
    isSaving: false,
    error: null,
  },
  events: {
    status: T.LoadStatuses.NOT_LOADED,
    isFetching: false,
    data: null,
    error: null,
  },
  eventsWaitlist: {
    isFetching: false,
    data: null,
    error: null,
  },
  searchPhraze: '',
};

function searchItems(node, find){
  if (node) {
    if (node.organisation_id === find) {
      return node;
    } else if (node.children != null) {
      let result = null;
      for (let i = 0; result == null && i < node.children.length; i++) {
        result = searchItems(node.children[i], find);
      }
      return result;
    }
  }
  return null;
}

const employees = (state = initialState, action) => {
  switch (action.type) {
  case 'RESET_STORE':
    return initialState;
  case EMPLOYEES_GET_REQUEST:
    return {
      ...state,
      list: {
        ...state.list,
        status: T.LoadStatuses.IS_LOADING,
        isFetching: true,
      },
    };
  case EMPLOYEES_SEARCH_REQUEST:
    return {
      ...state,
      filteredList: {
        ...state.filteredList,
        isFetching: true,
        data: null,
      },
    };
  case EMPLOYEES_GET_SUCCESS:
    return {
      ...state,
      list: {
        ...state.list,
        isFetching: false,
        status: T.LoadStatuses.LOADED,
        hasMore: action.payload.hasMore,
        hasChildren: action.payload.hasChildren,
        data: action.payload.employees,
      },
    };
  case EMPLOYEES_SEARCH_SUCCESS:
    return {
      ...state,
      filteredList: {
        ...state.filteredList,
        isFetching: false,
        hasMore: action.payload.hasMore,
        data: action.payload.employees,
      },
    };
  case EMPLOYEES_GET_FAILURE:
    return {
      ...state,
      list: {
        ...state.list,
        isFetching: false,
        status: T.LoadStatuses.FAILED,
      },
    };
  case EMPLOYEES_SEARCH_FAILURE:
    return {
      ...state,
      filteredList: {
        ...state.filteredList,
        isFetching: false,
      },
    };
  case EMPLOYEES_SEARCH_PHRAZE_CHANGE:
    return {
      ...state,
      searchPhraze: action.payload.searchPhraze,
    };
  case SWITCH_EMPLOYEE_TAB:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        tab: action.payload,
      },
    };
  case EMPLOYEES_PERSON_SELECT:
    return {
      ...state,
      selectedPerson: {
        ...initialState.selectedPerson,
        userName: action.payload.userName,
      },
    };
  case EMPLOYEES_FETCH_FUNCTIONS_REQUEST: {
    return {
      ...state,
      functions: {
        ...state.functions,
        isFetching: true,
      },
    };
  }
  case EMPLOYEES_FETCH_FUNCTIONS_SUCCESS: {
    return {
      ...state,
      functions: {
        ...state.functions,
        data: action.payload.functions,
        isFetching: false,
      },
    };
  }
  case EMPLOYEES_FETCH_FUNCTIONS_FAILURE: {
    return {
      ...state,
      functions: {
        ...state.functions,
        error: action.payload.error,
        isFetching: false,
      },
    };
  }
  case EMPLOYEES_FETCH_SELECTED_PERSON_REQUEST:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        isFetching: true,
        data: null,
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_SUCCESS:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        isFetching: false,
        data: action.payload.person,
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_REQUEST:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        data: {
          ...state.selectedPerson.data,
          summary: {
            ...state.selectedPerson.data.summary,
            isFetching: true,
          },
        },
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_REQUEST:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        isFetching: false,
        elements: {
          ...state.selectedPerson.elements,
          checklists: {isFetching: true},
        },
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_CHECKLISTS_SUCCESS:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        isFetching: false,
        data: {
          ...state.selectedPerson.data,
          checklists: action.payload.data,
        },
        elements: {
          ...state.selectedPerson.elements,
          checklists: {isFetching: false},
        },
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_COMPETENCES_SUCCESS:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        isFetching: false,
        data: action.payload.data,
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_FAILURE:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_REQUEST:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        report: {
          status: T.LoadStatuses.IS_LOADING
        },
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_SUCCESS:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        report: {
          status: T.LoadStatuses.LOADED,
          data: action.payload.data,
        },
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_REPORT_FAILURE:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        report: {
          isFetching: false,
          status: T.LoadStatuses.FAILED,
          data: null,
        },
      },
    };
  case EMPLOYEES_FETCH_ORGANISATION_REQUEST:
    return {
      ...state,
      organisation: {
        ...state.organisation,
        status: T.LoadStatuses.IS_LOADING,
        data: null,
      },
    };
  case EMPLOYEES_FETCH_ORGANISATION_SUCCESS:
    return {
      ...state,
      organisation: {
        ...state.organisation,
        status: T.LoadStatuses.LOADED,
        data: action.payload.data,
      },
    };
  case EMPLOYEES_FETCH_TREE_REQUEST:
    if (action.payload && action.payload.fetchMore) {
      const child = searchItems(state.tree.data, action.payload.orgId);
      if(child) {
        child.status = T.LoadStatuses.IS_LOADING;
      }
      return {
        ...state,
        tree: {
          ...state.tree,
        },
      };
    }else {
      return {
        ...state,
        tree: {
          ...state.tree,
          status: T.LoadStatuses.IS_LOADING,
          data: null,
        },
      };
    }


  case EMPLOYEES_FETCH_TREE_SUCCESS:
    if (action.payload && action.payload.fetchMore) {
      const child = searchItems(state.tree.data, action.payload.orgId);
      if (child) {
        child.status = T.LoadStatuses.LOADED;
        child.children = action.payload.data.children;
      }
      return {
        ...state,
        tree: {
          ...state.tree,
          data: state.tree.data,
          status: T.LoadStatuses.LOADED,
        },
      };
    }else {
      return {
        ...state,
        tree: {
          ...state.tree,
          status: T.LoadStatuses.LOADED,
          data: action.payload.data,
        },
      };
    }

  case EMPLOYEES_FETCH_TREE_PROGRESS_SUCCESS:
    if (action.payload) {
      const child = searchItems(state.tree.data, action.payload.orgId);
      if (child) {
        child.progress_status = T.LoadStatuses.LOADED;
        child.progress = action.payload.data;
      }
      return {
        ...state,
        tree: {
          ...state.tree,
          data: state.tree.data,
        },
      };
    }else {
      return {
        ...state,
        tree: {
          ...state.tree,
          data: action.payload.data,
        },
      };
    }


  case EMPLOYEES_FETCH_TREE_FAILURE:
    return {
      ...state,
      tree: {
        ...state.tree,
        status: T.LoadStatuses.FAILED,
        data: null,
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_REQUEST:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        data: {
          ...state.selectedPerson.data,
          summary: {
            ...state.selectedPerson.data.summary,
            activities: {isFetching: true},
          },
        },
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_ACTIVITIES_SUCCESS:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        isFetching: false,
        data: action.payload.data,
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_REQUEST:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        elements: {
          ...state.selectedPerson.elements,
          events: {
            status: T.LoadStatuses.IS_LOADING,
          },
        },
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_EVENTS_SUCCESS:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        data: {
          ...state.selectedPerson.data,
          events: action.payload.data,
        },
        elements: {
          ...state.selectedPerson.elements,
          events: {status: T.LoadStatuses.LOADED},
        },
      },
    };
  case EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_REQUEST:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        elements: {
          ...state.selectedPerson.elements,
          expiring: {
            isFetching: true,
            status: T.LoadStatuses.IS_LOADING,
          },
        },
      },
    };

  case EMPLOYEES_FETCH_SELECTED_PERSON_EXPIRING_SUCCESS:
    return {
      ...state,
      selectedPerson: {
        ...state.selectedPerson,
        data: {
          ...state.selectedPerson.data,
          expiring: action.payload.data,
        },
        elements: {
          ...state.selectedPerson.elements,
          expiring: {
            status: T.LoadStatuses.IS_LOADING,
            isFetching: false,
          },
        },
      },
    };
  case EMPLOYEES_FETCH_STATISTICS_GET_REQUEST:
    return {
      ...state,
      statistics: {
        ...state.statistics,
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  case EMPLOYEES_FETCH_STATISTICS_GET_SUCCESS:
    return {
      ...state,
      statistics: {
        ...state.statistics,
        isFetching: false,
        status: T.LoadStatuses.LOADED,
        data: action.payload.statistics,
      },
    };
  case EMPLOYEES_FETCH_STATISTICS_GET_FAILURE:
    return {
      ...state,
      statistics: {
        ...state.statistics,
        status: T.LoadStatuses.FAILED,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case EMPLOYEES_FETCH_CHECKLISTS_GET_REQUEST:
    return {
      ...state,
      checklists: {
        ...state.checklists,
        isFetching: true,
      },
    };
  case EMPLOYEES_FETCH_CHECKLISTS_GET_SUCCESS:
    return {
      ...state,
      checklists: {
        ...state.checklists,
        isFetching: false,
        data: action.payload.persons,
      },
    };
  case EMPLOYEES_FETCH_CHECKLISTS_GET_FAILURE:
    return {
      ...state,
      checklists: {
        ...state.checklists,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case EMPLOYEES_FETCH_EXTRADATA_REQUEST:
    return {
      ...state,
      extraData: {
        ...state.extraData,
        isFetching: true,
      },
    };
  case EMPLOYEES_FETCH_EXTRADATA_SUCCESS:
    return {
      ...state,
      extraData: {
        data: action.payload.data,
        isFetching: false,
      },
    };
  case EMPLOYEES_FETCH_EXTRADATA_FAILURE:
    return {
      ...state,
      extraData: {
        ...state.extraData,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case EMPLOYEES_FETCH_ACTIVITIES_GET_REQUEST:
    return {
      ...state,
      activities: {
        ...state.activities,
        isFetching: true,
      },
    };
  case EMPLOYEES_FETCH_ACTIVITIES_GET_SUCCESS:
    return {
      ...state,
      activities: {
        ...state.activities,
        isFetching: false,
        data: action.payload.activities,
      },
    };
  case EMPLOYEES_FETCH_ACTIVITIES_GET_FAILURE:
    return {
      ...state,
      activities: {
        ...state.activities,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case EMPLOYEES_FETCH_EXPIRING_COMPETENCES_REQUEST:
    return {
      ...state,
      expiring: {
        ...state.expiring,
        isFetching: true,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  case EMPLOYEES_FETCH_EXPIRING_COMPETENCES_SUCCESS:
    return {
      ...state,
      expiring: {
        ...state.expiring,
        isFetching: false,
        status: T.LoadStatuses.LOADED,
        data: action.payload.expiring,
      },
    };
  case EMPLOYEES_FETCH_EXPIRING_COMPETENCES_FAILURE:
    return {
      ...state,
      expiring: {
        ...state.expiring,
        isFetching: false,
        error: action.payload.error,
      },
    };
  case EMPLOYEES_REPORT_VIEW_REQUEST:
    return {
      ...state,
      report: {
        data: null,
        id: action.payload.reportId,
        status: T.LoadStatuses.IS_LOADING,
      },
    };
  case EMPLOYEES_REPORT_VIEW_SUCCESS:
    return {
      ...state,
      report: {
        data: action.payload.data,
        status: T.LoadStatuses.LOADED,
      },
    };
  case EMPLOYEES_REPORT_VIEW_FAILURE:
    return {
      ...state,
      report: {
        data: null,
        status: T.LoadStatuses.FAILED,
        error: action.payload.error,
      },
    };
  case EMPLOYEES_SAVE_VERIFICATION_REQUEST:
  case EMPLOYEES_SAVE_REQUIREMENTS_REQUEST:
  case EMPLOYEES_SAVE_ROLE_REQUEST:
    return {
      ...state,
      saving: {
        ...state.saving,
        isSaving: true,
      },
    };
  case EMPLOYEES_SAVE_VERIFICATION_FAILURE:
  case EMPLOYEES_SAVE_REQUIREMENTS_FAILURE:
  case EMPLOYEES_SAVE_ROLE_FAILURE:
    return {
      ...state,
      saving: {
        ...state.saving,
        isSaving: false,
        error: action.payload.error,
      },
    };
  case EMPLOYEES_SAVE_VERIFICATION_SUCCESS:
  case EMPLOYEES_SAVE_REQUIREMENTS_SUCCESS:
  case EMPLOYEES_SAVE_ROLE_SUCCESS:
    return {
      ...state,
      saving: {
        ...state.saving,
        isSaving: false,
        error: null,
      },
    };
  case EMPLOYEES_FETCH_EVENTS_REQUEST:
    return {
      ...state,
      events: {
        ...state.events,
        status: T.LoadStatuses.IS_LOADING,
        error: null,
      },
    };
  case EMPLOYEES_FETCH_EVENTS_SUCCESS:
    return {
      ...state,
      events: {
        ...state.events,
        status: T.LoadStatuses.LOADED,
        data: action.payload.events,
      },
    };
  case EMPLOYEES_FETCH_EVENTS_FAILURE:
    return {
      ...state,
      events: {
        ...state.events,
        isFetching: false,
        error: action.payload.error,
      },
    };

  case EMPLOYEES_FETCH_EVENTS_WAITLIST_REQUEST:
    return {
      ...state,
      eventsWaitlist: {
        ...state.eventsWaitlist,
        isFetching: true,
        error: null,
      },
    };
  case EMPLOYEES_FETCH_EVENTS_WAITLIST_SUCCESS:
    return {
      ...state,
      eventsWaitlist: {
        ...state.eventsWaitlist,
        isFetching: false,
        data: action.payload.eventsWaitlist,
      },
    };
  case EMPLOYEES_FETCH_EVENTS_WAITLIST_FAILURE:
    return {
      ...state,
      eventsWaitlist: {
        ...state.eventsWaitlist,
        isFetching: false,
        error: action.payload.error,
      },
    };
  default:
    return state;
  }
};

export default employees;
