import * as styles from './styles';
import { i18n, i18nCourseGetCompetenceType } from '@src/i18n';
import {faTimes} from '@fortawesome/pro-regular-svg-icons';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {backendUrl} from '@config';
import CompetenceSignature from '@components/competences/competence-signature';
import CompetenceNano from '@components/competences/competence-nano';
import CompetenceEcourse from '@components/competences/competence-ecourse';
import CompetenceComplex from '@components/competences/competence-complex';
import CompetenceEquivalents from '@components/competences/competence-equals';
import CompetenceVerification from '@components/competences/competence-verification';
import CompetenceChecklist from '@components/competences/competence-checklist';
import CompetenceChecklistItem from '@components/competences/competence-checklist-item';
import CompetenceCourse from '@components/competences/competence-course';
import ChevronBack from '../../../assets/svg/chevron-back.svg';
import {competencesToggle} from '@actions/competences.actions';

import {getImageUrl} from '@utils/misc.utils';
import { useDispatch, useSelector } from 'react-redux';
import {getConfigObject} from '@selectors/config.selectors';
import {AnimatePresence, motion} from 'framer-motion';
import {CloseButton} from '@components/buttons/closeButton';
import * as CT from '../../../store/types/competence';
import classNames from 'classnames';
import CheckListItem from './Components/check-list';

const CompetenceView = ({
  personId,
  orgId,
  id,
  onCancel,
  competence,
}) => {
  const configObject = useSelector(getConfigObject);
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(null);
  const [children, setChildren] = useState({
    [id]: {
      data: [],
      isFetching: false,
    },
  });

  const defaultImg = getImageUrl(configObject.getProperty('params.default-img'));

  const fetchChildren = courseId => {
    setExpanded(courseId);
    setChildren({
      ...children,
      [courseId]: {
        data: null,
        isFetching: true,
      },
    });
    axios.request({
      method: 'GET',
      url: `${backendUrl}/persons/get_children_competences_json/${personId}/${courseId}/${orgId}/0`,
      params: {
        state: 'all',
        limit: 100,
        fields:
          'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
      },
      withCredentials: true,
    }).then(request => {
      if (request) {
        setChildren({
          ...children,
          [courseId]: {
            data: request.data,
            isFetching: false,
          },
        });
      }
    });
  };

  useEffect(() => {
    if (id !== -1) {
      setChildren({
        ...children,
        [id]: {
          data: null,
          isFetching: true,
        },
      });
      axios.request({
        method: 'GET',
        url: `${backendUrl}/persons/get_children_competences_json/${personId}/${id}/${orgId}/0`,
        params: {
          state: 'all',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }).then(request => {
        if (request) {
          setChildren({
            ...children,
            [id]: {
              data: request.data,
              isFetching: false,
            },
          });
        }
      });
    }
  }, []);

  const checkCompetence = (id) => {
    console.log('id', id)
   dispatch(competencesToggle({
      cid: id,
      disableStatusUpdateCheck: true,
      noRefetchCompetencesAfterPosting: true,
    }));
    /*
    * dispatch(competencesToggle({
      cid: verification.id,
      disableNotifications: true,
      disableStatusUpdateCheck: true,
      noRefetchCompetencesAfterPosting: true,
    }));
    *
    * */
    // competencesToggle
  }
  const getCompetenceComponent = type => {
    switch (type) {
    case 'digital_signature':
      return CompetenceSignature;
    case 'nano':
      return CompetenceNano;
    case 'ecourse':
      return CompetenceEcourse;
    case 'complex':
      return CompetenceComplex;
    case 'equivalents':
      return CompetenceEquivalents;
    case 'verification':
      return CompetenceVerification;
    case 'checklist':
      return CompetenceChecklist;
    case 'checklist_item':
      return CompetenceChecklistItem;
    default:
      return CompetenceCourse;
    }
  };

  return (
    <styles.Wrapper
      mode="darkGray"
    >
      <CloseButton
        variant="darkGray"
        onClick={onCancel}
        style={{position: 'absolute'}}
        iconVariant="regular"
      />
      {CT.CourseTypes.checklist.includes(competence?.type.competence_type) && (
        <styles.CompetenceWrapper>
          <div className='my-education__section__role-required'>
            <div
              className="my-education__section__role-wrapper__card"
              style={{borderBottom: 0}}
            >
              <div className="grid-x">
                <div className="cell small-4 medium-4 course-image-wrapper">
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${competence?.cover && competence?.url
                      || defaultImg})`,
                    }}
                  />
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={{__html: competence.short_description}}
                  />
                </div>
                <div className="cell small-8 medium-7 content course-content-wrapper">
                  <div className="employees-checklist__text"
                    style={{marginBottom: 20,}}
                  >
                    <h2>{competence?.title}</h2>
                  </div>
                  <div
                    style={{marginLeft: 20}}
                  >
                  {children[id] && children[id].data && children[id].data.length !== 0 && (
                    <>
                      {children[id].data.map(course => (
                        <CheckListItem
                          onClick={() => checkCompetence(course.id)}
                          course={course}
                        />
                      ))}
                    </>
                  )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </styles.CompetenceWrapper>
      ) || (
        <styles.CompetenceWrapper>
          <div className="header">
            <h1>{competence.title}</h1>
            <div
              className="desc"
              dangerouslySetInnerHTML={{__html: competence.short_description}}
            />
          </div>
          <div
            style={{marginTop: 40}}
          >
            {children[id] && children[id].data && children[id].data.length !== 0 && (
              <div className="my-education__section__role-required">
                {children[id].data.map(course => {
                  const CourseComponent = getCompetenceComponent(course.competence_type.competence_type);

                  const currentExpanded = expanded && expanded === course.competence_id;
                  const opacity = expanded && !currentExpanded ? '0.4' : '1';
                  const loading = !children[expanded] || children[expanded].isFetching || !children[expanded].data;
                  const currentLoading = currentExpanded && loading;
                  const open = currentExpanded && !loading;

                  return (
                    <AnimatePresence
                      key={course.id}
                      exitBeforeEnter
                    >
                      {open
                        ? (
                            <motion.div
                            key="open"
                            css={{overflow: 'hidden'}}
                            animate={{
                              height: 'auto',
                              boxShadow: '0 0 6px rgba(0, 0, 0, 0.4)',
                              opacity,
                            }}
                            initial={{
                              height: 0,
                              boxShadow: '0 0 0 rgba(0, 0, 0, 0.4)',
                            }}
                            exit={{
                              height: 0,
                              boxShadow: '0 0 0 rgba(0, 0, 0, 0.4)',
                            }}
                            transition={{
                              ease: 'easeInOut',
                              duration: 0.3,
                            }}
                          >
                            <styles.SubHeader
                              onClick={() => setExpanded(null)}
                            >
                              <h2>{course.competence_title}</h2>
                              <div className="ico-back">
                                <img
                                  src={ChevronBack}
                                  alt="back"
                                />
                              </div>
                            </styles.SubHeader>
                            {children[expanded].data.map(child_course => {
                              const SubCourseComponent = getCompetenceComponent(child_course.competence_type.competence_type);

                              return (
                                <div
                                  key={child_course.id}
                                  className="my-education__section__role-wrapper__card"
                                >
                                  <div className="grid-x">
                                    <SubCourseComponent
                                      sub
                                      defaultImg={defaultImg}
                                      fetchChildren={id => fetchChildren(id)}
                                      size="big"
                                      children={(Object.keys(children).includes(expanded.toString()) ? children[expanded] : {data: null})}
                                      course={child_course}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                          </motion.div>
                        )
                        : (
                            <motion.div
                            key="cover"
                            css={{overflow: 'hidden'}}
                            animate={{
                              height: 'auto',
                              opacity,
                            }}
                            initial={{height: 0}}
                            exit={{height: 0}}
                            transition={{
                              ease: 'easeOut',
                              duration: 0.3,
                            }}
                          >
                            <div
                              className="my-education__section__role-wrapper__card"
                            >
                              <div className="grid-x">
                                <CourseComponent
                                  sub
                                  defaultImg={defaultImg}
                                  fetchChildren={id => fetchChildren(id)}
                                  size="big"
                                  children={(Object.keys(children).includes(course.competence_id.toString()) ? children[course.competence_id] : {data: null})}
                                  course={course}
                                  loading={currentLoading}
                                />
                              </div>
                            </div>
                          </motion.div>
                        )}
                    </AnimatePresence>
                  );
                })}
              </div>
            )}
          </div>
        </styles.CompetenceWrapper>
      )}
    </styles.Wrapper>
  );
};

export default CompetenceView;
