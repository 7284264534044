import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ContentLoader from 'react-content-loader';
import completed from '../../../../assets/svg/completed.svg';
import {Block, Button, Label, Overlay, useRequiredContext, themes} from '@snapper/core';

import {PersonShape} from '@types/person';
import {
  CircularProgressbar,
  buildStyles,
} from 'react-circular-progressbar';
import Progress from '@components/progress/progress';

import PersonAvatar from '@components/person-avatar/person-avatar';

import {getIsMobile} from '@selectors/global.selectors';
import {Tooltip} from '@snapper/core';
import '@routes/employees/styles/emplyee-card.scss';
import {getMainOrganisations} from '../../../../store/selectors/profile.selectors';

import {
  Wrapper,
  Heading,
  SubHeading,
  LeftPart,
  RightPart,
  PersonInfoWrapper,
  Personalia,
  Text,
  ContentWrapper,
} from './style';
import {ActionButton} from '@components/buttons/actionButton';
import {i18n} from '@src/i18n/index';
import {css, withTheme} from '@emotion/react';
import { CloseButton } from '@components/buttons/closeButton';
import { backendUrl } from '@config';
import { DropdownWithActionButton } from '@components/dropdown-with-action-button/dropdown-with-action-button';

class EmployeeCard extends Component {
  state = {open: false};

  handleAvatarClick = () => {
    const {employee, onAvatarClick} = this.props;

    onAvatarClick(employee);
  };

  handleEditClick = () => {
    const {employee, onEditClick} = this.props;

    onEditClick(employee);
  };

  handleMessageClick = () => this.handleActionClick('sendMessage');

  handleActionClick = (message) => {
    const {onActionClick} = this.props;

    onActionClick(message);
  };

  blockRef = React.createRef()

  render() {
    const {
      onProfileClick,
      employee: {fullname, positions, mobile, phone, email, progress, competencelevel},
      employee,
      theme,
      isSuperUser,
      isExpanded,
      mainOrganisation,
      page,
      taskList: TaskList,
      onEditEmploymentClick,
      listView,
      wrapperStyle,
      onCloseButtonClick,
      isFetching,
    } = this.props;

    const {isMobile} = this.props;
    const {open} = this.state;

    const cardProps
      = !isExpanded && {
        role: 'button',
        onClick: this.handleAvatarClick,
        onKeyPress: this.handleAvatarClick,
        tabIndex: 0,
      }
      || {};

    const position = mainOrganisation && positions?.find(p => p.organisation_id === mainOrganisation.id)?.title;

    if (isExpanded && isMobile && false) {
      return (
        <div
          className="employee-card employee-card--mobile"
          {...cardProps}
        >
          <div className="employee-card__top-section">
            <div className="employee-card__avatar-wrapper">
              <PersonAvatar
                person={employee}
                notificationCount={employee.pending_checklists}
                className="employee-card__avatar"
              />
            </div>
            <div className="employee-card__name-wrapper">
              <div className="employee-card__name">{fullname}</div>
              <div className="employee-card__progress-wrapper">

                {progress > 0 && (
                  <Progress
                    progress={progress}
                    fullWidth
                    barOnly
                  />
                )
                  || <>0%</>}
              </div>
            </div>
          </div>
          <div className="employee-card__actions">
            {['message', 'edit'].includes(page) && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={onProfileClick}
              >
                <i className="fas fa-user" />
              </button>
            )}
            {page !== 'message' && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={this.handleMessageClick}
              >
                <i className="fas fa-envelope" />
              </button>
            )}
            {page !== 'edit' && (
              <button
                className="btn btn--icon"
                type="button"
                onClick={this.handleEditClick}
              >
                <i className="fas fa-pencil-alt" />
              </button>
            )}
            {TaskList && (
              <>
                <button
                  className={classNames('btn btn--icon', {'btn--expanded': open})}
                  type="button"
                  onClick={() => this.setState({open: !open})}
                  onKeyPress={() => this.setState({open: !open})}
                  onBlur={() => this.setState({open: false})}
                >
                  <i className="fas fa-ellipsis-h" />
                </button>
                {open && (
                  <div className="btn__dropdown">
                    <TaskList onClick={() => this.setState({open: false})} />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      );
    }

    return (
      <>
        {listView
      && (
        <tr
          className={classNames([
            'employee-card',
            {
              'employee-card--expanded': isExpanded,
              'employee-card--clickable': !isExpanded,
            },
          ])}
          {...cardProps}
        >
          <td className="employee-card__avatar-wrapper">
            <PersonAvatar
              person={employee}
              notificationCount={employee.pending_checklists}
              className={classNames([
                'employee-card__avatar',
                {'employee-card__avatar--centered': isExpanded},
              ])}
            />
          </td>
          <td
            className={classNames([
              'employee-card__name-wrapper',
              {'employee-card__name-wrapper--centered': isExpanded},
            ])}
          >
            <div className="employee-card__name">{fullname}</div>
          </td>
          <td>
            <div
              ref={this.blockRef}
              style={{width: 28, height: '100%'}}
            >
            {progress === 100 && (
              <img
                style={{width: 28}}
                src={completed}
                alt="all-done"
              />
            ) || (
              <div
                style={{
                  width:28,
                  height: 28,
                  backgroundColor: employee.progress === 0 ? '#fafafa' : 'white',
                  padding: 4,
                  zIndex: 200,
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                  fontSize: 9,
                  borderRadius: 24,
                  boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.12)',
                }}
                ref={this.blockRef}
              >
                {progress > 0 &&  (
                  <CircularProgressbar
                    value={progress}
                    strokeWidth={50}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      backgroundColor: 'red',
                      pathColor: theme.chartPrimaryColor,
                      trailColor: progress === 0 ? theme.chartSecondaryColor : '#E0E0E0',
                    })}
                  />
                ) || <>-</>}
              </div>
            )}
              </div>
              <Tooltip
                parentRef={this.blockRef}
                className="tooltip"
                onHover
                distance={8}
                positionRight
                alignCenter
              >
                {progress} %
              </Tooltip>
          </td>
          {!isMobile && (
            <td className="employee-card__position">
              <div>
                {position}
  &nbsp;
              </div>
            </td>
          )}
          {isExpanded && (
            <>
              <div className="employee-card__progress-wrapper">
                <Progress
                  progress={competencelevel}
                  fullWidth
                />
              </div>
              <div className="employee-card__actions">
                <button
                  className="btn btn--icon"
                  type="button"
                  onClick={this.handleMessageClick}
                >
                  <i className="fas fa-envelope" />
                </button>
                <button
                  className="btn btn--icon"
                  type="button"
                  onClick={this.handleEditClick}
                >
                  <i className="fas fa-pencil-alt" />
                </button>
              </div>
            </>
          )}
        </tr>
      )
      ||  employee
        && (
          <Wrapper
            class="employee-top-part"
            style={wrapperStyle}
          >
            {onCloseButtonClick && (
              <CloseButton
                className="close-btn"
                variant="lightGray"
                onClick={onCloseButtonClick}
                iconVariant="regular"
                style={{
                  position: 'absolute',
                  right: '1.75em',
                  top: '1.75em',
                }}
              />
            )}
            <ContentWrapper>
              <LeftPart>
                <PersonInfoWrapper>
                  <PersonAvatar
                    isFetching={isFetching}
                    person={employee}
                    notificationCount={employee.pending_checklists}
                    className={classNames([
                      'employee-card__avatar',
                      'rounded-corners',
                      {'employee-card__avatar--centered': isExpanded || isFetching},
                    ])}
                  />
                  <Personalia className="employee-personalia-wrapper">
                    <Heading className="name">{fullname}</Heading>
                    {position && (
                      <SubHeading className="position">
                        <div>
                          {position}
                        </div>
                      </SubHeading>
                    )}
                    <Text
                      className="email"
                      css={css`word--break: break-all;`}
                    >
                      {email}
                    </Text>
                    <Text className="tel">{mobile || phone}</Text>
                  </Personalia>
                </PersonInfoWrapper>

                {page !== 'edit'
                && (
                  <ActionButton
                    variant="transparent"
                    className="send-msg-button"
                    onClick={this.handleMessageClick}
                    withRightChrevron={{noSeparatorLine: true}}
                    css={{
                      marginLeft: 0,
                      marginBottom: 0,
                    }}
                    placeholderMode={isFetching}
                  >
                    {i18n('person.send-message-to-x', {functionArgs: {x: fullname}})}
                  </ActionButton>
                )}

              </LeftPart>
              {page !== 'edit' && (

              <RightPart>
                <span>
                  {i18n('person.change-information')}
                </span>
                <DropdownWithActionButton
                  showSelectedAsTitle={false}
                  variant="profile"

                  actionButton={{
                    text: i18n('employees.actions'),
                    souldOpenDropdown: true,
                  }}
                  onChange={val => {
                    if (val === 'personalia'){
                      this.handleEditClick();
                    }else {
                      this.handleActionClick(val);
                    }
                  }}
                  items={[
                    {label: i18n('person.change-personalia'), value: 'personalia'},
                    {label: i18n('person.change-employment'), value: 'employment'},
                    ...(isSuperUser ? [{label: i18n('person.overtake-bart'), value: 'bart'}] : []),
                  ]}
                />
              </RightPart>
              )}
            </ContentWrapper>
          </Wrapper>
        )}

        <div className="employee" />
      </>
    );
  }
}

EmployeeCard.propTypes = {
  employee: PersonShape().isRequired,
  onProfileClick: PropTypes.func,
  onAvatarClick: PropTypes.func,
  onEditClick: PropTypes.func,
  onMessageClick: PropTypes.func,
  isMobile: PropTypes.bool.isRequired,
  isExpanded: PropTypes.bool,
  taskList: PropTypes.node,
  page: PropTypes.string,
};

EmployeeCard.defaultProps = {
  isExpanded: false,
  onProfileClick: () => {},
  onAvatarClick: () => {},
  onEditClick: () => {},
  onMessageClick: () => {},
  taskList: null,
  page: '',
};

const mapStateToProps = state => ({
  isMobile: getIsMobile(state),
  mainOrganisation: getMainOrganisations(state),
});

export default withTheme(connect(mapStateToProps)(EmployeeCard));

export const EmployeeCardLoadingNew = ({page}) =>  (
  <EmployeeCard
    isFetching
    employee={{}}
    page={page}
  />
);

export const EmployeeCardLoading = ({isExpanded, isCentered, isMobile}) =>
  isExpanded
    ?     isMobile && (
      <div className="employee-card employee-card__loader--mobile">
        <div className="employee-card__top-section">
          <ContentLoader
            title={i18n('globals.loading-page', {ellipsis: true})}
            height={52}
            width={409}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <circle
              cx="26"
              cy="26"
              r="25"
            />
            <rect
              x="70"
              y="10"
              rx="4"
              ry="4"
              width="120"
              height="20"
            />
            <rect
              x="10"
              y="85"
              rx="3"
              ry="3"
              width="70"
              height="5"
            />
          </ContentLoader>
        </div>
        <div className="employee-card__actions">
          <ContentLoader
            title={i18n('globals.loading-page', {ellipsis: true})}
            height={40}
            width={150}
            speed={2}
            primaryColor="#f3f3f3"
            secondaryColor="#ecebeb"
          >
            <circle
              cx="30"
              cy="20"
              r="20"
            />
            <circle
              cx="80"
              cy="20"
              r="20"
            />
            <circle
              cx="130"
              cy="20"
              r="20"
            />
          </ContentLoader>
        </div>
      </div>
    ) || (
      <div
        className={classNames([
          'employee-card__loader--expanded',
          {'employee-card__loader--centered': isCentered},
        ])}
      >
        <ContentLoader
          title={i18n('globals.loading-page', {ellipsis: true})}
          height={122}
          width={90}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect
            x="0"
            y="70"
            rx="4"
            ry="4"
            width="90"
            height="5"
          />
          <rect
            x="10"
            y="85"
            rx="3"
            ry="3"
            width="70"
            height="5"
          />
          <circle
            cx="45"
            cy="30"
            r="23"
          />
        </ContentLoader>
      </div>
    )
    : (
      <div
        className={classNames([
          'employee-card__loader',
          {'employee-card__loader--centered': isCentered},
        ])}
      >
        <ContentLoader
          title={i18n('globals.loading-page', {ellipsis: true})}
          height={40}
          width={180}
          speed={2}
          primaryColor="#f3f3f3"
          secondaryColor="#ecebeb"
        >
          <rect
            x="58"
            y="8"
            rx="4"
            ry="4"
            width="117"
            height="6"
          />
          <rect
            x="58"
            y="24"
            rx="3"
            ry="3"
            width="85"
            height="6"
          />
          <circle
            cx="20"
            cy="20"
            r="20"
          />
        </ContentLoader>
      </div>
    );

EmployeeCardLoading.propTypes = {
  isCentered: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isMobile: PropTypes.bool,
};

EmployeeCardLoading.defaultProps = {
  isCentered: false,
  isExpanded: false,
  isMobile: false,
};
