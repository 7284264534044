import PersonCompetenceForm from '@components/person-competence-form/person-competence-form';
import React, {useState} from 'react';
import Modal from '@components/modal/modal';
import {i18n} from '@src/i18n';
import EditCompetence from '../edit-competence/edit-competence';
import {competenceSave} from '@actions/competences.actions';
import {useDispatch} from 'react-redux';
import {coursesStartCourse} from '@actions/courses.actions';
import {css} from '@emotion/react';
import { AnimateSlideUpOnLoadTransparent } from '@components/animate-slide-up-on-load/AnimateSlideupOnLoad';
import CompetenceView from '../competences/competence-view';

const addCompetence = ({}) => {
  const [personalDataLoaded, setPersonalDataLoaded] = useState(false);
  const dispatch = useDispatch();
// http://localhost:4001/grape/persons/get_children_competences_json/27709/59424/6707/0?state=all&limit=100&fields=certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade
  return(
    <>
      <AnimateSlideUpOnLoadTransparent
        css={css`overflow: auto;`}
        show={true}
      >
        <CompetenceView
          orgId={6707}
          personId={27709}
          id={59424}
          competence={{
            'title': "Titlte",
            'short_description': "sjhort"
          }}
        />
      </AnimateSlideUpOnLoadTransparent>
    </>
  );
}

export default addCompetence;
