import React, {Component} from 'react';
import PropTypes from 'prop-types';
import CompetenceCourse from '@components/competences/competence-course';
import CompetenceEcourse from '@components/competences/competence-ecourse';
import CompetenceNano from '@components/competences/competence-nano';
import CompetenceEquivalents from '@components/competences/competence-equals';
import CompetenceChecklist from '@components/competences/competence-checklist';
import CompetenceChecklistItem from '@components/competences/competence-checklist-item';
import CompetenceComplex from '@components/competences/competence-complex';
import CompetenceSignature from '@components/competences/competence-signature';
import CompetenceVerification from '@components/competences/competence-verification';
import CompetenceOther from '@components/competences/competence-other';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronCircleLeft} from '@fortawesome/pro-light-svg-icons';
import axios from 'axios';
import {backendUrl} from '@config';
import {getOrganisationId, getProfile, getProfileId} from '@selectors/profile.selectors';
import {bindActionCreators} from 'redux';
import {fetchCompetencesChildren} from '@actions/profile.actions';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import backIco from '../../../assets/svg/back.btn.svg';

class CompetenceList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: [-1],
      children: {},
    };
  }

  goBack() {
    const {show} = this.state;

    if (show.length === 1) {
      show[0] = -1;
    }else {
      show.splice(-1, 1);
    }
    this.setState({show});
    console.log(show);
  }

  editItem(item) {
    console.log('edit course--');
    console.log(item);
  }

  fetchChildren(id) {
    const {personId, orgId, profile} = this.props;

    if (id !== -1) {
      axios.request({
        method: 'GET',
        url: `${backendUrl}/persons/get_children_competences_json/${personId}/${id}/${orgId}/0`,
        params: {
          state: 'all',
          limit: 100,
          fields:
            'certificate_url,passed,competence_id,competence(files,title,id,short_description,description,person_competence_id,competence_type_id),competence_title,person_competence_id,event_id,date,competence_type,competence_type_id,grade',
        },
        withCredentials: true,
      }).then(request => {
        if (request) {
          const {children} = this.state;

          children[id] = request.data;
          this.setState({children});
        }
      });
    }
  }

  setShowChild(id) {
    const {show} = this.state;

    if (show[0] === -1) {
      show[0] = id;
    }else {
      show.push(id);
    }
    console.log(show);
    this.setState({show});

    this.fetchChildren(id);
  }

  getCompetenceComponent(type) {
    switch (type) {
    case 'digital_signature':
      return CompetenceSignature;
    case 'nano':
      return CompetenceNano;
    case 'ecourse':
      return CompetenceEcourse;
    case 'complex':
      return CompetenceComplex;
    case 'equivalents':
      return CompetenceEquivalents;
    case 'verification':
      return CompetenceVerification;
    case 'checklist':
      return CompetenceChecklist;
    case 'checklist_item':
      return CompetenceChecklistItem;
    default:
      return CompetenceCourse;
    }
  }

  render() {
    const {
      courses,
      root,
      sub,
      personId,
      orgId,
      isSubFetching,
      size,
      canEdit,
      onEdit,
      onToggleCompetence,
    } = this.props;

    const {
      show,
      children,
    } = this.state;

    return (
      <div>
        {root && root !== 'employee-activites' && (
          <div>
            {courses.missing.map(competence => (
              <div key={`competence-list${competence.competence_id}`}>
                {competence.competence_type.competence_type === 'ecourse' && (
                  <CompetenceEcourse
                    roleName={courses.roleId[competence.competence_id]}
                    type="ecourse"
                    showOnly={showOnly}
                    size={size}
                    course={competence}
                  />
                )
                  || competence.competence_type.competence_type === 'course' && (
                    <CompetenceCourse
                      type="course"
                      size={size}
                      showOnly={showOnly}
                      course={competence}
                    />
                  )
                || competence.competence_type.competence_type === 'nano' && (
                  <CompetenceNano
                    type="nano"
                    size={size}
                    course={competence}
                  />
                )
                  || competence.competence_type.competence_type
                    === '"verification"' && (
                      <CompetenceVerification
                        type="verification"
                        size={size}
                        canEdit
                        course={competence}
                      />
                  )
                  || competence.competence_type.competence_type
                    === 'digital_signature' && (
                      <CompetenceSignature
                        type="signature"
                        size={size}
                        course={competence}
                      />
                  )
                  || competence.competence_type.competence_type === 'complex' && (
                    <CompetenceComplex
                      isFetching={isSubFetching}
                      type="complex"
                      size={size}
                      course={competence}
                    />
                  )
                  || competence.competence_type.competence_type
                    === 'equivalents' && (
                      <CompetenceEquivalents
                        isFetching={isSubFetching}
                        roleName={courses.roleId[competence.competence_id]}
                        type="equivalents"
                        size={size}
                        course={competence}
                      />
                  )
                  || competence.competence_type.competence_type
                    === 'checklist' && (
                      <CompetenceChecklist
                        isFetching={isSubFetching}
                        type="checklist"
                        size={size}
                        course={competence}
                      />
                  )
                  || competence.competence_type.competence_type
                    === 'checklist_item' && (
                      <CompetenceChecklistItem
                        isFetching={isSubFetching}
                        type="checklist-item"
                        size={size}
                        course={competence}
                        onClick={() => onToggleCompetence()}
                      />
                  )}
              </div>
            ))}
          </div>
        ) || (
          <div>
            {courses && courses.map(course => {
              const CourseComponent = this.getCompetenceComponent(course.competence_type
                .competence_type);

              return (
                <>
                  {show[0] !== -1 && (
                    <>
                      {show[show.length - 1] === course.id && (
                        <div
                          className="children-wrapper"
                          key={`competence-list-sub-${course.competence_id}`}
                        >
                          <div
                            className="back"
                            onClick={() => this.goBack()}
                          >
                            <img
                              src={backIco}
                              alt="go-back"
                            />
                          </div>

                          <div className="content">
                            <div className="small-header">
                              {i18nCourseGetCompetenceType(course)}
                            </div>
                            <div>{course.competence_title}</div>
                          </div>
                          {Object.keys(children).includes(course.id.toString()) && (
                            <>
                              {children[course.id].map(course => {
                                const CourseComponent = this.getCompetenceComponent(course.competence_type
                                  .competence_type);

                                return (
                                  <div key={`competence-list-sub-${course.competence_id}`}>
                                    <CourseComponent
                                      root={root}
                                      sub={sub}
                                      orgId={orgId}
                                      personId={personId}
                                      canEdit={canEdit}
                                      onEdit={onEdit}
                                      type={course.competence_type.competence_type}
                                      isFetching={isSubFetching}
                                      children={children}
                                      showChildren={id => this.setShowChild(id)}
                                      course={course}
                                      size={size}
                                      isPassed={course.passed === 100}
                                      noExpandChildren
                                    />
                                  </div>
                                );
                              })}
                            </>
                          ) || (
                            <>
                              {i18n('globals.loading')}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  ) || (
                    <div key={`competence-list-sub-${course.competence_id}`}>
                      <CourseComponent
                        root={root}
                        orgId={orgId}
                        sub={sub}
                        canEdit={canEdit}
                        onEdit={onEdit}
                        personId={personId}
                        type={course.competence_type.competence_type}
                        isFetching={isSubFetching}
                        fetchChildren={() => this.fetchChildren(id)}
                        showChildren={id => this.setShowChild(id)}
                        course={course}
                        size={size}
                        isPassed={course.passed === 100}
                      />
                    </div>
                  )}
                </>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

CompetenceList.defaultProps = {
  size: 'compact',
  onToggleCompetence: () => {},
};

CompetenceList.propTypes = {
  size: PropTypes.string,
  courses: PropTypes.shape({}).isRequired,
  root: PropTypes.bool.isRequired,
  onToggleCompetence: PropTypes.func,
  isSubFetching: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  personId: getProfileId(state),
  orgId: getOrganisationId(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {fetchCompetencesChildren},
    dispatch,
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetenceList));


