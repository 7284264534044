import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators, compose} from 'redux';
import {connect} from 'react-redux';
import {withRouter, matchPath} from 'react-router';
import {Route, Redirect, Switch} from 'react-router-dom';
import withBreadcrumbs from 'react-router-breadcrumbs-hoc';
import {getRouteWithParams} from '@utils/routes.utils';
import {employees as routes} from '@routes/routes.manifest';
import * as T from '../../../store/types/load.types';
import classNames from 'classnames';
import {
  getEmployees,
  getIsFetchingEmployees,
  getSearchPhraze,
  getSelectedPerson,
  getEmployeesStatistics,
  getReport,
  getEmployeesActivities,
  getEmployeesExpiredCompetences,
  getSelectedPersonReport,
  getEmployeesChecklists,
  getSelectedOrganisation,
  getIsFetchingFunctions,
  getEvents,
  getStatisticsKindsForView,
} from '@selectors/employees.selectors';
import {
  getProfile,
  getOrganisationId,
  getIsSuperuser,
  getIsDriftsjef,
} from '@selectors/profile.selectors';
import {getIsMobile} from '@selectors/global.selectors';
import {PersonShape} from '@types/person';
import {RoleShape, OrganisationShape} from '@types/role';
import {routerEmployeesMainViewDidMount} from '@actions/router.actions';
import {
  employeesEditSelectedPerson,
  employeesUpdateSelectedPersonRoles,
  employeesUpdateSelectedPersonEmployment,
  employeesFetchSelectedPerson,
  employeesFetchSelectedPersonReport,
  employeesFetchFunctions,
  employeesEditViewInit,
  employeesReportViewInit,
  campExtraViewInit,
  employeesAddViewInit,
  employeesResetPassword,
  employeesEditPassword,
  employeesSaveVerification,
  employeesSaveRequirements,
  employeesSaveRole,
} from '@actions/employees.actions';
import {messagesSendMessage} from '@actions/messages.actions';
import {fetchRoles} from '@actions/roles.actions';
import '@routes/employees/styles/employees-container.scss';
import '@routes/employees/styles/employees-link-card.scss';
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs';
import EmployeesReport from '@routes/employees/components/employees-report/employees-report';
import EmployeeMessage from '@routes/employees/components/employee-message';
import EmployeeCard, {EmployeeCardLoading} from '@routes/employees/components/employee-card/employee-card';
import {fetchedDataWrapperShape} from '@types/fetched-data';
import EmployeesListContainer from '@routes/employees/containers/employees-list-container';
import {i18n} from '@src/i18n/index';
import OrgansationHeader from '@routes/employees/components/organsation-header';

const breadcrumbsRoutes = [
  {
    breadcrumb: 'Dashboard',
    path: routes.dashboard,
  },
  {
    breadcrumb: 'Mine ansatte',
    path: routes.employees,
  },
  {
    breadcrumb: 'Legg til ansatt',
    path: routes.addEmployee,
  },
  {
    breadcrumb: 'Ansatt',
    path: routes.employeePreview,
  },
  {
    breadcrumb: 'Rediger',
    path: routes.employeeEdit,
  },
  {
    breadcrumb: 'Melding',
    path: routes.employeeMessage,
  },
  {
    breadcrumb: 'Rapport',
    path: routes.reportPreview,
  },
  {
    breadcrumb: 'Camp Extra',
    path: routes.campExtra,
  },
];

class EmployeesContainer extends Component {
  employeePreview = React.createRef();
  employeeEdit = React.createRef();

  state = {
    isMobileChecklistOpen: false,
    isMyAccessOpen: false,
    isMobilePersonnelOpen: false,
    isEmploymentModalOpen: false,
    isSendMessageModalOpen: false,
    activeTab: 'overview',
  };

  componentDidMount() {
    const {routerEmployeesMainViewDidMount, match} = this.props;

    routerEmployeesMainViewDidMount({orgId: match.params.orgId});
  }

  componentDidUpdate(prevProps, prevState) {
    const {routerEmployeesMainViewDidMount, match} = this.props;
    const {match: prevMatch} = prevProps;

    if (prevMatch && match.params?.orgId !== prevMatch.params?.orgId) {
      localStorage.setItem('orgId', match.params.orgId);
      routerEmployeesMainViewDidMount({orgId: match.params.orgId});
    }
  }

  toggleMobileChecklist = () => {
    this.setState(state => ({isMobileChecklistOpen: !state.isMobileChecklistOpen}));
  };

  toggleMyAccessOpen = () => {
    this.setState(state => ({isMyAccessOpen: !state.isMyAccessOpen}));
  };

  toggleMobilePersonnel = () => {
    this.setState(state => ({isMobilePersonnelOpen: !state.isMobilePersonnelOpen}));
  };

  handleSendMessage = message => {
    const {messagesSendMessage} = this.props;

    messagesSendMessage({
      ...message,
      callback: this.redirectToPreviewEmployee,
    });
  };

  handleCancelMessage = () => {
    this.setState({isSendMessageModalOpen: false});
  };

  updateOrg = org => {
    const {history: {push}} = this.props;

    push(getRouteWithParams(routes.employees, {orgId: org}));
  };

  changeOrg = org => {
    localStorage.setItem('orgId', org);
    window.location.href = `/dashboard/${org}`;
  };

  redirectToEmployees = () => {
    const {
      history: {push},
      orgId,
    } = this.props;

    push(getRouteWithParams(routes.employees, {orgId}));
  };

  redirectToPreviewEmployee = ({employee}) => {
    const {history: {push}, orgId} = this.props;
    const {user_name} = employee;

    push(getRouteWithParams(routes.employeePreview, {
      orgId,
      userName: user_name,
    }));

    this.forceUpdate();
  };

  handleEditEmployeeClick = employee => {
    const {history: {push}, orgId} = this.props;
    const {user_name: userName} = employee;

    const urlWithParams = getRouteWithParams(routes.employeeEdit, {
      orgId,
      userName,
    });

    push(urlWithParams);
  };

  handleMessageEmployeeClick = employee => {
    this.setState({isSendMessageModalOpen: true});
  };

  handleEditEmploymentClick = () => {
    this.setState({isEmploymentModalOpen: true});
  }

  handleEmploymentFormSubmit = ({data}) => {
    const {
      employeesUpdateSelectedPersonEmployment,
      selectedPerson,
    } = this.props;

    employeesUpdateSelectedPersonEmployment({
      personId: selectedPerson.data.id,
      data,
      callback: ({quitted}) => quitted && this.redirectToEmployees(),
    });
  };

  handleEmploymentModalClose = () => {
    this.setState({isEmploymentModalOpen: false});
  }

  handleEditEmployeeFormSubmit = employee => {
    const {employeesEditSelectedPerson} = this.props;

    employeesEditSelectedPerson({
      person: employee,
      callback: this.redirectToPreviewEmployee,
    });
  };

  handleEmployeeRolesFormSubmit = ({roles}) => {
    const {employeesUpdateSelectedPersonRoles} = this.props;

    employeesUpdateSelectedPersonRoles({roles});
  };

  handleEmployeeReportViewInit = userName => {
    const {employeesFetchSelectedPersonReport} = this.props;

    employeesFetchSelectedPersonReport({userName});
  };

  handleEmployeePreviewInit = userName => {
    const {employeesFetchSelectedPerson} = this.props;

    employeesFetchSelectedPerson({userName});
  };

  handleEmployeeMessageInit = userName => {
    const {employeesFetchSelectedPerson, selectedPerson} = this.props;

    if (!selectedPerson.data) {
      employeesFetchSelectedPerson({userName});
    }
  };

  handleEmployeesReportInit = ({orgId, reportId}) => {
    const {employeesReportViewInit} = this.props;

    employeesReportViewInit({
      orgId,
      reportId,
    });
  };

  handleCampExtraInit = () => {
    const {campExtraViewInit} = this.props;

    campExtraViewInit();
  };

  handleEmployeeEditViewInit = userName => {
    const {employeesEditViewInit} = this.props;

    employeesEditViewInit({userName});
  };

  handleAddEmployeeViewInit = userName => {
    const {employeesAddViewInit, employeesFetchFunctions} = this.props;

    employeesAddViewInit({userName});
    employeesFetchFunctions();
  };

  handleResetPassword = identifier => {
    const {employeesResetPassword} = this.props;

    employeesResetPassword({identifier});
  };

  handleEditPassword = data => {
    const {employeesEditPassword} = this.props;

    employeesEditPassword({
      data,
      callback: this.redirectToPreviewEmployee,
    });
  };

  handleOpenCompetenceOverviewClick = ({userName}) => {
    const {history: {push}} = this.props;

    push(getRouteWithParams(routes.employeeReport, {userName}));
  }

  handleGotoEmployeesList = () => {
    const {history, orgId} = this.props;
    const {push} = history;

    push(getRouteWithParams(routes.employees, {orgId}));
  }

  render() {
    const {
      isMobile,
      location,
      report,
      history,
      mainOrganisation,
      selectedPerson,
      reportCards,
    } = this.props;


    const breadPath = [{
      breadcrumb:'Dashboard',
      match: {url: '/dashboard/'},
    }];

    if (mainOrganisation.status === T.LoadStatuses.LOADED && mainOrganisation.data.parent) {
      breadPath.push({
        breadcrumb:mainOrganisation.data.parent.title,
        match: {url: '/dashboard/' + mainOrganisation.data.parent.organisation_id},
      }, {
        breadcrumb:mainOrganisation.data.title,
        match: {url: '/dashboard/' + mainOrganisation.data.organisation_id},
      });
    }

    return (
      <div
        className={classNames('employees-page', {
          'report-page': matchPath(location.pathname, {
            path: routes.employeeReport,
            exact: false,
          }),
        })}
      >
        <Switch>
          <Route
            path={[
              routes.employees,
              routes.employeeEdit,
              routes.employeePreview,
              routes.employeeReport,
              routes.employeeFiles,
              routes.employeeCV,
            ]}
            exact
            render={() =>
              <EmployeesListContainer />}
          />
          <Route
            path={routes.reportPreview}
            exact
            render={({match: {params: {orgId, reportId}}}) => {
              const breadPath = [{
                breadcrumb: i18n('menu.employees'),
                match: {url: getRouteWithParams(routes.employees, {orgId})},
              }];

              if (mainOrganisation.status === T.LoadStatuses.LOADED && mainOrganisation.data.parent) {
                if(mainOrganisation.data.parent.manager === true) {
                  breadPath.push({
                    breadcrumb:mainOrganisation.data.parent.title,
                    match: {url: '/employees/' + mainOrganisation.data.parent.organisation_id},
                  });
                }

                breadPath.push({
                  breadcrumb:mainOrganisation.data.title,
                  match: {url: '/employees/' + mainOrganisation.data.organisation_id},
                });
              }

              return (
                <>
                  <div className="employee-list-header">
                    <div className="wrapper">
                      <Breadcrumbs
                        breadcrumbs={breadPath}
                        disableMyPage
                      />

                      <OrgansationHeader
                        mainOrganisation={mainOrganisation}
                        updateActiveOrg={this.updateOrg}
                      />
                    </div>
                  </div>
                  <div className="employees-page__section">
                    <EmployeesReport
                      reportId={reportId}
                      history={history}
                      report={report}
                      reportCards={reportCards}
                      orgId={orgId}
                      onInit={id => this.handleEmployeesReportInit({
                        orgId,
                        reportId: id,
                      })}
                    />
                  </div>
                </>
              );
            }}
          />
          <Route
            path={routes.employeeMessage}
            exact
            render={({match: {params: {userName}}}) => (
              <div
                className="employees-page__section employees-page__section--columns employees-page__section--message"
              >
                {selectedPerson.data && (
                  <EmployeeCard
                    wrapperStyle={{
                      paddingLeft:'1.5em',
                      paddingRight:'1.5em',
                      borderRadius:'4px',
                    }}

                    isExpanded
                    listView={false}
                    page="message"
                    onProfileClick={this.handleCancelMessage}
                    employee={selectedPerson.data}
                    onEditClick={this.handleEditEmployeeClick}
                    onMessageClick={this.handleMessageEmployeeClick}
                  />
                )
                  || selectedPerson.isFetching
                    && (
                      <EmployeeCardLoading
                        isMobile={isMobile}
                        isExpanded
                      />
                    )}
                <EmployeeMessage
                  username={userName}
                  onSendMessage={this.handleSendMessage}
                  onInit={() => this.handleEmployeeMessageInit(userName)}
                  onCancel={this.handleCancelMessage}
                />
              </div>
            )}
          />
          <Redirect to={routes.dashboard} />
        </Switch>
      </div>
    );
  }
}

EmployeesContainer.propTypes = {
  history: PropTypes.shape({push: PropTypes.func.isRequired}).isRequired,
  selectedPerson: PropTypes.shape({
    userName: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    data: PersonShape(),
    error: PropTypes.shape({}),
  }).isRequired,
  roles: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.arrayOf(RoleShape()),
    error: PropTypes.shape({}),
  }).isRequired,
  report: PropTypes.shape({
    isFetching: PropTypes.bool.isRequired,
    data: PropTypes.object,
    error: PropTypes.shape({}),
  }).isRequired,
  mainOrganisation: PropTypes.shape({
    organisation_id: PropTypes.number.isRequired,
    extern_organisation_id: PropTypes.number,
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
  }),
  events: PropTypes.arrayOf(PropTypes.shape({})),
  employeeReport: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  person: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  checklists: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isFetchingFunctions: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  statistics: fetchedDataWrapperShape(PropTypes.shape({})).isRequired,
  activities: fetchedDataWrapperShape(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  location: PropTypes.shape({pathname: PropTypes.string.isRequired}).isRequired,
  expiring: fetchedDataWrapperShape(PropTypes.arrayOf(PropTypes.shape({})))
    .isRequired,
  breadcrumbs: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  routerEmployeesMainViewDidMount: PropTypes.func.isRequired,
  employeesEditSelectedPerson: PropTypes.func.isRequired,
  employeesUpdateSelectedPersonRoles: PropTypes.func.isRequired,
  employeesUpdateSelectedPersonEmployment: PropTypes.func.isRequired,
  employeesFetchFunctions: PropTypes.func.isRequired,
  employeesFetchSelectedPerson: PropTypes.func.isRequired,
  employeesFetchSelectedPersonReport: PropTypes.func.isRequired,
  employeesEditViewInit: PropTypes.func.isRequired,
  employeesAddViewInit: PropTypes.func.isRequired,
  employeesReportViewInit: PropTypes.func.isRequired,
  employeesResetPassword: PropTypes.func.isRequired,
  employeesEditPassword: PropTypes.func.isRequired,
  campExtraViewInit: PropTypes.func.isRequired,
  messagesSendMessage: PropTypes.func.isRequired,
  employeesSaveVerification: PropTypes.func.isRequired,
  employeesSaveRequirements: PropTypes.func.isRequired,
  employeesSaveRole: PropTypes.func.isRequired,
  orgId: PropTypes.number.isRequired,
  isSuperuser: PropTypes.bool.isRequired,
  isDriftsjef: PropTypes.bool.isRequired,
};

EmployeesContainer.defaultProps = {
  mainOrganisation: null,
  events: null,
};

const mapStateToProps = state => ({
  employees: getEmployees(state),
  person: getProfile(state),
  selectedPerson: getSelectedPerson(state),
  isFetchingEmployees: getIsFetchingEmployees(state),
  isMobile: getIsMobile(state),
  searchPhraze: getSearchPhraze(state),
  events: getEvents(state),
  employeeReport: getSelectedPersonReport(state),
  isFetchingFunctions: getIsFetchingFunctions(state),
  report: getReport(state),
  mainOrganisation: getSelectedOrganisation(state),
  statistics: getEmployeesStatistics(state),
  checklists: getEmployeesChecklists(state),
  activities: getEmployeesActivities(state),
  expiring: getEmployeesExpiredCompetences(state),
  orgId: getOrganisationId(state),
  isSuperuser: getIsSuperuser(state),
  isDriftsjef: getIsDriftsjef(state),
  reportCards: getStatisticsKindsForView(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      routerEmployeesMainViewDidMount,
      employeesEditSelectedPerson,
      employeesUpdateSelectedPersonRoles,
      employeesUpdateSelectedPersonEmployment,
      employeesFetchSelectedPerson,
      employeesFetchSelectedPersonReport,
      employeesFetchFunctions,
      employeesEditViewInit,
      employeesReportViewInit,
      employeesResetPassword,
      employeesEditPassword,
      campExtraViewInit,
      employeesAddViewInit,
      messagesSendMessage,
      fetchRoles,
      employeesSaveVerification,
      employeesSaveRequirements,
      employeesSaveRole,
    },
    dispatch,
  );

export default compose(
  withRouter,
  withBreadcrumbs(breadcrumbsRoutes, {disableDefaults: true}),
  connect(mapStateToProps, mapDispatchToProps),
)(EmployeesContainer);

