import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import '@routes/employees/styles/events-list.scss';

import _capitalize from 'lodash/capitalize';

import moment from 'moment';
import {i18n} from '@src/i18n';

class ExpiringList extends Component {
  render() {
    const {expiring, isPerson, orgId} = this.props;

    if (!expiring) {
      return null;
    }

    if (isPerson) {
      return (
        <div className="events-list">
          {expiring.map(item => {
            const status = moment(item.valid_until).isBefore(new Date()) ? 'past' : 'not-past';

            return (
              <div className={`events-list__item expire ${status === 'past' ? 'expired' : 'expire-soon'}`}>
                <div className="events-list__date">
                  <div className="events-list__date__day">
                    {moment(item.valid_until).format('DD')}
                  </div>
                  <div className="events-list__date__month">
                    {moment(item.valid_until).format('MMM')}
                  </div>
                </div>
                <div className="events-list__card">
                  <div className="events-list__card__contents">
                    <div className="events-list__text_2_title">
                      <Link
                        to={`/course-catalog/${item.competence_id}`}
                        className="activity-list__activity-description title"
                      >
                        {`${item.competence_title}`}
                      </Link>
                    </div>
                    {status === 'past' && (
                      <div className="events-list__text__description">
                        {i18n('competence.expired', {lowerCase: true})}
                        &nbsp;
                        {moment(item.valid_until).format('DD. MMM YYYY')}
                      </div>
                    ) || (
                      <div className="events-list__text__description">
                        {i18n('competence.expires', {lowerCase: true})}
                        &nbsp;
                        {moment(item.valid_until).format('DD. MMM YYYY')}
                      </div>
                    )}
                  </div>
                  <div><em>{i18n(`globals.course-types.${item.competence_type.competence_type}`)}</em></div>

                </div>
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className="events-list">
        {expiring.map(item => {
          let status;

          if(isPerson) {
            status = moment(item.valid_until).isBefore(new Date()) ? 'past' : 'not-past';
          } else{
            status = moment(item.valid_until).isBefore(new Date()) ? 'past' : 'not-past';
          }

          return (
            <div className={`events-list__item expire ${status === 'past' ? 'expired' : 'expire-soon'}`}>
              <div className="events-list__date">
                <div className="events-list__date__day">
                  {moment(item.valid_until).format('DD')}
                </div>
                <div className="events-list__date__month">
                  {moment(item.valid_until).format('MMM')}
                </div>
              </div>

              <div className="events-list__card">
                <div className="events-list__card__contents">

                  <div className="events-list__text_2_title">
                    {!!item.person
                      && (
                        <Link
                          to={`/employees/${orgId}/user/${item.person.user_name}`}
                          className="activity-list__activity-description"
                        >
                          {item.person.fullname}
                          <br />
                        </Link>
                      )}
                    <Link
                      to={`/course-catalog/${item.competence_id}`}
                      className="activity-list__activity-description title"
                    >
                      {`${_capitalize(item.competence_title)}`}
                    </Link>
                    {/* DEBUG!*/}
                    {status === 'past' && (
                      <div className="events-list__text__description">
                        {i18n('competence.expired', {lowerCase: true})}
                        &nbsp;
                        {moment(item.valid_until).format('DD. MMM YY')}
                      </div>
                    ) || (
                      <div className="events-list__text__description">
                        {i18n('competence.expires', {lowerCase: true})}
                        {' '}
                        ut&nbsp;
                        {moment(item.valid_until).format('DD. MMM YYYY')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}

ExpiringList.defaultProps = {
  isPerson: false,
  loading: false,
};

ExpiringList.propTypes = {
  expiring: PropTypes.arrayOf(PropTypes.shape({person: PropTypes.shape({}).isRequired})).isRequired,
  isPerson: PropTypes.bool,
  loading: PropTypes.bool,
};


export default ExpiringList;
