import {screenreaderOnlyMinxin} from '@styles/mixins';

import {css} from '@emotion/react';
import React, {useEffect, useRef, useState} from 'react';
import {useOnClickOutside} from '@src/hooks/hooks';

import {Wrapper, DropdownItemsWrapper, ButtonsWrapper, ActionButton, DropdownItem, toggleButton, DropdownItemsLabel, ToggleDropdownButton, ToggleDropdownIcon, CaretIcon} from './dropdown-styles';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {i18n} from '@src/i18n';
import {faCaretDown} from '@fortawesome/pro-solid-svg-icons';
import {relativeTimeRounding} from 'moment';
import {CaretDown} from 'phosphor-react';

/**
 * A list for selecting an option.
 *
 * Items are provided using items-prop, like this:
 * [{label:"First name", value:"firstname"}]
 *
 * @example
 * <Dropdown
 *  items={[{label:"Pizza", value:'pizza}, {label:"Salad", value:"Salad", disabled: true}]}
 *  onChange={(value)=> console.log("value is now " + value)}
 *  toggleButton={{title:'Choose food'}}
 * />
 *
 * The dropdown has two buttons of interest:
 * Action-button:
 *      Large button shown on left. Can have an own action on click,
 *      but can also be set to toggle the dropdown with  actionButton.souldOpenDropdown
 * Toggle-button:
 *     smaller button shwon on right. Can have text or be set to show the selected item
 *     with toggleButton.showSelectedAsTitle. If none, a chevron is shown
 * If both buttons are present, actionButton is shown on the left
 */
export const DropdownWithActionButton = ({
  items,
  multiple = false, // if selection of multiple items are allowed
  onChange,
  defaultSelected,
  sortMode,  // how the items are sorted, eg alpha
  onFirstOpen,  // called the first time the menu opens, useful for eg, fetching data
  isFetching,
  toggleButton,
  actionButton,
  dropdownItemsLabel,
  variant,
  size,
  itemStyle,
  itemsWrapperStyle,
  ...props
}) => {
  const [isOpen, setIsOpen] = useState(null);

  const [selectedValue, setSelectedValue] = useState(defaultSelected);
  const itemsWrapperRef = useRef();
  const toggleButtonRef = useRef();

  useOnClickOutside([itemsWrapperRef, toggleButtonRef], () => setIsOpen(false));

  const handleSelectOption = value => {
    onChange(value);
    setIsOpen(false);
    setSelectedValue(items.find(item => item.value === value).value);
  };

  const focusFirstOption = () => {
    if(itemsWrapperRef.current) {
      const firstFocusable = [...itemsWrapperRef.current.childNodes].find(cn => cn.tabIndex >= 0);

      setTimeout(() => {
        firstFocusable.focus();
      });
    }
  };

  const handleToggleOpen = evt => {
    const evtCausedByKeyboard = evt.detail === 0;

    if(isOpen === null && onFirstOpen) {
      onFirstOpen();
    }
    setIsOpen(old => !old);

    if(evtCausedByKeyboard) {
      focusFirstOption();
    }
  };


  const focusNextOption = () => {
    const focused = document.activeElement;

    if(focused) {
      const next = focused.nextElementSibling;

      if(next && [...next.classList].includes('dropdown-item')) {
        next.focus();
      }
    }
  };

  const focusPrevOption = () => {
    const focused = document.activeElement;

    if(focused) {
      const prev = focused.previousElementSibling;

      if(prev && [...prev.classList].includes('dropdown-item')) {
        prev.focus();
      }
    }
  };

  const  handleKeyUp = evt => {
    if(evt.key === 'Escape') {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);

    return () => document.removeEventListener('keyup', handleKeyUp);
  }, []);

  const itemsForDisplay = sortMode === 'alpha' &&  items && items.sort((a, b) => a.label.localeCompare(b.label)) || items;

  const selectedItem = items.find(item => item.value === selectedValue);

  const labelForToggleButton = toggleButton?.showSelectedAsTitle && selectedItem?.label || toggleButton?.text;

  // todo flytt meny opp så den dekekr knapp
  // tekst skal være blå

  return (
    <Wrapper {...props}>
      <ButtonsWrapper aria-hidden="true">
        {actionButton
            && (
              <ActionButton
                variant={variant}
                onClick={e => {
                  setIsOpen(false);
                  if(actionButton.onClick) {
                    actionButton.onClick();
                  }

                  if(actionButton.souldOpenDropdown) {
                    handleToggleOpen(e);
                  }
                }}
              >
                {actionButton.text}
              </ActionButton>
            )}
        <ToggleDropdownButton
          isNextToActionButton={actionButton}
          ref={toggleButtonRef}
          title={toggleButton?.title}
          aria-label={toggleButton?.title || i18n('globals.open-close-toggle')}
          size={size || 'small'}
          colorScheme={variant === 'dark' || 'light'}
          hasText={labelForToggleButton}
          onClick={handleToggleOpen}
          variant={variant || 'default'}
          css={toggleButton?.style || ''}
          onKeyDown={e => {
            if(e.key === 'ArrowDown') {
              e.preventDefault();
              handleToggleOpen(e);
              focusFirstOption();
            }
          }}
        >
          {labelForToggleButton}

          {toggleButton?.iconVariant === 'triangle' || !toggleButton?.iconVariant
          && (
            <ToggleDropdownIcon
              className="dropdown-icon"
              icon={faCaretDown}
              size={size || 'small'}
              variant={variant || 'default'}
            />
          )
          || <CaretIcon  weight="bold" />}
        </ToggleDropdownButton>
      </ButtonsWrapper>

      <DropdownItemsWrapper
        ref={itemsWrapperRef}
        isOpen={isOpen}
        css={itemsWrapperStyle || ''}
        variant={variant || 'default'}
      >
        {dropdownItemsLabel
           && <DropdownItemsLabel>{dropdownItemsLabel}</DropdownItemsLabel>}

        {isFetching && (
          <DropdownItem>
            {i18n('globals.loading', {ellipsis: true})}
          </DropdownItem>
        )}
        {!isFetching && itemsForDisplay && itemsForDisplay.map((item, idx) => (
          <DropdownItem
            variant={variant || 'default'}
            as={!variant ? 'button' : 'div'}
            className="dropdown-item"
            tabIndex={item.disabled ? '-1' : '0'}
            key={item.value}
            disabled={item.disabled}
            isSelected={selectedValue ? selectedValue === item.value : idx === 0}
            css={itemStyle || ''}
            onClick={() =>
              !item.disabled
                && handleSelectOption(item.value)}
            onKeyUp={e => {
              if(e.key === 'Enter' || e.key === ' ') {
                handleSelectOption(item.value);
              };
            }}

            onKeyDown={e => {
              // prevent site from scrolling
              if(['ArrowDown', 'ArrowUp', ' '].includes(e.key)) {
                e.preventDefault();
              }
              if(e.key === 'ArrowDown') {
                focusNextOption();
              } else if(e.key === 'ArrowUp') {
                focusPrevOption();
              }
            }}
          >
            {item.label}
          </DropdownItem>
        ))}
      </DropdownItemsWrapper>

      {/* Screenreder-only select*/}
      <div css={screenreaderOnlyMinxin}>
        {dropdownItemsLabel
           && <DropdownItemsLabel>{dropdownItemsLabel}</DropdownItemsLabel>}
        {isFetching && i18n('globals.loading', {ellipsis: true})}
        {!isFetching && itemsForDisplay
        && (
          <select
            tabIndex="-1"
            onBlur={evt => handleSelectOption(evt.target.value)}
            {...multiple ? {multiple:'true'} : {}}
          >
            {itemsForDisplay.map(item => (
              <option
                value={item.value}
                disabled={item.disabled}
              >
                {item.label}
              </option>
            ))}
          </select>
        )}
      </div>
    </Wrapper>
  );
};
