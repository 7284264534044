import styled from '@emotion/styled';

export const TreeMap = styled.div`
  margin: 0;
  padding: 0;
  position: relative;
  font-size: 0.9em;
  width: 100%;
`;

export const SearchInputWrapper = styled.div`
  position: relative;
  width: 46%;
  svg {
    position: absolute;
    right: 12px;
    color: #666;
    top: 12px;
  }
`;


export const SelectedCourses = styled.div`
  width: 100%;
  margin-bottom: 10px;

  font-size: 14px;

  .course {
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    .status {
      display: flex;
      align-items: center;
      margin-right: 10px;
      justify-items: center;
    }

    .txt {
      flex: 2;
      padding: 10px;

      :hover {
        background-color: #F4F4F4;
        div {
          display: block;
        }
      }
    }

    .action-btn {
      justify-content: flex-start;
      align-content: flex-end;
      align-items: flex-start;
      align-self: flex-start;
      justify-items: flex-start;
      justify-self: flex-end;
    }
  }
`;

export const Intro = styled.div`
  display: flex;
  width: 100%;
  margin-top: 30px;
  flex-direction: row;
`;

export const Add = styled.div`
  h4 {
    font-size: 1em;
    font-weight: 500;
    margin: 0;
    color: #282828;
  }
  display: flex;
  flex-direction: row;
  width: 100%;
  opacity: ${({disabled}) => disabled ? 0.3 : 1};
  color: #444;
  margin-bottom: 20px;
  cursor: pointer;
  padding: 10px;
  :hover {
    background-color: #F4F4F4;
  }
`;

export const Ico = styled.div`
  display: flex;
  width: 64px;
  height: 64px;
  border-radius: 4px;
  background-color: white;
  border: 1px solid #E8E8E8;
  margin-right: 20px;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  svg {
    font-size: 1.2em;
  }
`;

export const Content = styled.div`
  width: 70%;
  h4 {
    margin-bottom: 6px;
  }
  font-size: 0.9em;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  margin-bottom: 40px;
  h5 {
    font-size: 0.9em;
    font-weight: 500;
    margin: 0;
    margin-bottom: 30px;
    color: #444;
  }
`;


export const SearchItem = styled.input`
  margin-bottom: 10px;
  border-radius: 4px;
  border: 1px solid #E8E8E8;
  width: 100%;
  padding: 10px;
`;

export const Item = styled.div`
  width: 100%;
  margin-bottom: 1px;
  position: relative;
  padding: 4px 10px;
  border-radius: 4px;
  cursor: pointer;
  background-color: ${({active}) => active ? '#DBDBDB' : 'transparent'};
  img {
    position: absolute;
    right: 6px;
    top: 10px;
    display: none;
    &.expanded {
      display: block;
    }
  }
  &:hover{
    background-color: #F4F4F4;
    img {
      display: block;
    }
  }

`;


export const UnCheck = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  float: left;
  div {
    float: left;
    font-size: 0.8em;
    padding-top: 2px;
    padding-right: 10px;
    text-decoration: none;
    display: none;
  }
`;

export const Course = styled.div`
  width: 100%;
  margin-bottom: 6px;
  position: relative;
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 0.8em;
  padding-left: 40px;
  input{
    position: absolute;
    left: 20px;
    margin-top: 2px;
    margin-left: -4px;
  }
  cursor: pointer;
  background-color: ${({active}) => active ? 'blue' : 'transparent'};
  img {
    position: absolute;
    right: 6px;
    top: 10px;

  }
  &:hover{
    background-color: #F4F4F4;
  }

`;
