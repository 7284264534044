import * as styles from '../person-competence-form-styles';

import React from 'react';
import svgLeft from '../../../../assets/svg/arrow-left.svg';
import svgDown from '../../../../assets/svg/arrow-down.svg';
import { i18n } from '@src/i18n';

export const Children = ({
  items,
  expand,
  expanded,
  categories,
  formData,
  add,
  level,
}) => {
  if (Boolean(!items || !expanded)) {
    return <></>;
  }
  const marginLeft = level * 6;

  return (
    <>
      {Boolean(items?.children && items?.children.length !== 0) && (
        <div>
          {items.children.map(child => (
            <>
              {Boolean(child.hasChildren || child.hasCourses > 0) && (
                <>
                  <styles.Item
                    onClick={() => expand(child.id)}
                    style={
                      {
                        marginLeft: `${marginLeft}%`,
                        width: `${100 - marginLeft}%`,
                      }
                    }
                  >
                    {child.title}
                    {Object.keys(formData.expandedItems).includes(child.id.toString())
                    && (
                      <img
                        src={svgDown}
                        alt="expand"
                        className="expanded"

                      />
                    )
                    ||  (
                      <img
                        src={svgLeft}
                        alt="expand"
                      />
                    )}
                  </styles.Item>
                  <Children
                    level={level + 1}
                    expand={expand}
                    add={add}
                    expanded={Object.keys(formData.expandedItems).includes(child.id.toString())}
                    formData={formData}
                    categories={categories}
                    items={
                      Object.keys(categories.children).includes(child.id.toString())
                        ? categories.children[child.id]
                        : null
                    }
                  />
                </>
              )}
            </>
          ))}
        </div>
      )}
      {Boolean(items && items.courses?.length) && (
        <>
          {items.courses.map(course => (
            <styles.Course
              onClick={() => add(course)}
              style={
                {
                  marginLeft: `${marginLeft}%`,
                  width: `${100 - marginLeft}%`,
                }
              }
            >
              <div className="check">
                <input
                  type="checkbox"
                  checked={Object.keys(formData.selectedItems).includes(course.id.toString())}
                />
              </div>
              {course.title} (<em>{i18n(`globals.course-types.${course.type}`)}</em>)
            </styles.Course>
          ))}
        </>
      )}

    </>
  );
};
