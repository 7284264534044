import {EyeSlash, File as FileIcon} from 'phosphor-react';
import {css} from '@emotion/react';
import {i18n} from '@src/i18n';
import React from 'react';
import {Download, Read, Wrapper, File} from '@components/files-list/files-list-styles';

export const FilesList = ({className, files, onClickFile, fileLineStyle, wrapperStyle}) => (
  <Wrapper
    css={wrapperStyle || ''}
    className={className || ''}
  >
    {files.map(({title, url, state}) =>
      title !== 'cover' && (
        <File
          css={fileLineStyle || ''}
          onClick={() => {
            if(onClickFile) {
              onClickFile({
                title,
                url,
                state,
              });
            }
          }}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          read={state === 'read'}
        >
          {state === 'unread'
        && (
          <EyeSlash
            weight="fill"
            color="#0B5CD7"
            size="16"
            css={css`margin-right: 0.8rem;`}
          />
        )}
          {(!state || state === 'default') && (
            <FileIcon
              weight="fill"
              color="#B9B9B9"
              size="16"
              css={css`margin-right: 0.8rem;`}
            />
          )}

          {title}
          {' '}
          {state === 'read' && (
            <Read>
              <span class="separator">-</span>
              {i18n('globals.has-read')}
            </Read>
          )}
          <Download>
            {i18n('globals.download')}
          </Download>
        </File>
      ))}
  </Wrapper>
);
