import React from 'react';
import PropTypes from 'prop-types';

import {PersonShape} from '@types/person';

import '@routes/employees/styles/employees-list.scss';
import * as T from '@types/load.types';

import EmployeeCard, {EmployeeCardLoading} from '@routes/employees/components/employee-card/employee-card';
import {i18n} from '@src/i18n/index';
import {LoadMoreButton} from '@components/load-more-button/load-more-button';
import {css} from '@emotion/react';

const EmployeesList = ({
  employees,
  hasMore,
  isMobile,
  isSuperUser,
  onEmployeeSelect,
  onEmployeeMore,
  isFetching,
}) => (
  <div className="employees-list">
    <table className="employees-list__table">
      <tr>
        <th />
        <th />
        <th className="status">{i18n('person.status')}</th>
        {!isMobile
          && <th>{i18n('person.position')}</th>}
      </tr>
      {employees.map(employee => (
        <EmployeeCard
          absent={employee.is_absent}
          listView
          isFetching
          isMobile={isMobile}
          employee={employee}
          isSuperUser={isSuperUser}
          onAvatarClick={() => onEmployeeSelect(employee)}
        />
      ))}
    </table>
    {hasMore && (
      isFetching !== T.LoadStatuses.LOADED && (
        <div className="info__chip">
          <div className="chip show-more chip--loading">
            <i className="fas fa-spin fa-spinner" />
          </div>
        </div>
      ) || (
        <LoadMoreButton
          onClick={onEmployeeMore}
        >
          {i18n('employees.load-more-employees')}
        </LoadMoreButton>
      )
    )}
  </div>
);

EmployeesList.propTypes = {
  employees: PropTypes.arrayOf(PersonShape()).isRequired,
  onEmployeeSelect: PropTypes.func.isRequired,
  onEmployeeMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
};

export default EmployeesList;

export const EmployeesListLoading = () => (
  <div className="employees-list">
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
    <div className="employees-list__card-wrapper">
      <EmployeeCardLoading />
    </div>
  </div>
);
