import React, {Component} from 'react';
import PropTypes from 'prop-types';

import '../styles/employee-message.scss';
import loadable from '@loadable/component';

const MessageForm = loadable(() => {
  console.log('load-MessageForm');
  return import(/* webpackChunkName: "message.form" */ '@components/message-form/message-form');
});

class EmployeeMessage extends Component {
  constructor(props) {
    super(props);

    this.handleMessageSubmit = this.handleMessageSubmit.bind(this);
  }

  state = {loading: false};

  componentDidMount() {
    const {onInit, username} = this.props;

    onInit(username);
  }

  handleMessageSubmit({title, emailBody}) {
    const {username, onSendMessage} = this.props;

    this.setState({loading: true});

    onSendMessage({
      username,
      title,
      emailBody,
    });
  }

  render() {
    const {loading} = this.state;
    const {onCancel} = this.props;

    if (loading) {
      return (
        <span style={{margin: 'auto'}}>
          <i className="fa fa-spin fa-spinner" />
          {' '}
          Laster...
        </span>
      );
    }

    return (
      <div className="employee-message">
        <div className="employee-message__form-wrapper">
          <MessageForm
            initialValues={{
              title: '',
              emailBody: '',
            }}
            onSubmit={this.handleMessageSubmit}
            onCancel={onCancel}
          />
        </div>
      </div>
    );
  }
}

EmployeeMessage.propTypes = {
  username: PropTypes.string.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  onInit: PropTypes.func,
  onCancel: PropTypes.func,
};

EmployeeMessage.defaultProps = {
  onInit: () => {},
  onCancel: () => {},
};

export default EmployeeMessage;
