import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './competence.scss';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {withRouter} from 'react-router';
import {getProfile} from '@selectors/profile.selectors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {ActionButton} from '@components/buttons/actionButton';

import {fetchCompetencesChildren} from '@actions/profile.actions';
import CompetencesList from '@components/competences/competence-list';
import {faChevronDown, faChevronUp, faChevronRight, faCalendarAlt, faUserCheck, faHourglass, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import completed from '../../../assets/svg/completed.svg';
import {CircularProgressbar, buildStyles} from 'react-circular-progressbar';
import {Pencil} from 'phosphor-react';
import {getIsMobile} from '@selectors/global.selectors';
import {AnimatePresence, motion} from 'framer-motion';
import {FilesList} from '@components/files-list/files-list';
import {css} from '@emotion/react';
import {verifyPassed} from '../../utils/misc.utils';


const createSubtitleContent = content => ({__html: content});

class CompetenceEquivalents extends Component {
  state = {isExpanded: false};

  handleChildrenUpdate = values => {
    const {course, root, showChildren, section} = this.props;
    const {isExpanded} = this.state;

    if (showChildren) {
      showChildren(values);
    }
    this.setState({isExpanded: !isExpanded});
    if (!isExpanded && course.children && course.children.length === 0) {
      const {fetchCompetencesChildren} = this.props;

      fetchCompetencesChildren({
        courseId: values,
        root,
        isOtherCompetences: section === 'other',
      });
    }
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    removeRequirement(id);
  }

  render() {
    const {
      size,
      course,
      sub,
      canEdit,
      onEdit,
      roleName,
      isPassed,
      isFetching,
      fetchChildren,
      defaultImg,
      loading,
      isMobile,
      noExpandChildren,
    } = this.props;
    const {isExpanded} = this.state;

    return (
      <>
        {size === 'compact' && (
          <div className={`competence-card ${size}`}>
            <div
              className="grid-x clickable-card"
              role="button"
              tabIndex={0}
              onKeyPress={() => this.handleChildrenUpdate(course.competence_id)}
              onClick={() => this.handleChildrenUpdate(course.competence_id)}
            >
              {isPassed && (
                <img
                  className="passed-ico"
                  alt="completed"
                  src={completed}
                />
              )}
              <div className="cell auto">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
              </div>

              <div className="cell shrink button">
                {!isExpanded && !sub && <FontAwesomeIcon icon={faChevronDown} /> || (
                  <div className="goto-next">
                    {course.children
                        && course.children.length === 0
                        && isFetching && <span>...</span>
                        || <FontAwesomeIcon icon={faChevronUp} />}
                  </div>
                )}
              </div>
            </div>

            <div className="small-12 cell">
              <AnimatePresence>
                {isExpanded && course.children && course.children.length !== 0 && (
                  <motion.div
                    key="animate-expand"
                    css={{overflow: 'hidden'}}
                    animate={{height: 'auto'}}
                    initial={{height: 0}}
                    exit={{height: 0}}
                    transition={{
                      ease: 'easeOut',
                      duration: 0.3,
                    }}
                    className="sub equals"
                  >
                    <CompetencesList
                      sub={true}
                      courses={course.children}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        )}
        {size === 'employee' && (
          <div className={`competence-card ${size}${isExpanded ? ' expanded' : ''}`}>
            <div
              className="grid-x clickable-card"
              role="button"
              tabIndex={0}
              onKeyPress={() => this.handleChildrenUpdate(course.competence_id)}
              onClick={() => this.handleChildrenUpdate(course.competence_id)}
            >
              <div className="cell auto">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >
                  {canEdit && !isMobile && (
                    <div className="edit-button">
                      <ActionButton
                        variant="defaultTheme"
                        onClick={() => onEdit(course)}
                      >
                        {i18n('globals.edit')}
                      </ActionButton>
                    </div>
                  )}
                  {canEdit && isMobile && (
                    <div className="edit-button mobile">
                      <Pencil
                        color="#0b5dd7"
                        role="button"
                        tabIndex="0"
                        onKeyUp={evt => evt.key === 'Enter' && onEdit(course)}
                        onClick={() => onEdit(course)}
                      />
                    </div>
                  )}
                  <div className="roles-list__competence-title">
                    <div
                      className="roles-list__competence-status"
                    >
                      {verifyPassed(course)
                        && (
                          <img
                            src={completed}
                            alt="all-ok"
                            style={{width: 20}}
                          />
                        )}
                    </div>
                    <div className="info-wrapper">
                      <div className="small-header">
                        {i18nCourseGetCompetenceType(course)}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            &nbsp;&nbsp;
                            {course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon={faUserCheck} />
                            &nbsp;&nbsp;
                            {course.verified.fullname}
                          </div>
                        )}
                        {course.valid_until && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon={faHourglass} />
                            &nbsp;&nbsp;
                            {course.valid_until}
                          </div>
                        )}
                      </div>
                    </div>

                    {course.files && course.files.length > 0 && !isExpanded && (
                      <FilesList
                        className="files-list"
                        files={course.files.map(({title, url}) => ({
                          title,
                          url,
                        }))}
                        wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                      />
                    )}

                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>

              <div className="cell shrink button">
                {!isExpanded && !noExpandChildren && <FontAwesomeIcon icon={(sub ? faChevronRight : faChevronDown)} /> || (
                  course.children
                    && course.children.length === 0
                    && isFetching && <span>...</span>
                    || !noExpandChildren && <FontAwesomeIcon icon={faChevronUp} />
                )}
              </div>
            </div>

            <div className="small-12 cell">
              <AnimatePresence>
                {isExpanded && course.children && course.children.length !== 0 && (
                  <motion.div
                    key="animate-expand"
                    css={{overflow: 'hidden'}}
                    animate={{height: 'auto'}}
                    initial={{height: 0}}
                    exit={{height: 0}}
                    transition={{
                      ease: 'easeOut',
                      duration: 0.3,
                    }}
                    className="sub equals"
                  >
                    <CompetencesList
                      sub={true}
                      onEdit={onEdit}
                      canEdit={canEdit}
                      size={size}
                      courses={course.children}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        )}
        {size === 'big' && (
          isExpanded && (
            <div className="course-sub-header">
              <h2>{course.competence_title}</h2>
              {isFetching
                  && <div>{i18n('globals.loading')}</div>}
              {isExpanded && course.children && course.children.length !== 0 && (
                <div className="grid-x">
                  <div className="sub equals">
                    <CompetencesList
                      sub={true}
                      onEdit={onEdit}
                      canEdit={canEdit}
                      size="big"
                      courses={course.children}
                    />
                  </div>
                </div>
              )}
            </div>
          ) || (
            <>
              <div className={`cell small-12 ${isPassed ? 'passed ' : ''}medium-4 course-image-wrapper`}>
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${course.cover && course.cover.url
                      || defaultImg})`,
                  }}
                />
              </div>
              <div className="content cell small-12 medium-8 course-text-wrapper">
                <div className="__title">
                  <h2>{course.competence_title}</h2>
                  {course.competence_type.competence_type
                    && (
                      <div>
                        {i18nCourseGetCompetenceType(course)}
                      </div>
                    )}
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={createSubtitleContent(course.short_description)}
                  />
                  {course.files && course.files.length > 0 && (
                    <FilesList
                      className="files-list"
                      files={course.files.map(({title, url}) => ({
                        title,
                        url,
                      }))}
                      wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                    />
                  )}

                </div>

                <div className="cell small-12 medium-8 button-wrapper">
                  <ActionButton
                    withRightChrevron
                    variant="primaryTheme"
                    className="action-btn"
                    onClick={!loading ? () => fetchChildren(course.competence_id) : undefined}
                    disabled={loading}
                  >
                    {!loading
                      ? i18n('role.open-competence')
                      : (
                        <FontAwesomeIcon
                          spin
                          icon={faSpinner}
                        />
                      )}
                  </ActionButton>
                </div>
                <div
                  className="button"
                  role="button"
                  tabIndex={0}
                  onKeyPress={() =>
                    this.handleChildrenUpdate(course.competence_id)}
                  onClick={() => this.handleChildrenUpdate(course.competence_id)}
                />
              </div>
              {isPassed && (
                <img
                  className="passed-ico"
                  alt="completed"
                  src={completed}
                />
              ) || (
                <div
                  className="passed-ico"
                  style={{
                    width:28,
                    height: 28,
                    backgroundColor: course.passed === 0 ? '#fafafa' : 'white',
                    padding: 4,
                    justifyContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    fontSize: 9,
                    borderRadius: 24,
                    boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.12)',
                  }}
                >
                  <CircularProgressbar
                    value={course.passed}
                    strokeWidth={50}
                    styles={buildStyles({
                      strokeLinecap: 'butt',
                      backgroundColor: 'red',
                      pathColor: '#C4C8CC',
                      trailColor: course.passed  === 0 ? '#FFD7D7' : '#E0E0E0',
                    })}
                  />
                </div>
              )}
            </>
          )
        )}
      </>
    );
  }
}

CompetenceEquivalents.defaultProps = {
  roleName: '',
  size: 'normal',
  root: 'std',
  roleId: -2,

  removeRequirement: undefined,
};

CompetenceEquivalents.propTypes = {
  course: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  roleName: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  size: PropTypes.string,
  root: PropTypes.string,
  roleId: PropTypes.number,
  removeRequirement: PropTypes.func,
  fetchCompetencesChildren: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {fetchCompetencesChildren},
    dispatch,
  );

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetenceEquivalents));
