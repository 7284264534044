import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './progress.scss';
import {i18n} from '@src/i18n/index';

import * as styles from './progress-styles';

const Progress = ({
  progress,
  tasksDone,
  tasks,
  taskName,
  fullWidth,
  barOnly,
  bigBar,
  className,
  label,
  loading,
  textOnly,
}) => (
  <styles.ProgressBar className={`${className}`}>
    {bigBar && (
      <styles.BigProgress>
        <div className="bar">
          {progress < 40
            && <span>{`${Math.round(progress)}%`}</span>
           || (
             <>
                  
             </>
           )}
          <div
            className="bar-complete"
            style={{width: `${progress}%`}}
          >
            {progress > 40
              && <span>{`${Math.round(progress)}%`}</span>}
          </div>
        </div>
        <styles.Txt>
          <styles.Dot />
          <div>
            {i18n('globals.competencelevel')}
          </div>
        </styles.Txt>
      </styles.BigProgress>
    ) || (
      <>
        {!barOnly
        && (!!tasks
          && (loading && <div className="progress__progress">- av -</div> || (
            <div className="progress__progress">
              {taskName}
              :&nbsp;&nbsp;
              {i18n('competence.x-of-y', {
                functionArgs: {
                  x: tasksDone,
                  y: tasks,
                },
              })}
            </div>
          ))
          || (loading && <div className="progress__progress">-</div>
            || (
              <div className="progress__progress">
                {`${Math.round(progress)}%`}
                {' '}
                {i18n('globals.done', {lowerCase: true})}
              </div>
            )
          ))}
        {label && <div className="progress__label">{label}</div>}
        {!textOnly
        && (
          <div
            className={classNames({
              'progress__bar-overall': true,
              'progress__bar-overall--full-width': fullWidth,
            })}
          >
            <styles.Completed
              style={{width: `${progress}%`}}
            />
          </div>
        )}
      </>
    )}
  </styles.ProgressBar>
);

Progress.propTypes = {
  progress: PropTypes.number,
  tasksDone: PropTypes.number,
  tasks: PropTypes.number,
  taskName: PropTypes.string,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  barOnly: PropTypes.bool,
  label: PropTypes.string,
  loading: PropTypes.bool,
};

Progress.defaultProps = {
  progress: 0,
  tasksDone: null,
  tasks: null,
  taskName: i18n('role.tasks'),
  fullWidth: false,
  className: '',
  barOnly: false,
  label: '',
  loading: false,
};

export default Progress;
