import styled from '@emotion/styled';

export const Completed = styled.div`
  height: 7px;
  border-radius: 5px;
  background-color: ${({theme}) => theme.progress.default.backgroundColor};
`;

export const ProgressBar = styled.div`
  width: 100%;
  color: ${({theme}) => theme.colors.neutral['80']};
  font-weight: 600;
  display: flex;
  flex-direction: column;
  font-size: ${({theme}) => theme.fonts.sm.size};
  line-height: 24px;
  white-space: nowrap;
`;

export const Txt = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 16px;
  color: #0a0a0a;
  font-size: ${({theme}) => theme.fonts.ss.size};
`;

export const Dot = styled.div`
  align-self: center;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  border-radius: 6px;
  padding-top: 6px;
  background-color: ${({theme}) => theme.progress.default.backgroundColor};
`;

export const BigProgress = styled.div`
  font-size: ${({theme}) => theme.fonts.ss.size};
  .bar {
    height: 24px;
    border-radius: 5px;
    margin-right: 12px;
    text-indent: 6px;
    line-height: 24px;
    color: ${({theme}) => theme.colors.neutral['70']};
    vertical-align: middle;
    position: relative;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    border-radius: 8px;
    background-color: #eaeaea;
  }

  .bar-complete {
    position: absolute;
    top: 0;
    color: white;
    height: 100%;
    border-radius: 8px;
    background-color: ${({theme}) => theme.progress.default.backgroundColor};
  }

  .done {

  }
`

