import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {
  coursesSignCourse,
  coursesBeginSignature,
} from '@actions/courses.actions';
import {fetchedDataWrapperShape} from '@types/fetched-data';
import {
  getCurrentSignature,
  getSigningOnCourse,
} from '@selectors/courses.selectors';
import Modal from '@components/modal/modal';
import FormInput from '@components/form-input';
import {Field, reduxForm} from 'redux-form';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import './signature-modal.scss';
import {i18n} from '@src/i18n';
import { faSignature } from '@fortawesome/pro-solid-svg-icons';

const SignForm = ({data}) => {

  const [formData, setFormData] = useState({
    courseId: data.courseId,
    password: '',
  });

  const handleInput = ({target: {id, value}}) => {
    setFormData({
      ...formData,
      [id]: value,
    });
    console.log(formData)
  };

  return (
    <div
      className="signature-modal"
    >
      <FormInput
        className="person-form__input"
        name="password"
        label={i18n('competence.password')}
        input={{}}
        value={formData.password}
        meta={{}}
        type="password"
        id="password"
        onChange={handleInput}
      />
    </div>
  );
};

SignForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  courseId: PropTypes.number.isRequired,
};

const SignatureModal = ({
  course,
  coursesSignCourse,
  coursesBeginSignature,
}) => {
    if (!course) {
      return (<div />);
    }

    const [formData, setFormData] = useState({
      courseId: course.id,
      password: '',
    });

    const handleInput = ({target: {id, value}}) => {
      setFormData({
        ...formData,
        [id]: value,
      });
      console.log(formData)
    };

    return (
      <Modal
      submitButtonText={i18n('competence.sign')}
      onConfirm={() => {
        coursesSignCourse(formData)
      }}
      onCancel={() => {
        coursesBeginSignature(null);
      }}
    >
      <div className="signature-modal">
        <div className="signature-modal__header">
          <FontAwesomeIcon
            icon={faSignature}
          />
          &nbsp;{i18n('competence.sign')}&nbsp;
          {course.title || course.competence_title}
        </div>

        <div
          className="signature-modal"
        >
          <FormInput
            className="person-form__input"
            name="password"
            label={i18n('competence.password')}
            input={{}}
            value={formData.password}
            meta={{}}
            type="password"
            id="password"
            onChange={handleInput}
          />
        </div>
      </div>
    </Modal>
    )
  };

SignatureModal.propTypes = {
  course: PropTypes.shape({}).isRequired,
  signedCourse: fetchedDataWrapperShape(PropTypes.shape({})).isRequired,
  signOnCourseResults: PropTypes.arrayOf(PropTypes.shape({
    correct: PropTypes.arrayOf({
      message: PropTypes.string,
      user: PropTypes.string,
    }),
    errors: PropTypes.arrayOf({
      message: PropTypes.string,
      user: PropTypes.string,
    }),
  })),
  coursesBeginSignature: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  course: getCurrentSignature(state),
  signedCourse: state.courses.courseSignCourse,
  courseSigningOn: getSigningOnCourse(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      coursesSignCourse,
      coursesBeginSignature,
    },
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignatureModal);
