import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';

import './person-competence-form.scss';
import axios from 'axios';
import {backendUrl} from '@config';
import * as styles from './person-competence-form-styles';
import {ActionButton} from '@components/buttons/actionButton';
import {i18n} from '@src/i18n';
import FormInput from '@components/form-input';
import svgLeft from '../../../assets/svg/arrow-left.svg';
import svgDown from '../../../assets/svg/arrow-down.svg';
import { Children } from './components/children';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faBell, faSearch, faTimes } from '@fortawesome/pro-light-svg-icons';
import { useSelector } from 'react-redux';
import { getConfigObject } from '@selectors/config.selectors';
const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const PersonCompetenceForm = ({
  personId,
  onSubmit,
  onCancel,
  orgId,
  onGotData,
}) => {
  const configObject = useSelector(getConfigObject);

  const [formData, setFormData] = useState({
    selectedItems: {},
    expandedItems: {},
    search: {
      searching: false,
      result: [],
      query: '',
      noDocs: false,
    },
    addAs: 'requirement',
  });

  const [step, setStep] = useState(0);
  const [form, editForm] = useState(-1);

  const showItems = configObject.getProperty('employees.person.competences') ||
    {
      required: true,
      recommended: true,
    };

  const [categories, setCategories] = useState({
    isFetching: true,
    data: [],
    children: {},
  });

  const addCompetence = obj => {
    if (Object.keys(obj).includes('url')) {
      const searchId = obj.id.replace('cid_', '');
      if (Object.keys(formData.selectedItems).includes(searchId)) {
        delete formData.selectedItems[searchId];
      }else {
        const constObject = {
          type: obj.course_type,
          title: obj.value,
          id: searchId,
        }
        formData.selectedItems[searchId] = {
          ...constObject,
          valid_until: null,
          joined: new Date(),
          comments: '',
        };
      }
      const newSelected = formData.selectedItems;

      setFormData({
        ...formData,
        selectedItems: newSelected,
      });
    }else {
      if (Object.keys(formData.selectedItems).includes(obj.id.toString())) {
        delete formData.selectedItems[obj.id];
      }else {
        formData.selectedItems[obj.id] = {
          ...obj,
          valid_until: null,
          joined: new Date(),
          comments: '',
        };
      }
      const newSelected = formData.selectedItems;

      setFormData({
        ...formData,
        selectedItems: newSelected,
      });
    }
  };

  useEffect(() => {
    if (formData.search.query.length <= 2) {
      setFormData({
        ...formData,
        search: {
          ...formData.search,
          searching: false,
          result: [],
        }
      });
    }else {
      if (source.current) {
        source.current.cancel('cancelMsg');
      }
      const tokenSource = axios.CancelToken.source();
      source.current = tokenSource;
      axios.request({
        url: `${backendUrl}/courses/search?term=${formData.search.query}&registerform=1&pid=25509&oid=0&json=1`,
        method: 'GET',
        withCredentials: true,
        cancelToken: tokenSource.token,
      }).then(({data}) => {
        console.log(data.length)
        console.log(typeof (data))
        if (data.length === undefined) {
          setFormData({
            ...formData,
            search: {
              ...formData.search,
              result: [],
              noDocs: true,
            }
          });
        }else {
          setFormData({
            ...formData,
            search: {
              ...formData.search,
              result: data,
              noDocs: false,
            }
          });
        }
      })
        .catch(error => {
          console.error(error);
        });
    }
  }, [formData.search.query]);

  useEffect(async () => {
    const fetchData = async () => {
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/persons/personalcompetencerequirements_json/${personId}/${orgId}`,
          params: {'exclude_groups[]': '5,24'},
          withCredentials: true,
        })
        .then(({data}) => {
          const newData = data.map(item => ({
            ...item,
            expanded: false,
            isFetching: false,
            data: [],
          }));

          setCategories({
            ...categories,
            isFetching: false,
            data: newData,
          });

          if (onGotData !== null) {
            onGotData();
          }
        });
    };

    fetchData();
  }, []);

  const handleSearchInput = ({target: {id, value}}) => {
    setFormData({
      ...formData,
      search: {
        ...formData.search,
        searching: true,
        result: [],
        [id]: value,
      }
    });

  };

  const handleCompetenceInput = ({target: {id, value}}) => {
    const key = Object.keys(formData.selectedItems)[form];

    setFormData({
      ...formData,
      selectedItems: {
        ...formData.selectedItems,
        [key]: {
          ...formData.selectedItems[key],
          [id]: value,
        },
      },
    });
  };

  const handleCategoryClick = id => {
    if (Object.keys(formData.expandedItems).includes(id.toString())) {
      delete formData.expandedItems[id];
      setFormData(
        {
          ...formData,
          expandedItems: {
            ...formData.expandedItems,
          }
        }
      );
      return;
    }else {
      setFormData({
        ...formData,
        expandedItems: {
          ...formData.expandedItems,
          [id]: {
            expanded: true,
          },
        },
      });
    }
    const myCategory = Object.keys(categories.children).includes(id.toString())
      ? categories.children[id]
      : {
        children: [],
        expanded: false,
        isFetching: false,
      };

    if (myCategory.children.length !== 0) {
      myCategory.expanded = !myCategory.expanded;
      setCategories(prevState => ({
        ...prevState,
        ...categories,
      }));
    } else {
      myCategory.isFetching = true;
      myCategory.expanded = true;
      myCategory.children = [];
      categories.children[id] = myCategory;
      setCategories(prevState => ({
        ...prevState,
        ...categories,
      }));
      axios
        .request({
          method: 'GET',
          url: `${backendUrl}/persons/configure_part_json/${personId}/${id}/0/1/0/`,
          withCredentials: true,
        })
        .then(({data: {courses: allCourses, groups: allGroups}}) => {
          myCategory.isFetching = false;
          myCategory.children = allGroups.map(item => ({
            ...item,
            checked: false,
            children: {},
          }));
          myCategory.courses = allCourses.map(item => ({
            ...item,
            checked: false,
            children: {},
          }));

          categories.children[id] = myCategory;
          setCategories(prevState => ({
            ...prevState,
            ...categories,
            activeId: id,
          }));
        });
    }
  };

  const moveTo = (next) => {
    if (!showItems[next]) {return;}
    setFormData({
      ...formData,
      addAs: next,
    });
    if (next === 'remove') {
      if (!showItems['requirement']) {return;}
      setStep(2);
    }else {
      setStep(1);
    }

  };

  const handleRolesOnChange = ({values}) => {
    const {change} = this.props;

    change('roles', values);
  };

  return (
    <>
      <ul className="steps">
        <li className={(step >= 0 ? 'active' : 'step')} />
        <li className={(step >= 1 ? 'active' : 'step')} />
      </ul>
      { step === 0 && (
        <styles.Intro>
          <styles.Column>
            <h5>{i18n('person.add-competences.add')}</h5>
            <styles.Add
              disabled={!showItems.competence}
              onClick={() => moveTo('competence')}
            >
              <styles.Ico>
                <FontAwesomeIcon icon={faBell} />
              </styles.Ico>
              <styles.Content>
                <h4>{i18n('person.add-competences.add-ob')}</h4>
                {i18n('person.add-competences.add-ob-txt')}
              </styles.Content>
            </styles.Add>
            <styles.Add
              disabled={!showItems.recommended}
              onClick={() => moveTo('requirement')}
            >
              <styles.Ico>
                <FontAwesomeIcon icon={faBell} />
              </styles.Ico>
              <styles.Content>
                <h4>{i18n('person.add-competences.add-recomended')}</h4>
                {i18n('person.add-competences.add-recomended-txt')}
              </styles.Content>
            </styles.Add>
          </styles.Column>
          <styles.Column>
            <h5>{i18n('person.add-competences.remove')}</h5>
            <styles.Add
              disabled={!showItems.recommended}
              onClick={() => moveTo('remove')}
            >
              <styles.Ico>
                <FontAwesomeIcon icon={faBan} />
              </styles.Ico>
              <styles.Content>
                <h4>{i18n('person.add-competences.remove-recomended')}</h4>
                {i18n('person.add-competences.remove-recomended-txt')}
              </styles.Content>
            </styles.Add>
          </styles.Column>
        </styles.Intro>
      ) || step === 1 && (
        <>
          {!categories.isFetching && (
            <div
              className="person-competence-form"
            >
              <styles.SearchInputWrapper>
              <styles.SearchItem
                value={formData.search.query}
                autoComplete="off"
                id="query"
                name="query"
                placeholder={i18n('person.add-competences.search-for')}
                type="text"
                onChange={handleSearchInput}
              />
                {formData.search.query.length !== 0 && (
                  <div
                    style={{cursor: 'pointer'}}
                    onClick={() => setFormData({
                      ...formData,
                      search: {
                        ...formData.search,
                        query: '',
                        searching: false,
                      }

                    })}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                ) || (
                  <FontAwesomeIcon icon={faSearch} />
                )}
              </styles.SearchInputWrapper>
              <div className="person-competence-form__wrapper">
                <div className="person-competence-form__wrapper__left">
                  {formData.search.searching && (
                    <>
                      {formData.search.result && formData.search.result.length !== 0 && (
                        <>
                          <h3>{i18n('person.add-competences.search-result')}</h3>
                          {formData.search.result.map(course => (
                            <styles.Course
                              onClick={() => addCompetence(course)}
                            >
                              <input
                                type="checkbox"
                                checked={Object.keys(formData.selectedItems).includes(course.id.replace('cid_', ''))}
                              />
                              {course.label && (
                                <div>
                                  ({course.label.split('(')[1]}<br/>
                                </div>
                              )}
                              {course.value} (<em>{i18n(`globals.course-types.${course.course_type}`)}</em>)
                            </styles.Course>
                          ))}
                        </>
                      ) || formData.search.noDocs && (
                        <>
                          <h3>{i18n('person.add-competences.did-not-find')}</h3>
                        </>
                        ) || (
                        <>
                          <h3>{i18n('person.add-competences.searching')}</h3>
                        </>
                      )}
                    </>
                  ) || (
                    <>
                      <h3>{i18n('person.add-competences.select-a-category')}</h3>
                      <styles.TreeMap>
                        {categories.data.map(category => (
                          <>
                            <styles.Item
                              onClick={() => handleCategoryClick(category.id)}
                              active={Object.keys(formData.expandedItems).includes(category.id.toString())}
                            >
                              {category.title}
                              {Object.keys(formData.expandedItems).includes(category.id.toString())
                              && (
                                <img
                                  src={svgDown}
                                  alt="expand"
                                  className="expanded"
                                />
                              )
                              || (
                                <img
                                  src={svgLeft}
                                  alt="expand"
                                />
                              )}
                            </styles.Item>
                            <Children
                              level={1}
                              expanded={Object.keys(formData.expandedItems).includes(category.id.toString())}
                              expand={handleCategoryClick}
                              categories={categories}
                              formData={formData}
                              add={addCompetence}
                              items={
                                Object.keys(categories.children).includes(category.id.toString())
                                  ? categories.children[category.id] : null
                              }
                            />
                          </>
                        ))}
                      </styles.TreeMap>
                    </>
                  )}
                </div>
                <h4>{i18n('person.add-competences.select-competence')}</h4>
                <div className="person-competence-form__wrapper__right">
                  {formData.selectedItems && Object.keys(formData.selectedItems).length !== 0 && (
                    <>
                      <h3>{i18n('person.add-competences.selected-courses')}</h3>
                      {Object.keys(formData.selectedItems).map((key, i) => {
                        const course = formData.selectedItems[key];
                        return (
                          <styles.SelectedCourses>
                            <div className="course"
                              onClick={() => addCompetence(course)}
                            >
                              <div className="txt">
                                <em>{i18n(`globals.course-types.${course.type}`)}</em>
                                {' '}
                                <br />
                                {course.title}
                                <styles.UnCheck>
                                  <div>{i18n('person.add-competences.unselect')}</div>
                                  <input
                                    type="checkbox"
                                    checked={Object.keys(formData.selectedItems).includes(course.id.toString())}
                                  />
                                </styles.UnCheck>
                              </div>
                            </div>
                          </styles.SelectedCourses>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ) || step === 1 && (
        <div
          className="person-competence-form"
        >
          <div>
            <div className="form-header">
              <h2>{i18n('person.add-competences.add-as')}</h2>
              <p>{i18n('person.add-competences.add-as-txt')}</p>
              <input
                checked={formData.addAs === 'requirement'}
                name="addtype"
                id="addtype-req"
                aria-selected={true}
                type="radio"
                onClick={() => setFormData({
                  ...formData,
                  addAs: 'requirement',
                })}
              />

              <label for="addtype-req">
                {i18n('person.add-competences.requirement')}
              </label>

              <input
                checked={formData.addAs !== 'requirement'}
                name="addtype"
                type="radio"
                id="addtype-comp"
                onClick={() => setFormData({
                  ...formData,
                  addAs: 'competence',
                })}
                value={i18n('person.add-competences.competence')}
              />

              <label htmlFor="addtype-comp">
                {i18n('person.add-competences.competence')}
              </label>
            </div>
          </div>

        </div>
      ) || step === 2 && (
        <div
          className="person-competence-form"
        >
          {formData.addAs === 'requirement' && (
            <>
              <div className="form-items">
                <h3>
                  {i18n('person.add-competences.selected-competences')}
                </h3>
              </div>
              {Object.keys(formData.selectedItems).map((key, i) => {
                const course = formData.selectedItems[key];

                return (
                  <div className="selected-courses">
                    <div className="course">
                      <div className="txt">
                        <em>{i18n(`globals.course-types.${course.type}`)}</em>
                        {' '}
                        <br />
                        {course.title}
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          ) || formData.addAs === 'competence' && (
            <>
              <div className="form-items">
                <h2>
                  {i18n('person.add-competences.selected-competences')}
                </h2>
              </div>

              {Object.keys(formData.selectedItems).map((key, i) => {
                const course = formData.selectedItems[key];

                return (
                  <div className="selected-courses">
                    <div className="course">
                      <div className="txt">
                        <em>{i18n(`globals.course-types.${course.type}`)}</em>
                        {' '}
                        <br />
                        {course.title}
                      </div>
                      <div className="action-btn">
                        {form === -1 && (
                          <ActionButton
                            variant="defaultTheme"
                            onClick={() => editForm(i)}
                          >
                            {i18n('globals.edit')}
                          </ActionButton>
                        )}
                      </div>
                    </div>
                    {i === form && (
                      <div className="form">
                        <FormInput
                          className="person-form__input"
                          name="comments"
                          label={i18n('person.add-competences.comments')}
                          input={{}}
                          meta={{}}
                          type="text"
                          value={formData.selectedItems[Object.keys(formData.selectedItems)[form]].comments}
                          id="comments"
                          onChange={handleCompetenceInput}
                        />
                        <div className="two">
                          <FormInput
                            className="person-form__input"
                            name="joined"
                            label={i18n('person.add-competences.completed-date')}
                            input={{}}
                            meta={{}}
                            value={formData.selectedItems[Object.keys(formData.selectedItems)[form]].joined}
                            type="date"
                            id="joined"
                            onChange={handleCompetenceInput}
                          />
                          <FormInput
                            className="person-form__input"
                            name="valid_until"
                            label={i18n('person.add-competences.valid_until')}
                            input={{}}
                            value={formData.selectedItems[Object.keys(formData.selectedItems)[form]].valid_until}
                            meta={{}}
                            type="date"
                            id="valid_until"
                            onChange={handleCompetenceInput}
                          />
                        </div>
                        <div className="row-btn">
                          <ActionButton
                            variant="defaultTheme"
                            onClick={() => editForm(-1)}
                          >
                            {i18n('globals.ok')}
                          </ActionButton>
                        </div>
                      </div>
                    )}
                  </div>
                );
              })}
            </>
          ) || formData.addAs === 'remove' && (
            <div>
              del
            </div>
          )}
        </div>
      )}
      <div className="person-competence-form__submit-button-wrapper">
        {step === 0 && (
          <>
            <ActionButton
              variant="cancel"
              type="button"
              onClick={onCancel}
            >
              {i18n('globals.cancel')}
            </ActionButton>
            <ActionButton
              type="submit"
              variant="primary"
              disabled={Object.keys(formData.selectedItems).length === 0}
              onClick={() => {
                if (Object.keys(formData.selectedItems).length !== 0) {
                  setStep(1);
                }
              }}
            >
              {i18n('globals.next')}
            </ActionButton>
          </>
        ) || step === 1 && (
          <>
            <ActionButton
              variant="cancel"
              type="button"
              onClick={() => setStep(0)}
            >
              {i18n('globals.back')}
            </ActionButton>
            <ActionButton
              type="submit"
              variant="primary"
              onClick={() => setStep(2)}
            >
              {i18n('globals.next')}
            </ActionButton>
          </>
        ) || (
          <ActionButton
            type="submit"
            variant="primary"
            onClick={() => onSubmit({data:formData})}
          >
            {i18n('globals.allok')}
          </ActionButton>
        )}
      </div>
    </>
  );
};


PersonCompetenceForm.propTypes = {
  personId: PropTypes.number.isRequired,
  orgId: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  change: PropTypes.func.isRequired,
  onGotData: PropTypes.func,
  onCancel: PropTypes.func.isRequired,
};

PersonCompetenceForm.defaultProps = {onGotData: null};

export default PersonCompetenceForm;
