import React, { useState } from 'react';
import * as styles from './check-list.styles';
import { i18n } from '@src/i18n';

const CheckListItem = ({
  course,
  onClick,
}) => {
  const [checked, setChecked] = useState(course.passed === 100);
  const shouldClick = () => {
    if (!checked) {
      setChecked(true);
      onClick();
    }
  }
  return (
    <styles.CheckListWrapper>
      {course.checklist.check_raw === 'self' && (
        <>
          <styles.Input
            onClick={shouldClick}
            type="checkbox"
            checked={checked}
          />
          {course.competence_title}
        </>
      ) || course.checklist.check_raw === 'either' && (
        <>
          <styles.Input
            onClick={shouldClick}
            type="checkbox"
            checked={checked}
          />
          {course.competence_title}
        </>
      ) || course.checklist.check_raw === 'both' && (
        <>
          <styles.Input
            onClick={shouldClick}
            type="checkbox"
            checked={checked || course.checklist.is_check_user}
          />
          {course.competence_title}
          <div>

          {course.checklist.is_check_user && (
            <styles.InfoTxt>
              {i18n('competence.you-checked')}: {course.checklist.is_check_user}
            </styles.InfoTxt>
          )}
          {course.checklist.is_check_manager && (
            <styles.InfoTxt>
              {i18n('competence.manager-checked')}: {course.checklist.is_check_user}
            </styles.InfoTxt>
          ) || (
            <styles.InfoTxt>
              {course.checklist.is_check_manager && (
                <>
                  {i18n('competence.manager-checked')}: {course.checklist.is_check_user}
                </>
              ) || (
                <>
                  {i18n('competence.must-manager-sign')}
                </>
              )}

            </styles.InfoTxt>
          )}
          </div>
        </>
      ) || course.checklist.check_raw === 'manager' && (
        <>
          <styles.Input
            onClick={shouldClick}
            type="checkbox"
            disabled
            checked={course.passed === 100}
          />

          {course.competence_title}
          <styles.InfoTxt>
            {i18n('competence.must-manager-sign')}
          </styles.InfoTxt>
        </>
      )}
    </styles.CheckListWrapper>
  );
}

export default CheckListItem;
