import {css} from '@emotion/react';
import {i18n} from '@src/i18n';
import React from 'react';

export const LoadMoreButton = ({children, ...props}) => (
  <button
    className="load-more-btn"
    css={css`
      color: black;
      background: #f5f5f5;
      margin-left: auto;
      margin-right: auto;
      border-radius: 4px;
      max-width: max-content;
      border: none;
      outline: none;
      text-align: center;
      width: 100%;

      margin-top: 2.75em;
      padding: 1em 1.6em;
      width: 100%;
      font-size: 0.95em;
      font-weight: 500;
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
      }
    `}
    {...props}
  >
    {children}
  </button>
);
