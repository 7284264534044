import {bindActionCreators, compose} from 'redux';
import {withRouter} from 'react-router';
import {getPassedCompetencesGroupedByType} from '@selectors/profile.selectors';
import {connect, useDispatch} from 'react-redux';
import {getTree} from '@selectors/employees.selectors';
import {useCallback, useEffect} from 'react';
import {employeesFetchTree} from '@actions/employees.actions';
import {css, useTheme} from '@emotion/react';
import TreeNode from '@components/tree-view/tree-node';
import {LoadingIndicator} from '@snapper/core';
import {TreeViewer, LoadingWrapper} from './styles';

const TreeView = ({
  tree,
  orgId,
  openNode,
  isFetching,
}) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const fetchMore = useCallback(id => {
    dispatch(employeesFetchTree({
      orgId:id,
      fetchMore: true,
    }));
  }, [dispatch]);

  useEffect(() => {
    dispatch(employeesFetchTree({orgId}));
  }, [dispatch, orgId]);

  const lineWidth = '1px';

  return (
    <TreeViewer
      initial={{height: 0}}
      animate={{height: tree.data ? 'auto' : 140}}
      exit={{height: 0}}
      transition={{
        ease: 'easeInOut',
        duration: 0.8,
      }}
    >
      <ul
        css={css`
            flex: 1 0 auto;
            padding-top: 20px;
            padding-bottom: 60px;
            padding-right: 60px;
            padding-inline-start: 0;
            padding-left: 60px;
            margin: 0;
            --line-height: '20px';
            --line-width: ${lineWidth};
            --line-color: '#CCC';
            --line-border-radius: '4px';
            --node-padding: '20px';
            --tree-line-height: var(--line-height, 20px);
            --tree-line-width: var(--line-width, 1px);
            --tree-line-color: var(--line-color, black);
            --tree-line-border-radius: var(--line-border-radius, 5px);
            --tree-node-padding: var(--node-padding, 5px);
          `}
      >
        {tree.data && (
          <TreeNode
            data={tree.data}
            fetchMore={fetchMore}
            openNode={openNode}
          />
        )
          || (
            <LoadingWrapper>
              <LoadingIndicator
                color={theme.chartPrimaryColor}
              />
            </LoadingWrapper>
          )}
      </ul>
    </TreeViewer>
  );
};

const mapStateToProps = state => ({
  tree: getTree(state),
  passedCompetencesGroupedByType: getPassedCompetencesGroupedByType(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {employeesFetchTree},
    dispatch,
  );

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
)(TreeView);
