// group by experience and add an id to each of the experiences
// also add startyear and andyear for education items
import moment from 'moment';

export const prepareCvData = CVData => {
  const dividedByType = {};
  const experiences = CVData.experiences;


  if (experiences) {
    for (const currExperience of experiences) {
      const currKey = currExperience.experience_type;

      if (!dividedByType[currKey]) {
        dividedByType[currKey] = [];
      }

      if(currExperience.experience_type === 'education') {
        currExperience.startyear = moment(currExperience.startdate).year();
        currExperience.endyear = moment(currExperience.enddate).year();
      }

      currExperience.UID = currExperience.experience_id ||  Date.now();

      dividedByType[currKey].push(currExperience);
    }
  }


  ['formal', 'training', 'skills'].forEach(sectionType =>
  {
    if (Object.keys(CVData).includes(sectionType) ) {
      dividedByType[sectionType] = [];
      console.log(CVData[sectionType])
      CVData[sectionType].items.map(item => (
        dividedByType[sectionType].push(
          {
            'name': item.title,
            'date': sectionType !== 'formal' && item.date,
          }
          )
      ))
    }
  }
  );

  return dividedByType;
};
