import {eventChannel, END} from 'redux-saga';
import {
  all,
  call,
  put,
  take,
  takeLatest,
  takeEvery,
  select,
  throttle,
} from 'redux-saga/effects';
import axios from 'axios';
import {backendUrl, backendUrlV2, nanoLearningUrl} from '@config';

import {stringifyUrlParams} from '@utils/requests.utils';

import {ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT} from '@actions/router.actions';
import {notificationsAdd} from '@actions/notifications.actions';
import * as alertActions from '@actions/alert.actions';

import retry from '@utils/sagas.utils';
import * as CT from '../types/competence';

import * as coursesActions from '@actions/courses.actions';
import * as configActions from '@actions/config.actions';
import {
  profileFetchPersonEvents,
  profileUpdateOneCompetences,
} from '@actions/profile.actions';

import {
  getEmployeesEvents,
  getSelectedPersonUsername,
} from '@selectors/employees.selectors';

import {
  getConfigObject,
  getCurrLanguage,
  getPropertiesForCurrLangAndTrack,
} from '@selectors/config.selectors';

import {
  employeesFetchEvents,
  employeesFetchEventsWaitlist,
  employeesFetchSelectedPersonEvents,
} from '@actions/employees.actions';

import {
  getCompetencesSearchTerm,
  getSelectedCompetencegroupId,
  getSelectedSubcompetencegroupId,
  getSelectedSubSubcompetencegroupId,
  getSelectedCompetencetypes,
  getCompetencegroups as getCompetencegroupsSelector,
  getCompetences as getCompetencesSelector,
  getInitializeMyCoursesView,
} from '@selectors/courses.selectors';

import {
  getProfile,
  isManager,
  getProfileUserName,
} from '@selectors/profile.selectors';

const defaultFieldsCompetences = [
  'short_description',
  'files',
  'title',
  'modified',
  'course_type',
  'competence_type_id',
  'competence_type_key',
  'durations',
  'has_parents',
  'competence_type',
  'url',
  'category',
  'description',
  'content(image,imageAltText,teaserForListView)',
];

const defaultFieldsCmsContent = [
  'title',
  'image',
  'video',
  'imageCaption',
  'imageAltText',
  'authorText',
  'authorImage',
  'files',
  'teaserForListView',
  'category',
  'short_description',
  'description',
];

const CourseAxios = axios.create({
  headers: {'X-Grape-Lang': localStorage.getItem('language')},
  withCredentials: true,
});

function* getCompetenceById(action) {
  const {cid} = action.payload;

  const fields = defaultFieldsCompetences.join(',');

  const params = encodeURI(stringifyUrlParams({
    fields,
    view: 'full',
  }));

  const competence = yield retry(() =>
    CourseAxios
      .request({
        method: 'GET',
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      })
      .then(response => response.data.competences));

  return {
    ...competence[0],
    cid: competence[0].id,
  };
}

function* waitForInitializeMyCoursesViewToFinish() {
  const initializeMyCoursesView = yield select(getInitializeMyCoursesView);

  if (initializeMyCoursesView.isFetching) {
    yield take([
      coursesActions.coursesInitializeMyCoursesViewSuccess,
      coursesActions.coursesInitializeMyCoursesViewFailure,
    ]);
  }
}

function* getCompetences(action) {
  try {
    const searchTerm = yield select(getCompetencesSearchTerm) || null;
    const selectedSubcompetencegroupId = yield select(getSelectedSubcompetencegroupId);

    const selectedSubSubcompetencegroupId = yield select(getSelectedSubSubcompetencegroupId);

    const selectedCompetencetypes = [
      ...yield select(getSelectedCompetencetypes),
    ];
    const selectedCompetencegroupId = [
      yield select(getSelectedCompetencegroupId),
    ];

    const notStartedByInitializeMyCoursesView
      = action
      && action.type !== coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW;

    if (notStartedByInitializeMyCoursesView) {
      // to prevent race conditions between this request and the one initialized by initialzie mycourses view

      yield waitForInitializeMyCoursesViewToFinish(action);
    }

    const configObject = yield select(getConfigObject);

    const types
      = selectedCompetencetypes.length && selectedCompetencetypes.join(',')
      || 'complex,ecourse,equivalents,course,content,nano';

    const fields = (
      action && action.payload && action.payload.fields
      || defaultFieldsCompetences
    ).join(',');

    const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

    const baseCompetenceGroupId
      = configForCurrLangAndTrack.courseCatalog
      && configForCurrLangAndTrack.courseCatalog.startAtGroupId;

    let params = encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      'competence_group_ids[]':
          selectedSubSubcompetencegroupId
          || selectedSubcompetencegroupId
          || selectedCompetencegroupId
          || baseCompetenceGroupId
          || '',
      'types[]': types,
    }));

    const disalowwedCompetenceGroupIds
      = configObject.getProperty('routes.course-catalog.disalowwedCompetenceGroupIds') || [];
    const allowChildCompetenceForGroupIds
      = configObject.getProperty('routes.course-catalog.allowChildCompetenceForGroupIds') || [];

    if (params) {
      params = `?${params}`;
    }

    yield put(coursesActions.coursesGetCompetencesRequest());

    try {
      const limit = 100;

      const competences = yield searchTerm
        ? retry(() =>
          CourseAxios
            .request({
              method: 'GET',
              params: {
                term: searchTerm,
                not_course_groups: disalowwedCompetenceGroupIds.join(','),
              },
              url: `${backendUrlV2}/competences/search`,
            })
            .then(response => response.data))
        : retry(() =>
          CourseAxios
            .request({
              method: 'GET',
              params: {
                children: '1',
                limit: limit + 1,
              },
              url: `${backendUrl}/api/competences/${params}`,

            })
            .then(response => response.data.competences));

      let filter_out = null;

      filter_out = selectedCompetencegroupId
        && !allowChildCompetenceForGroupIds.includes(selectedCompetencegroupId[0])
        ? competences.filter(c => !c.has_parents)
        : competences;

      let hasMore = false;

      if (filter_out.length >= limit) {
        hasMore = true;
        filter_out = filter_out.slice(0, limit - 1);
      }
      yield put(coursesActions.coursesGetCompetencesSuccess({
        competences: filter_out,
        hasMore,
      }));
    } catch (error) {
      console.error(error);
      yield put(coursesActions.coursesGetCompetencesFailure({error}));
    }
  } catch (error) {
    console.error(error);
  }
}

function* getFeaturedCompetences(action) {
  const featuredCompetenceIdsToGet
    = action && action.payload && action.payload.cids;

  yield put(coursesActions.coursesGetFeaturedCompetencesRequest());
  try {
    const featuredCompetences = yield all(featuredCompetenceIdsToGet.map(cid =>
      call(getCompetenceById, {payload: {cid}})));

    yield put(coursesActions.coursesGetFeaturedCompetencesSuccess({featuredCompetences}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetFeaturedCompetencesFailure({error}));
  }
}

export function getCourseEventsAPI(payload) {
  const fields = [
    'short_description',
    'title',
    'startdate',
    'competence_type',
    'competence_id',
    'enddate',
    'description',
    'confirmed(profile_image,fullname,firstname,lastname,user_name)',
    'waitlist',
    'person',
    'location',
  ].join(',');

  let params = '';

  params = payload.userName
    ? encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
      waitlist: 1,
      confirmed: 1,
      user_name: payload.userName,
    }))
    : encodeURI(stringifyUrlParams({
      fields,
      view: 'full',
    }));

  if (params) {
    params = `?${params}`;
  }
  let gourl = `${backendUrl}/api/events${params}`;

  if (payload.userName) {
    gourl = `${backendUrl}/api/personevents${params}`;
  }

  return CourseAxios
    .request({
      method: 'get',
      url: gourl,
      params: {limit: 100},
      withCredentials: true,
    })
    .then(function (response) {
      return response.data;
    });
}

function* getCourseEvents() {
  yield put(coursesActions.coursesGetCourseEventsRequest());
  try {
    const events = yield call(getCourseEventsAPI, {});

    yield put(coursesActions.coursesGetCourseEventsSuccess({courseEvents: events.events}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetCourseEventsFailure({error}));
  }
}

function* getCompetencegroups(action) {
  yield put(coursesActions.coursesGetCompetencegroupsRequest());
  const selectedCompetencegroupId = yield select(getSelectedCompetencegroupId);
  const currCompetencegroups = yield select(getCompetencegroupsSelector);

  const configObject = yield select(getConfigObject);

  // get groups bellow this group
  const configForCurrLangAndTrack = yield select(getPropertiesForCurrLangAndTrack);

  let baseCompetenceGroupId = null;

  baseCompetenceGroupId = configObject.isMapActivated
    ? configForCurrLangAndTrack.courseCatalog
      && configForCurrLangAndTrack.courseCatalog.startAtGroupId
    : configObject.getProperty('routes.course-catalog.startAtGroupId');

  const disalowwedCompetenceGroupIds
    = configObject.getProperty('routes.course-catalog.disalowwedCompetenceGroupIds') || [];

  console.log('CCCCCC');
  try {
    let competencegroups = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        url: `${backendUrl}/api/competencegroups${
            (!selectedCompetencegroupId
              || currCompetencegroups.data === null)
            && baseCompetenceGroupId
              ? `/${baseCompetenceGroupId}`
              : ''
          }`,
        params: {
          fields: 'color,title,files,children(title)',
          ...action && action.payload && action.payload.params || {},
        },
      })
        .then(response => response.data.competencegroups));

    if (baseCompetenceGroupId) {
      competencegroups = competencegroups[0].children;
    }

    competencegroups
      = competencegroups
      && competencegroups.filter(({id}) => !disalowwedCompetenceGroupIds.includes(id));

    const configDefaultCompetenceGroup
      = configForCurrLangAndTrack.courseCatalog
      && configForCurrLangAndTrack.courseCatalog.defaultSelectedCompetenceGroupId;

    const defaultSelectedCompetenceGroup = competencegroups.find(c => c.id === configDefaultCompetenceGroup);

    yield put(coursesActions.coursesGetCompetencegroupsSuccess({
      competencegroups,
      defaultSelectedCompetenceGroup: defaultSelectedCompetenceGroup
        ? defaultSelectedCompetenceGroup.id
        : null,
    }));
    if (action && action.payload && action.payload.onSuccess) {
      action.payload.onSuccess(competencegroups);
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetCompetencegroupsFailure({error}));
  }
}

function* getCompetencetypes() {
  yield put(coursesActions.coursesGetCompetencetypesRequest());
  try {
    const competencetypes = yield retry(() =>
      CourseAxios
        .request({
          method: 'GET',
          url: `${backendUrl}/api/competencetypes/`,
        })
        .then(response => response.data.competencetypes));

    yield put(coursesActions.coursesGetCompetencetypesSuccess({competencetypes}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesGetCompetencetypesFailure({error}));
  }
}

function* fetchMyCoursesViewData(action) {
  yield put(coursesActions.coursesInitializeMyCoursesViewRequest());
  yield put(coursesActions.coursesSetCompetencegroup({competenceGroupId: null}));
  yield put(coursesActions.coursesFiltersSetSubcompetencegroup({competenceGroupId: null}));
  yield put(coursesActions.coursesFiltersSetSubSubcompetencegroup({competenceGroupId: null}));

  try {
    yield all([yield call(getCompetencegroups)]);
    yield call(getCompetences, action);
    const featuredCompetencesToGet
      = action && action.payload && action.payload.alsoGetFeaturedCompetences;

    if (featuredCompetencesToGet) {
      yield call(getFeaturedCompetences, {payload: featuredCompetencesToGet});
    }

    yield put(coursesActions.coursesInitializeMyCoursesViewSuccess());
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesInitializeMyCoursesViewFailure());
  }
}

function* fetchCompetenceDetails(action) {
  const {cid} = action.payload;

  yield put(coursesActions.coursesEndCourse());

  const fields = [
    'description',
    'files',
    'title',
    'course_type',
    'competence_type_id',
    'competence_type',
    'competence_type_key',
    'durations',
    'children(competence_type,description,competence_type_key,competence_type_id,title,competence_id,files)',
  ].join(',');

  const fields_events = ['title', 'location'].join(',');

  const params = encodeURI(stringifyUrlParams({
    fields,
    view: 'full',
  }));

  const params_events = encodeURI(stringifyUrlParams({
    fields_events,
    view: 'full',
  }));

  yield put(coursesActions.coursesFetchCompetenceDetailsGetRequest());
  try {
    const {data: {competences: [competenceDetails]}} = yield retry(() =>
      CourseAxios.request({
        method: 'GET',
        params: {children: '1'},
        url: `${backendUrl}/api/competences/${cid}?${params}`,
        withCredentials: true,
      }));

    if (competenceDetails.competence_type_id) {
      /*
        this is classroom course, the events for it.
       */
      const {data: {events: eventsDetails}} = yield retry(() =>
        CourseAxios.request({
          method: 'GET',
          params: {children: '1'},
          url: `${backendUrl}/api/competences/${cid}/events?${params_events}`,
        }));

      competenceDetails.events = eventsDetails;
      /*
      # if manager, and course details

       */
      let userName = yield select(getProfileUserName);

      while (userName === null) {
        yield take();
        userName = yield select(getProfileUserName);
      }
      const isUserManager = yield select(isManager);

      if (isUserManager) {
        const userEvents = yield select(getEmployeesEvents);

        if (!userEvents) {
          yield put(employeesFetchEvents({}));
          yield put(employeesFetchEventsWaitlist({}));
        }
      }
    }

    competenceDetails.competence_type = {
      competence_type: competenceDetails.competence_type,
      competence_type_id: competenceDetails.competence_type_id,
    };

    if (competenceDetails.children) {
      for (let i = 0; i < competenceDetails.children.length; i += 1) {
        competenceDetails.children[i].competence_type = {
          competence_type: competenceDetails.children[i].competence_type,
          competence_type_id: competenceDetails.children[i].competence_type_id,
        };
      }
    }

    yield put(coursesActions.coursesFetchCompetenceDetailsGetSuccess({competenceDetails}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesFetchCompetenceDetailsGetFailure({error}));
  }
}

function* courseSignOn(action) {
  const {courseEventId, employees} = action.payload;

  const {data: person} = yield select(getProfile);
  const signedEmployees = employees || [person];

  yield put(coursesActions.courseSignOnRequest({ceid: courseEventId}));
  try {
    const results = {
      correct: [],
      errors: [],
    };

    yield all(signedEmployees.map(({fullname, user_name}) => {
      const user = fullname && fullname.trim() !== '' ? fullname : user_name;

      return call(() =>
        CourseAxios
          .request({
            method: 'POST',
            url: `${backendUrl}/api/personevents/${courseEventId}`,
            params: {user_name},
            withCredentials: true,
          })
          .then(response => {
            results.correct.push({
              user,
              response,
            });

            return response;
          })
          .catch(error => {
            console.error(error);
            results.errors.push({
              user,
              error,
            });

            return error;
          }));
    }));

    if (!employees) {
      let notification;

      if (results.correct && results.correct.length > 0) {
        notification = {
          text: results.correct[0].response.data.message,
          color: 'green',
        };
      } else if (results.errors && results.errors.length > 0) {
        notification = {
          title: 'Feil',
          text: results.errors[0].error.message,
          color: 'red',
        };
      }
      yield put(profileFetchPersonEvents({}));
      yield put(notificationsAdd({notification}));
    } else {
      yield put(employeesFetchEvents({}));
      yield put(employeesFetchEventsWaitlist({}));
    }

    yield put(coursesActions.courseSignOnResults({results}));
    yield put(coursesActions.courseSignOnSuccess());
  } catch (error) {
    console.error(error);
    yield put(coursesActions.courseSignOnFailure({error}));
  }
}

function* courseSignOff(action) {
  const {courseEventId, employees, onSuccess} = action.payload;
  const {data: person} = yield select(getProfile);

  let selectedUserName = null;

  try{
    selectedUserName = yield select(getSelectedPersonUsername);
  } catch{
    selectedUserName = null;
  }
  const signedEmployees = employees ? Array.isArray(employees) && employees || [employees] : [person];

  yield put(coursesActions.courseSignOffRequest({ceid: courseEventId}));
  try {
    const results = {
      correct: [],
      errors: [],
    };

    yield all(signedEmployees.map(({fullname, user_name}) => {
      const user = fullname && fullname.trim() !== '' ? fullname : user_name;

      return call(() =>
        CourseAxios
          .request({
            method: 'PUT',
            url: `${backendUrl}/api/personevents/${courseEventId}`,
            params: {
              action: 'off',
              user_name,
            },
            withCredentials: true,
          })
          .then(response => {
            results.correct.push({
              user,
              response,
            });

            return response;
          })
          .catch(error => {
            console.error(error);
            results.errors.push({
              user,
              error,
            });

            return error;
          }));
    }));

    let notification;

    if (results.correct && results.correct.length > 0) {
      notification = {
        text: results.correct[0].response.data.message,
        color: 'green',
      };
    } else if (results.errors && results.errors.length > 0) {
      notification = {
        title: 'Feil',
        text: results.errors[0].error.message,
        color: 'red',
      };
    }

    if (employees) {
      if (employees.user_name === selectedUserName) {
        yield put(employeesFetchSelectedPersonEvents(employees));
      }
      yield put(employeesFetchEvents(employees));
      yield put(employeesFetchEventsWaitlist(employees));
    }

    yield put(profileFetchPersonEvents({}));
    yield put(notificationsAdd({notification}));

    yield put(coursesActions.courseSignOffResults({results}));
    yield put(coursesActions.courseSignOffSuccess());
    if(onSuccess) {
      onSuccess();
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.courseSignOffFailure({error}));
  }
}

function* coursePreviewLoaded(action) {
  try {
    yield call(fetchCompetenceDetails, action);
  } catch (error) {
    console.error(error);
  }
  yield put(alertActions.actionClear());
}

function* onCourseStart(action) {
  try {
    const {cid, type} = action.payload;

    if (CT.CourseTypes.ecourse.includes(type)) {
      const {valid, lmsstarturls} = yield call(() =>
        axios
          .request({
            method: 'get',
            url: `${backendUrl}/api/lmsstarturls?competence_ids=${cid}&external=1`,
            withCredentials: true,
          })
          .then(response => response.data));

      const [{urls}] = lmsstarturls;

      if (valid === true && urls[0]) {
        // CLEAR THE INFO BOX.
        const open_url = `${urls[0].id}`;
        const win = window.open(open_url, '_blank');

        yield put(coursesActions.coursesLmsRunning({
          url: open_url,
          opened: !!win,
        }));
      } else {
        yield put(coursesActions.coursesLmsError());
      }
    } else if (CT.CourseTypes.nano.includes(type)) {
      const nanoCourseStartUrl = `${nanoLearningUrl}?id=${cid}`;

      yield put(coursesActions.coursesRunNanoCourse({
        url: nanoCourseStartUrl,
        cid,
        type,
      }));
    } else {
      const iframeMessageChannel = yield call(() =>
        eventChannel(emmiter => {
          window.addEventListener('message', ({data}) => {
            if (
              data === 'IFRAME_COURSE_FINISHED'
              || data === 'TAB_COURSE_FINISHED'
            ) {
              emmiter();
              emmiter(END);
            }
          });

          return () => {
            window.removeEventListener('message');
          };
        }));

      yield take(iframeMessageChannel);
      yield put(coursesActions.coursesCourseFinished({cid}));
    }
  } catch (error) {
    console.error(error);
    yield put(coursesActions.coursesCourseFailure({error}));
  }
}

function* onCourseSign(action) {
  const {courseId, password} = action.payload;
  const bodyFormData = new FormData();

  bodyFormData.set('formIndex', '0');
  bodyFormData.set('password', password);
  let status;

  try {
    const data = yield call(() =>
      CourseAxios
        .request({
          method: 'POST',
          url: `${backendUrl}/courses/sign_digitally/${courseId}`,
          data: bodyFormData,
          config: {headers: {'Content-Type': 'multipart/form-data'}},
        })
        .then(response => response.data));

    ({status} = data);
    if (data.statuscode === -1) {
      yield put(coursesActions.coursesSignCourseSuccess({
        status: data.status,
        courseId,
      }));
      yield put(notificationsAdd({
        notification: {
          text: data.status,
          color: 'green',
        },
      }));
      yield put(profileUpdateOneCompetences());
      yield put(coursesActions.coursesBeginSignature(null));
    } else {
      if (status) {
        yield put(notificationsAdd({
          notification: {
            text: status,
            color: 'red',
          },
        }));
      }
      coursesActions.coursesSignCourseError();
    }
  } catch (error) {
    console.error(error);
    if (status) {
      yield put(notificationsAdd({
        notification: {
          text: status,
          color: 'red',
        },
      }));
    }
    coursesActions.coursesSignCourseError({error});
  }
}

export function* fetchCourseCatalogNews(action) {
  yield put(coursesActions.fetchCourseCatalogNewsRequest());
  const {ids} = action.payload;

  try {
    const catalogNews = yield all(ids.map(id =>
      retry(() =>
        CourseAxios
          .request({
            method: 'GET',
            url: `${backendUrl}/api/cms/${id}/pages?fields=teaser,body,image,category,author`,
          })
          .then(res => res.data))));

    yield put(coursesActions.fetchCourseCatalogNewsSuccess({data: catalogNews}));
  } catch (error) {
    console.error(error);
    yield put(coursesActions.fetchCourseCatalogNewsFailure({error}));
  }
}

export default [
  takeLatest(coursesActions.COURSE_SIGNON, courseSignOn),
  takeLatest(coursesActions.COURSE_SIGNOFF, courseSignOff),
  takeLatest(coursesActions.COURSES_GET_COMPETENCES, getCompetences),
  takeLatest(
    coursesActions.COURSES_GET_FEATURED_COMPETENCES,
    getFeaturedCompetences,
  ),
  takeLatest(coursesActions.COURSES_GET_COMPETENCEGROUPS, getCompetencegroups),
  takeLatest(coursesActions.COURSES_GET_COMPETENCETYPES, getCompetencetypes),
  takeLatest(
    coursesActions.COURSES_INITIALIZE_MY_COURSES_VIEW,
    fetchMyCoursesViewData,
  ),
  takeLatest(coursesActions.COURSES_FILTERS_SET_FILTERS, getCompetences),
  throttle(700, coursesActions.COURSES_SET_SEARCHTERM, getCompetences),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_COMPETENCEGROUP,
    getCompetences,
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_SUBCOMPETENCEGROUP,
    getCompetences,
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_SET_SUB_SUBCOMPETENCEGROUP,
    getCompetences,
  ),
  takeLatest(
    coursesActions.COURSES_FILTERS_TOGGLE_COMPETENCETYPE,
    getCompetences,
  ),
  takeLatest(coursesActions.COURSES_GET_COURSEEVENTS, getCourseEvents),
  takeLatest(
    ROUTER_COURSE_CATALOG_COURSE_PREVIEW_DID_MOUNT,
    coursePreviewLoaded,
  ),
  takeLatest(
    coursesActions.fetchCourseCatalogNews().type,
    fetchCourseCatalogNews,
  ),
  takeLatest(coursesActions.coursesStartCourse().type, onCourseStart),
  takeEvery(coursesActions.COURSES_SIGN_COURSE, onCourseSign),
];
