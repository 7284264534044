import styled from '@emotion/styled';

export const HasMoreCard = styled.div`
  cursor: pointer;
  align-items: center;
  border: 1px solid #E0E0E0;
  margin: 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  flex-grow: 1;
  font-size: 0.9em;
`;

export const MobileCard = styled.div`
  cursor: pointer;
  align-content: center;
  display: flex;
  flex-direction: column;
  background: #fff;
  align-content: center;
  align-items: center;
  flex-grow: 1;
  font-size: 0.9em;
  margin-bottom: 4rem;

  .statistic-card-mobile {
    font-size: 1.2em;
    padding: 10px;
    width: 100%;
    border:0!important;
  }
  .progress {
    font-size: 1.0em;
    text-indent: 0;
    .progress__bar-overall--full-width {
      margin-top: 10px;
    }
  }
`;


