import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import completed from '../../../assets/svg/completed.svg';

import {getRouteWithParams} from '@utils/routes.utils';
import CourseButtons from '@components/course/course-buttons';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import {faCalendarAlt, faChevronDown, faChevronUp, faUserCheck} from '@fortawesome/pro-solid-svg-icons';
import CompetencesList from '@components/competences/competence-list';
import {ActionButton} from '@components/buttons/actionButton';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import {Pencil} from 'phosphor-react';
import {getIsMobile} from '@selectors/global.selectors';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

const createSubtitleContent = content => ({__html: content});

class CompetenceOther extends Component {
  constructor(props) {
    super(props);
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    if (removeRequirement) {
      removeRequirement(id);
    }
  }

  render() {
    const {
      size,
      course,
      roleId,
      defaultImg,
      isPassed,
      onEdit,
      canEdit,
      isMobile,
    } = this.props;

    return (
      <>

        {size === 'big' && (
          <>
            {isPassed && (
              <>
                <Link
                  to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                    courseId: course.id,
                    roleId,
                  })}
                  className="cell small-3 medium-3 course-image-wrapper"
                >
                  <div
                    className="img"
                    style={{
                      minHeight: 120,
                      backgroundImage: `url(${course.cover && course.cover.url
                      || defaultImg})`,
                    }}
                  />
                </Link>
                <div className="content cell small-9 medium-8 course-content-wrapper">
                  <Link
                    to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                      courseId: course.id,
                      roleId,
                    })}
                    className="__title"
                  >
                    <h2>{course.competence_title}</h2>
                  </Link>
                  <div
                    style={{
                      color: 'gray',
                      width: '80%',
                      'float': 'left',
                    }}
                  >
                    {course.competence_type.competence_type
                    && (
                      <div>
                        {i18nCourseGetCompetenceType(course)}
                      </div>
                    )}
                    <div
                      className="desc"
                      style={{
                        color: 'rgba(0,0,0,0.5)',
                        paddingTop: 10,
                      }}
                      dangerouslySetInnerHTML={createSubtitleContent(course.short_description && course.short_description.replace(/(<([^>]+)>)/gi, '').slice(0, 150) + '...')}
                    />
                    <CourseButtons course={course} />
                  </div>
                  <div
                    style={{
                      color: 'gray',
                      width: '10%',
                      'float': 'right',
                    }}
                  >
                    <img
                      alt="completed"
                      src={completed}
                    />
                  </div>
                </div>
              </>
            ) || (
              <>
                <Link
                  to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                    courseId: course.id,
                    roleId,
                  })}
                  className="cell small-4 medium-4 course-image-wrapper"
                >
                  <div
                    className="img"
                    style={{
                      backgroundImage: `url(${course.cover && course.cover.url
                      || defaultImg})`,
                    }}
                  />
                </Link>
                <div className="content cell small-8 medium-7 course-content-wrapper">
                  <Link
                    to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                      courseId: course.id,
                      roleId,
                    })}
                    className="__title"
                  >
                    <h2>{course.competence_title}</h2>
                  </Link>
                  {course.competence_type.competence_type
                  && (
                    <div>
                      {i18nCourseGetCompetenceType(course)}
                    </div>
                  )}
                  <div
                    className="desc"
                    dangerouslySetInnerHTML={createSubtitleContent(course.short_description)}
                  />
                  {course.files && !!course.files.length && (
                    <div className="course-preview__files">
                      {course.files.map(({title, url}) =>
                        title !== 'cover' && (
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn course-preview__file-button"
                          >
                            <i className="fas fa-file-alt" />
                            {' '}
                            {title}
                          </a>
                        ))}
                    </div>
                  )}

                  <CourseButtons course={course} />
                </div>
              </>
            )}
          </>
        ) || size === 'employee' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 cell">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >

                  {canEdit && !isMobile && (
                    <div className="edit-button">
                      <ActionButton
                        onClick={() => onEdit(course)}
                        variant="defaultTheme"
                      >
                        {i18n('globals.edit')}
                      </ActionButton>
                    </div>
                  )}
                  {canEdit && isMobile && (
                    <div className="edit-button mobile">
                      <Pencil
                        color="#0b5dd7"
                        role="button"
                        tabIndex="0"
                        onKeyUp={evt => evt.key === 'Enter' && onEdit(course)}
                        onClick={() => onEdit(course)}
                      />
                    </div>
                  )}
                  <div className="roles-list__competence-title">
                    <div
                      className="roles-list__competence-status"
                    >
                      {course.passed === 100
                        && (
                          <img
                            src={completed}
                            alt="all-ok"
                            style={{width: 20}}
                          />
                        )}
                    </div>
                    <div>
                      <div className="small-header">
                        {i18nCourseGetCompetenceType(course)}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            &nbsp;&nbsp;
                            {course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon={faUserCheck} />
                            &nbsp;&nbsp;
                            {course.verified.fullname}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>
            </div>
          </div>
        ) || size === 'compact' && (
          <div className={`competence-card ${size}`}>
            <div className="grid-x">
              <div className="small-12 cell">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >
                  <div className="roles-list__competence-title">
                    <div>
                      <div className="small-header">
                        {i18nCourseGetCompetenceType(course)}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                              &nbsp;&nbsp;
                            {course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon={faUserCheck} />
                              &nbsp;&nbsp;
                            {course.verified.fullname}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>
            </div>
          </div>

        )}
      </>
    );
  }
}

CompetenceOther.propTypes = {
  course: PropTypes.shape({}).isRequired,
  size: PropTypes.string,
  removeRequirement: PropTypes.func,
  roleId: PropTypes.number,
};

CompetenceOther.defaultProps = {
  size: 'normal',
  removeRequirement: undefined,
  roleId: -2,
};

const mapStateToProps = state => ({isMobile: getIsMobile(state)});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetenceOther);
