import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import {getFormSubmitErrors, stopSubmit} from 'redux-form';

import {Redirect} from 'react-router-dom';

import * as authActions from '@actions/auth.actions';

import AuthService from '../utils/auth-service';

import {screenreaderOnlyMinxin} from '@styles/mixins';

import {version, buildHash} from '@src/config';
import LoginFormTwo from '../components/LoginFormTwo/LoginFormTwo';
import ChangePasswordForm from '../components/LoginFormTwo/ChangePasswordForm';

import backgroundOslo1 from '../../../assets/images/background-olso-1.jpg';

import {i18n} from '@src/i18n';

import '../styles/login-container.scss';
import {ReactComponent as SnapperLogoDimmed} from '@src/assets/svg/logo/snapper_dimmed.svg';

import {
  Wrapper,
  LeftPart,
  RightPart,
  BgImage,
  LanguageSelectWrapper,
  MainHeader,
  Warning,
  LoginFormWrapper,
  Footer,
  FooterLogo,
  FooterTextWrapper,
  FooterMainText,
  FooterSecondaryText,
} from './styles/styles';
import LanguageSelect from '../components/LanguageSelect/LanguageSelect';

const isIE = false || !!document.documentMode;

class LoginContainer extends Component {
  state = {showSection: 'login'};

  componentDidMount() {
    const {to, sessionId, authAttempts} = this.props;

    if (!sessionId) {
      window.setTimeout(function () {
        if (to) {
          if (authAttempts <= 2) {
            authActions.authAttempt();
            window.location.replace(to + '?redirect=/auth/login');
          } else {
            console.warn('authAttempts:', authAttempts);
          }
        }
      }, 500);
    }
  }

  handleForgot = ({username}) => {
    const {authLoginNewPasswordRequest} = this.props;

    authLoginNewPasswordRequest({username});
  };

  handleSelect = type => {
    const {authGotoNext} = this.props;

    authGotoNext(type);
  };

  verifyUser = ({username}) => {
    const {authVerifyUser, stopSubmit} = this.props;

    if (username) {
      authVerifyUser({username});
    }
  };

  handleLogin = ({username, password, stage}) => {
    const {authLoginRequest, stopSubmit} = this.props;

    authLoginRequest({
      username,
      password,
    });

    // Resets previous errors in the login-form
    stopSubmit('login', {});
  };

  render() {
    const {
      sessionId,
      forgotStatus,
      forgotStatusMsg,
      secondLogin,
      submitErrors,
      to,
      stage,
      serviceName = null,
      mainImage = null,
      currLanguage,
      languages = ['no', 'en'],
      authCloseResetPassowrdForm,
    } = this.props;
    const {showSection} = this.state;

    if (to) {
      return (
        <div>{i18n('login.progress')}</div>
      );
    }

    const loginFailure = submitErrors && submitErrors.errorMsg !== undefined;

    if (false === true && AuthService.isAuthorized(sessionId)) {
      return <Redirect to="/my-education" />;
    }

    if (forgotStatus === 2 && showSection === 'forgot') {
      this.setState({showSection: 'login'});
    }

    // Use this to switch menu type
    const loginType = 0;

    if (loginType === 0 && secondLogin && sessionId) this.handleSelect('this');

    const onChoosedLanguage = langcode => {
      localStorage.setItem('language', langcode);
      this.forceUpdate();
    };

    return (
      <Wrapper className="login-wrapper">
        <LeftPart className="left-part">

            <React.Fragment>
              {Array.isArray(languages) && languages.length > 1 && (
                <LanguageSelectWrapper>
                  <LanguageSelect
                    languages={languages}
                    currLanguage={currLanguage}
                    onChoosedLanguage={onChoosedLanguage}
                  />
                </LanguageSelectWrapper>
              )}
              <>
                <LoginFormWrapper>
                  <MainHeader>
                    {i18n('login.login')}
                    {serviceName && ` – ${serviceName}` || ''}
                  </MainHeader>
                  {isIE && <Warning>{i18n('UNSUPPORTED_BROWSER')}</Warning>}
                  {showSection === 'login' && (
                    <LoginFormTwo
                      initialValues={{
                        username: '',
                        stage,
                        password: '',
                      }}
                      stage={stage}
                      onSubmit={this.handleLogin}
                      invalidCredentials={loginFailure}
                      handleForgotPassword={() => {
                        this.setState({showSection: 'forgot'});
                      }}
                    />
                  )
                    || (showSection === 'forgot' && (
                      <ChangePasswordForm
                        forgotStatus={forgotStatus}
                        initialValues={{username: ''}}
                        onSubmit={this.handleForgot}
                        forgotStatusMsg={forgotStatusMsg}
                        handleCancel={() => {
                          this.setState({showSection: 'login'});
                          authCloseResetPassowrdForm();
                        }}
                      />
                    )
                    || showSection === 'faq' && (
                      <div>
                        <h1>FAQ</h1>
                      </div>
                    ))}
                </LoginFormWrapper>

                <Footer>
                  <FooterLogo aria-label="Snapper logo">
                    <SnapperLogoDimmed
                      width="65"
                      height="65"
                      aria-hidden="true"
                    />
                  </FooterLogo>
                  <FooterTextWrapper>
                    <FooterMainText>
                      <a href="https://snapper.no">Snapper Net Solutions</a>
                    </FooterMainText>
                    <FooterSecondaryText>
                      <span css={screenreaderOnlyMinxin}>
                        {i18n('login.version') + ':'}
                      </span>
                      {' '}
                      {`${buildHash} v. ${version}`}
                    </FooterSecondaryText>
                  </FooterTextWrapper>
                </Footer>
              </>
            </React.Fragment>

        </LeftPart>

        <RightPart className="right-part">
          <BgImage
            className="login-background"
            src={backgroundOslo1}
          />
        </RightPart>
      </Wrapper>
    );
  }
}

LoginContainer.propTypes = {
  sessionId: PropTypes.string,
  secondLogin: PropTypes.bool.isRequired,
  forgotStatus: PropTypes.number,
  forgotStatusMsg: PropTypes.string,
  authLoginRequest: PropTypes.func.isRequired,
  authGotoNext: PropTypes.func.isRequired,
  authVerifyUser: PropTypes.func.isRequired,
  authLoginNewPasswordRequest: PropTypes.func.isRequired,
  authCloseResetPassowrdForm: PropTypes.func.isRequired,
  submitErrors: PropTypes.shape({}),
  stopSubmit: PropTypes.func.isRequired,
  stage: PropTypes.string.isRequired,
  currLanguage: PropTypes.string.isRequired,
};

LoginContainer.defaultProps = {
  sessionId: undefined,
  forgotStatus: -1,
  forgotStatusMsg: undefined,
  submitErrors: {},
};

const mapStateToProps = state => ({
  sessionId: state.auth.sessionId,
  stage: state.auth.stage,
  loginAttempts: state.auth.loginAttempts,
  forgotStatus: state.auth.newPwdStatus,
  secondLogin: state.auth.secondLogin,
  forgotStatusMsg: state.auth.newPwd,
  submitErrors: getFormSubmitErrors('login')(state),
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    ...authActions,
    stopSubmit,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
