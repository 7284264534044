import React, {Component} from 'react';
import PropTypes from 'prop-types';

import './competence.scss';
import {backendUrl} from '@config';

import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {getProfile} from '@selectors/profile.selectors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {getRouteWithParams} from '@utils/routes.utils';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';

import {fetchCompetencesChildren} from '@actions/profile.actions';
import CompetencesList from '@components/competences/competence-list';
import {faChevronDown, faChevronUp, faChevronRight, faCalendarAlt, faUserCheck, faHourglass, faSpinner} from '@fortawesome/pro-solid-svg-icons';
import {Link} from 'react-router-dom';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import completed from '../../../assets/svg/completed.svg';
import {ActionButton} from '@components/buttons/actionButton';
import {buildStyles, CircularProgressbar} from 'react-circular-progressbar';
import {css, withTheme} from '@emotion/react';
import {getIsMobile} from '@selectors/global.selectors';
import {Pencil} from 'phosphor-react';
import {AnimatePresence, motion} from 'framer-motion';
import {FilesList} from '@components/files-list/files-list';
import {verifyPassed} from '../../utils/misc.utils';


class CompetenceComplex extends Component {
  state = {isExpanded: false};

  handleChildrenUpdate = values => {
    const {course, root, section, showChildren} = this.props;

    const {isExpanded} = this.state;

    if(showChildren) {
      showChildren(values);
    }

    this.setState({isExpanded: !isExpanded});
    if (!isExpanded && course.children && course.children.length === 0) {
      const {fetchCompetencesChildren} = this.props;

      fetchCompetencesChildren({
        courseId: values,
        root,
        isOtherCompetences: section === 'other',
      });
    }
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    if (removeRequirement) {
      removeRequirement(id);
    }
  }

  render() {
    const {
      size,
      course,
      roleName,
      canEdit,
      onEdit,
      isFetching,
      fetchChildren,
      sub,
      theme,
      loading,
      roleId,
      defaultImg,
      isMobile,
      noExpandChildren,
    } = this.props;

    const {isExpanded} = this.state;

    return (
      <>
        {size === 'compact' && (

          <div className={`competence-card ${size}`}>
            <div
              className="grid-x clickable-card"
              role="button"
              tabIndex={0}
              onKeyPress={() => this.handleChildrenUpdate(course.competence_id)}
              onClick={() => this.handleChildrenUpdate(course.competence_id)}
            >
              <div className="cell auto">
                <div className="small-header">
                  {i18nCourseGetCompetenceType(course)}
                  {roleName && (
                    <span>
                      {' '}
                      (
                      {roleName}
                      )
                    </span>
                  )}
                </div>
                {course.competence_title}
                {course.files && !!course.files.length && (
                  <div className="course-preview__files">
                    {course.files.map(({title, url}) =>
                      title !== 'cover' && (
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="btn course-preview__file-button"
                        >
                          <i className="fas fa-file-alt" />
                          {' '}
                          {title}
                        </a>
                      ))}
                  </div>
                )}
              </div>
              <div className="cell shrink button">
                {!isExpanded && <FontAwesomeIcon icon={faChevronDown} /> || (
                  course.children
                    && course.children.length === 0
                    && isFetching && <span>...</span>
                    || <FontAwesomeIcon icon={faChevronUp} />
                )}
              </div>
            </div>
            <div className="small-12 cell">
              <AnimatePresence>
                {isExpanded && course.children && course.children.length !== 0 && (
                  <motion.div
                    key="animate-expand"
                    css={{overflow: 'hidden'}}
                    animate={{height: 'auto'}}
                    initial={{height: 0}}
                    exit={{height: 0}}
                    transition={{
                      ease: 'easeOut',
                      duration: 0.3,
                    }}
                    className="sub equals"
                  >
                    <CompetencesList
                      size="compact-sub"
                      sub={true}
                      courses={course.children}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        )}
        {size === 'employee' && (
          <div className={`competence-card ${size}${isExpanded ? ' expanded' : ''}`}>

            <div
              className="grid-x clickable-card"
              role="button"
              tabIndex={0}
              onKeyPress={() => this.handleChildrenUpdate(course.competence_id)}
              onClick={() => this.handleChildrenUpdate(course.competence_id)}
            >
              <div className="cell auto">
                <div
                  className="roles-list__competence"
                  key={course.competence_id}
                >
                  {canEdit && !isMobile && (
                    <div className="edit-button">
                      <ActionButton
                        variant="defaultTheme"
                        onClick={() => onEdit(course)}
                      >
                        {i18n('globals.edit')}
                      </ActionButton>
                    </div>
                  )}
                  {canEdit && isMobile && (
                    <div className="edit-button mobile">
                      <Pencil
                        color="#0b5dd7"
                        role="button"
                        tabIndex="0"
                        onKeyUp={evt => evt.key === 'Enter' && onEdit(course)}
                        onClick={() => onEdit(course)}
                      />
                    </div>
                  )}
                  <div className="roles-list__competence-title">
                    <div className="roles-list__competence-status">
                      {verifyPassed(course)
                        && (
                          <img
                            src={completed}
                            alt="all-ok"
                            style={{width: 20}}
                          />
                        )
                       || course.passed !== 0 && (
                         <div
                           style={{
                             width:24,
                             height: 24,
                             backgroundColor: '#fafafa',
                             padding: 4,
                             justifyContent: 'center',
                             alignItems: 'center',
                             display: 'flex',
                             fontSize: 9,
                             borderRadius: 24,
                             boxShadow: '0px 1px 0px rgba(0, 0, 0, 0.12)',
                           }}
                         >
                           <CircularProgressbar
                             value={course.passed}
                             strokeWidth={50}
                             styles={buildStyles({
                               strokeLinecap: 'butt',
                               backgroundColor: 'red',
                               pathColor: theme.chartPrimaryColor,
                               trailColor: theme.chartSecondaryColor,
                             })}
                           />
                         </div>
                       )}
                    </div>
                    <div className="info-wrapper">
                      <div className="small-header">
                        {i18nCourseGetCompetenceType(course)}
                      </div>
                      {course.competence_title}
                      <div className="signed-info">
                        {course.verified && course.verified.date && (
                          <div className="signed-date">
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            &nbsp;&nbsp;
                            {course.verified.date}
                          </div>
                        )}
                        {course.verified && course.verified.fullname && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon={faUserCheck} />
                            &nbsp;&nbsp;
                            {course.verified.fullname}
                          </div>
                        )}
                        {course.valid_until && (
                          <div className="signed-by">
                            <FontAwesomeIcon icon={faHourglass} />
                            &nbsp;&nbsp;
                            {course.valid_until}
                          </div>
                        )}
                      </div>

                    </div>

                    {course.files && course.files.length > 0 && !isExpanded && (
                      <FilesList
                        className="files-list"
                        files={course.files.map(({title, url}) => ({
                          title,
                          url,
                        }))}
                        wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                      />
                    )}

                  </div>
                  <div className="roles-list__competence-description" />
                </div>
              </div>

              <div className="cell shrink button">
                {!isExpanded &&  !noExpandChildren && <FontAwesomeIcon icon={(sub ? faChevronRight : faChevronDown)} /> || (
                  course.children
                    && course.children.length === 0
                    && isFetching && <span>...</span>
                    || !noExpandChildren && <FontAwesomeIcon icon={faChevronUp} />
                )}
              </div>
            </div>
            <div className="small-12 cell">
              <AnimatePresence>
                {isExpanded && course.children && course.children.length !== 0 && (
                  <motion.div
                    key="animate-expand"
                    css={{overflow: 'hidden'}}
                    animate={{height: 'auto'}}
                    initial={{height: 0}}
                    exit={{height: 0}}
                    transition={{
                      ease: 'easeOut',
                      duration: 0.3,
                    }}
                    className="sub equals"
                  >
                    <CompetencesList
                      size="employee"
                      canEdit={canEdit}
                      onEdit={onEdit}
                      sub={true}
                      courses={course.children}
                    />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </div>
        )}
        {size === 'big' && (
          <>
            <div className="cell small-12 medium-4 course-image-wrapper">
              <Link
                to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                  courseId: course.id,
                  roleId,
                })}
              >
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${course.cover
                      && course.cover.url
                      || defaultImg})`,
                  }}
                />
              </Link>
            </div>
            <div className="content cell small-12 medium-8 course-text-wrapper">
              <div className={`${size}`}>
                <div className="grid-x">
                  <div className="small-12 cell">
                    <div className="small-header">
                      {roleName && (
                        <span>
                          {' '}
                          (
                          {roleName}
                          )
                        </span>
                      )}
                    </div>
                    <h2>
                      <Link
                        to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                          courseId: course.id,
                          roleId,
                        })}
                        className="__title"
                      >
                        {course.title}
                      </Link>
                    </h2>
                    {i18nCourseGetCompetenceType(course)}

                    <div
                      className="desc"
                      dangerouslySetInnerHTML={{__html: course.short_description}}
                    />

                    {course.files && course.files.length > 0 && (
                      <FilesList
                        className="files-list"
                        files={course.files.map(({title, url}) => ({
                          title,
                          url,
                        }))}
                        wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                      />
                    )}

                    <div className="cell small-12 medium-8 button-wrapper">
                      <ActionButton
                        withRightChrevron
                        className="action-btn"
                        variant="primaryTheme"
                        onClick={!loading ? () => fetchChildren(course.competence_id) : undefined}
                        disabled={loading}
                      >
                        {!loading
                          ? i18n('role.open-competence')
                          : (
                            <FontAwesomeIcon
                              spin
                              icon={faSpinner}
                            />
                          )}
                      </ActionButton>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </>
        )}

      </>
    );
  }
}

CompetenceComplex.propTypes = {
  course: PropTypes.shape({}).isRequired,
  profile: PropTypes.shape({}).isRequired,
  roleName: PropTypes.string,
  isFetching: PropTypes.bool.isRequired,
  size: PropTypes.string,
  removeRequirement: PropTypes.func,
  root: PropTypes.string,
  fetchCompetencesChildren: PropTypes.func.isRequired,
  roleId: PropTypes.number,
};

CompetenceComplex.defaultProps = {
  roleName: '',
  size: 'normal',
  removeRequirement: undefined,
  root: 'std',
  roleId: -2,
};

const mapStateToProps = state => ({
  profile: getProfile(state),
  isMobile: getIsMobile(state),
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {fetchCompetencesChildren},
    dispatch,
  );

export default withTheme(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetenceComplex)));
