import styled from '@emotion/styled';

export const OpenTree = styled.div`
  align-self: flex-end;
  cursor: pointer;
  color: ${({theme}) => theme.chartPrimaryColor};
  align-self: center;
  margin-left: 50px;
  font-size: ${({theme}) => theme.fonts.sm};
`;
export const Link = styled.div`
  height: 40px;
  width: 100%;
  position: relative;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: ${({theme}) => theme.chartPrimaryColor};
  align-content: center;
  align-items: center;
  padding-left: 20px;
  color: white;
  font-weight:600;
  display: flex;
  svg {
    position: absolute;
    right: 20px;
    font-size: 20px;
}
`;
export const Card = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  border: 1px solid #E0E0E0;
  margin: 15px;
  border-radius: 5px;
  background: white;
`;

export const CardInner = styled.div`
  flex: 1 1 auto;
  padding: 35px 8px;
  .statistic-card__circle-wrapper{
    svg {
      color: ${({theme}) => theme.chartSecondaryColor}!important;
    }
  }
`;
