import styled from '@emotion/styled';
import {darken,} from 'polished';

export const FormWrapper = styled.div`
  width: 100%;
  clear: both;
  .two {
    display: flex;
    width: 100%;

    > * {
      flex: 1;
    }
`;

export const DropZone = styled.div`
  border-radius: 4px;
  cursor: pointer;
  width: 200px;
  padding: 6px 16px;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
  text-align: center;
  border: 1px solid #C2C2C2;
  background-color: #FCFAF9;
  &:hover {
    background: ${darken(0.06, '#FCFAF9')};
  }
`;

export const Files = styled.div`
  font-size: 0.9rem;
  margin-top: 6px;
  color: #424242;
  margin-bottom: 20px;
  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }
`;


export const HelpTxt = styled.div`
  font-size: 0.9rem;
  margin-top: 6px;
  margin-bottom: 20px;
  color: #424242;
`;


export const ButtonWrapper = styled.div`
  display: flex;

  .left {
    justify-content: flex-start;
    flex: 1;
    align-self: center
  }
  .right {
    justify-content: flex-end;
    flex: 1;
    display: flex;
  }
`;


