import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import * as T from '@types/load.types';

import PropTypes from 'prop-types';
import {Section, ContentWrapper,  Header, Content, ClickToExpand, CaretIcon} from './employee-expired-styles';

import {i18n} from '@src/i18n';
import ExpiringList from '@components/expiring-competences';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretRight} from '@fortawesome/pro-solid-svg-icons';
import {css} from '@emotion/react';
import {CircleWavyWarning} from 'phosphor-react';
import {AnimatePresence, motion} from 'framer-motion';


const EmployeesExpired = ({
  isMobile,
  expired,
  orgId,
  ...props
}) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Section>
      <Header
        role="button"
        tabIndex="0"
        onClick={() => setExpanded(!expanded)}
      >
        <ContentWrapper>
          <div>
            {i18n('employees.course-competence-overview')}
          </div>
          <motion.div
            style={{
              transformOrigin:'center',
              marginLeft: '0.8em',
              width: 'fit-content',
              height: 'fit-content',
            }}
            animate={expanded ? 'expanded' : 'collapsed'}
            variants={{
              expanded: {rotate: 90},
              collapsed: {rotate: 0},
            }}
          >
            <CaretIcon
              icon={faCaretRight}
            />
          </motion.div>
        </ContentWrapper>

        <ClickToExpand>
          {i18n(`globals.click-to-${expanded ? 'collapse' : 'expand'}`)}
        </ClickToExpand>
      </Header>
      <AnimatePresence>

        <Content>
          {expired?.status !== T.LoadStatuses.LOADED && (
            <div>
              {i18n('globals.loading')}
            </div>
          ) || expired?.data.length !== 0 && (
            <div>
              {!expanded && (
                <div
                  css={css`display: flex; align-items: center;`}
                >
                  <CircleWavyWarning
                    size={17}
                    weight="fill"
                    color="#E00000"
                    css={css`margin-right: 0.4em;`}
                  />
                  <div
                    onClick={() => setExpanded(!expanded)}
                    dangerouslySetInnerHTML={{
                      __html:
                i18n(`employees.expire-will${ expired.data.length !== 1 ? '-plural' : ''}`, {
                  functionArgs: {
                    x:
                  `<span class="no-expired">(${expired.data.length})</span>`,
                  },
                }),
                    }}
                  />
                </div>
              )}
              {expanded && (
                <motion.div
                  initial={{
                    paddingTop: 0,
                    height: 0,
                  }}
                  animate={{height:  'auto'}}
                  exit={{
                    paddingTop: 0,
                    height: 0,
                  }}
                  transition={{
                    ease: 'easeInOut',
                    duration: 0.3,
                  }}
                >
                  <ExpiringList
                    orgId={orgId}
                    expiring={expired.data}
                  />
                </motion.div>
              )}
            </div>
          )}
        </Content>
      </AnimatePresence>
    </Section>
  );
};

export default EmployeesExpired;
