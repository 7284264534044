import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react';
import * as T from '@types/load.types';

import PropTypes from 'prop-types';
import {Wrapper, Section, Header, Content} from './employees-courses-styles';

import {i18n} from '@src/i18n';
import {EventPartipicantsList} from '@components/course/course-details/course-event-partipicant-list/course-event-partipicant-list';


const EmployeesCourses = ({
  myProfileId,
  myOrganisationId,
  events,
  isMobile,
  ...props
}) => {
  return (
    <Wrapper>
      <Section>
        <Header>{i18n('employees.course-overview')}</Header>
        <Content>
          {events?.status !== T.LoadStatuses.LOADED && (
            <div>
              {i18n('globals.loading')}
            </div>
          ) || events?.data.length === 0 && (
            <div>
              {i18n('employees.no-courses')}
            </div>
          ) || events?.data.length !== 0 && (
            <div>
              <EventPartipicantsList
                myProfileId={myProfileId}
                myOrganisationId={myOrganisationId}
                myEmployeesEvents={events.data}
              />
            </div>
          )}
        </Content>
      </Section>
    </Wrapper>
  );
};

export default EmployeesCourses;
