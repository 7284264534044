import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router';

import './action-bar.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {employees as routes} from '@routes/routes.manifest';
import {backendUrl} from '@config';
import {ActionButton} from '@components/buttons/actionButton';
import {css} from '@emotion/react';
import {i18n} from '@src/i18n';
import {DropdownWithActionButton} from '@components/dropdown-with-action-button/dropdown-with-action-button';
import {getRouteWithParams} from '@utils/routes.utils';

class ActionBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      type: props.type,
    };
  }

  render() {
    const {
      history: {push},
      orgId,
      searchEmployee,
      addEmployee,
      items,
    } = this.props;

    const {open, type} = this.state;


    return (
      <div className="action-bar">
        {type === 'employee-bar' && (
          <DropdownWithActionButton
            showSelectedAsTitle={false}
            variant="dark"
            actionButton={{
              text: i18n('employees.actions'),
              souldOpenDropdown: true,
            }}
            onChange={val => {
              switch (val) {
              case 'add-employee': {
                addEmployee();
                break;
              }
              case 'move-employee': {
                searchEmployee();

                break;
              }
              case 'export-excel': {
                window.location = `${backendUrl}/organisations/personstoexcel/${orgId}`;

                break;
              }
              // No default
              }
            }}
            items={items}
          />
        )}
      </div>
    );
  }
}

ActionBar.propTypes = {
  history: PropTypes.shape({push: PropTypes.func.isRequired}).isRequired,

  searchEmployee: PropTypes.func,
  type: PropTypes.string.isRequired,
  orgId: PropTypes.number.isRequired,
};

ActionBar.defaultProps = {searchEmployee: null};

export default withRouter(ActionBar);
