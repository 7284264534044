import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {getRouteWithParams} from '@utils/routes.utils';
import {myEducation as myEducationRoutes} from '@routes/routes.manifest';
import completed from '../../../assets/svg/completed.svg';
import {i18n, i18nCourseGetCompetenceType} from '@src/i18n';
import {faCalendarAlt, faHourglass, faUserCheck} from '@fortawesome/pro-solid-svg-icons';
import {ActionButton} from '@components/buttons/actionButton';
import {Pencil} from 'phosphor-react';
import {bindActionCreators} from 'redux';
import {getIsMobile} from '@selectors/global.selectors';
import {connect} from 'react-redux';
import {FilesList} from '@components/files-list/files-list';
import {css} from '@emotion/react';
import {verifyPassed} from '../../utils/misc.utils';


const createSubtitleContent = content => ({__html: content});

class CompetenceVerification extends Component {
  constructor(props) {
    super(props);
  }

  removePersonalCompetence(id) {
    const {removeRequirement} = this.props;

    if (removeRequirement) {
      removeRequirement(id);
    }
  }

  render() {
    const {
      size,
      course,
      roleId,
      defaultImg,
      onEdit,
      isPassed,
      passed,
      canEdit,
      isMobile,
    } = this.props;

    return (
      size === 'compact' && (
        <div className="course-row">
          <div className="cell small-12">
            {course.competence_type.competence_type
              && (
                <div>
                  {i18nCourseGetCompetenceType(course)}
                </div>
              )}
            <Link
              to={getRouteWithParams(myEducationRoutes.roleCourseView.path, {
                courseId: course.id,
                roleId,
              })}
              className="__title"
            >
              <div className="small-header">{course.competence_title}</div>
            </Link>
            {course.verified && (
              <>
                {course.verified.date}
                {' '}
                -
                {' '}
                {course.verified.fullname}
              </>
            )}
          </div>

          {verifyPassed(course) && (
            <img
              alt="completed"
              className="passed-ico"
              src={completed}
            />
          )}
        </div>
      ) || size === 'employee' && (
        <div className={`competence-card ${size}`}>
          <div className="grid-x">
            <div className="small-12 cell">
              <div
                className="roles-list__competence"
                key={course.competence_id}
              >
                {canEdit && !isMobile && (
                  <div className="edit-button">
                    <ActionButton
                      variant="defaultTheme"
                      onClick={() => onEdit(course)}
                    >
                      {i18n('globals.edit')}
                    </ActionButton>
                  </div>
                )}
                {canEdit && isMobile && (
                  <div className="edit-button mobile">
                    <Pencil
                      color="#0b5dd7"
                      role="button"
                      tabIndex="0"
                      onKeyUp={evt => evt.key === 'Enter' && onEdit(course)}
                      onClick={() => onEdit(course)}
                    />
                  </div>
                )}
                <div className="roles-list__competence-title">
                  <div
                    className="roles-list__competence-status"
                  >
                    {verifyPassed(course)
                        && (
                          <img
                            src={completed}
                            alt="all-ok"
                            style={{width: 20}}
                          />
                        )}
                  </div>
                  <div className="info-wrapper">
                    <div className="small-header">
                      {i18nCourseGetCompetenceType(course)}
                    </div>
                    {course.competence_title}
                    <div className="signed-info">
                      {course.verified && course.verified.date && (
                        <div className="signed-date">
                          <FontAwesomeIcon icon={faCalendarAlt} />
                            &nbsp;&nbsp;
                          {course.verified.date}
                        </div>
                      )}
                      {course.verified && course.verified.fullname && (
                        <div className="signed-by">
                          <FontAwesomeIcon icon={faUserCheck} />
                            &nbsp;&nbsp;
                          {course.verified.fullname}
                        </div>
                      )}
                      {course.valid_until && (
                        <div className="signed-by">
                          <FontAwesomeIcon icon={faHourglass} />
                            &nbsp;&nbsp;
                          {course.valid_until}
                        </div>
                      )}
                    </div>
                  </div>
                  {course.files && course.files.length > 0 && (
                    <FilesList
                      className="files-list"
                      files={course.files.map(({title, url}) => ({
                        title,
                        url,
                      }))}
                      wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                    />
                  )}

                </div>
                <div className="roles-list__competence-description" />
              </div>
            </div>
          </div>
        </div>
      )
          || size === 'big' && (
            <>
              <div className="cell small-4 medium-4 course-image-wrapper">
                <div
                  className="img"
                  style={{
                    backgroundImage: `url(${course.cover
                      && course.cover.url
                      || defaultImg})`,
                  }}
                />
              </div>
              {isPassed && (
                <img
                  alt="completed"
                  className="passed-ico"
                  src={completed}
                />
              )}

              <div className="content cell small-8 medium-8 course-content-wrapper">
                <div className="__title">
                  <h2>{course.competence_title}</h2>
                  {course.competence_type.competence_type && (
                    <div>
                      {i18nCourseGetCompetenceType(course)}
                    </div>
                  )}
                </div>
                {!isPassed && (
                  <>
                    <br />
                    <br />
                    <em>{i18n('role.awaiting-on-approval-from-manager')}</em>
                    <br />
                    <br />
                  </>
                )}
                <div
                  className="desc"
                  dangerouslySetInnerHTML={createSubtitleContent(course.short_description)}
                />
                {course.files && course.files.length > 0 && (
                  <FilesList
                    className="files-list"
                    files={course.files.map(({title, url}) => ({
                      title,
                      url,
                    }))}
                    wrapperStyle={css`width: 100%; margin-top: 1rem;`}
                  />
                )}

              </div>
            </>
          )
    );
  }
}

CompetenceVerification.defaultProps = {
  size: 'normal',
  roleId: -2,
  removeRequirement: undefined,
};

CompetenceVerification.propTypes = {
  course: PropTypes.shape({}).isRequired,
  removeRequirement: PropTypes.func,
  roleId: PropTypes.number,
  size: PropTypes.string,
};


const mapStateToProps = state => ({isMobile: getIsMobile(state)});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {},
    dispatch,
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CompetenceVerification);

